import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFormik } from 'formik';
import get from 'lodash-es/get';
import moment from 'moment';
import { isArray, isString } from 'lodash-es';

import Button from '../../Button';
import Modal from '../../Modal';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useFiltersListValue, useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { InvoiceCloneClass, Invoice, INVOICE_CLONE_SCHEMA } from '../../../enums/finance/finance.enum';
import Select from '../../Select';
import { isBrowserLocale24h, scrollToError } from '../../../utils';
import { DATE_FORMAT } from '../../../constants/date.constants';
import Input from '../../Input';
import CustomDateRangePicker from '../../CustomDateRangePicker';
import Checkbox from '../../Checkbox';
import { UserInfo, UserPreviewInfo } from '../../../enums/users.enum';
import Avatar from '../../Profile/Avatar';
import Icon from '../../Icon';
import { timeOptions, timeOptions24h } from '../../../constants/schedule.constants';
import RadioButton from '../../RadioButton';
import NumberInput from '../../NumberInput';

type ModalCloneInvoiceProps = {
  onCloseRequest: () => void;
  cloneInvoice: (data: { data: InvoiceCloneClass; callback: (id?: string) => void }) => void;
  users: UserInfo[];
  invoice: Invoice;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
};

function ModalCloneInvoice({
  onCloseRequest,
  error,
  isLoading,
  isOpen,
  users,
  invoice,
  cloneInvoice,
}: ModalCloneInvoiceProps) {
  const intl = useIntl();

  const startTimeInputRef = useRef<any>(null);
  const selectTimeStartRef = useRef<any>(null);

  const { values, errors, touched, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: new InvoiceCloneClass(invoice),
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: INVOICE_CLONE_SCHEMA,
    onSubmit: data => {
      if (data.preparationDate && data.preparationTime) {
        const preparationUtc = moment(`${data.preparationDate} ${data.preparationTime}`)
          .utc()
          .format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss);

        data.preparationDate = preparationUtc.split(' ')[0];
        data.preparationTime = preparationUtc.split(' ')[1];
      }
      return cloneInvoice({
        data,
        callback: () => {
          onCloseRequest();
        },
      });
    },
  });

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [values.invoiceNumberFields.length]);

  const usersOptions = useMemo(() => {
    return users.map((el: UserInfo) => {
      return {
        label: el.fullName,
        value: el,
      };
    });
  }, [users]);

  const repeatOptions = useMemo(() => {
    const preparationDay = moment(values.preparationDate).format('dddd');
    const preparationDate = moment(values.preparationDate).date();
    const isLastDayOfMonth = moment(values.preparationDate).date() === moment(values.preparationDate).daysInMonth();

    return [
      { label: intl.formatMessage(messages.doesNotRepeat), value: 'DOES_NOT_REPEAT' },
      { label: intl.formatMessage(messages.weeklyOn, { day: preparationDay }), value: 'WEEKLY' },
      { label: intl.formatMessage(messages.everyTwoWeeksOn, { day: preparationDay }), value: 'EVERY_2_WEEKS' },
      { label: intl.formatMessage(messages.everyThreeWeeksOn, { day: preparationDay }), value: 'EVERY_3_WEEKS' },
      { label: intl.formatMessage(messages.everyFourWeeksOn, { day: preparationDay }), value: 'EVERY_4_WEEKS' },
      { label: intl.formatMessage(messages.monthlyOnDay, { day: preparationDate }), value: 'MONTHLY_ON_DAY' },
      ...(isLastDayOfMonth
        ? [{ label: intl.formatMessage(messages.monthlyOnLastDay), value: 'MONTHLY_ON_LAST_DAY' }]
        : []),
    ];
  }, [values.preparationDate]);

  const usersValues = useFiltersListValue(usersOptions, values.userIds || []);

  const recipientsValues = useMemo(() => {
    const users = [...usersValues];
    values.emails.forEach(email => {
      users.push({
        label: email,
        value: email,
      });
    });
    return users;
  }, [values.emails, usersValues]);

  const emailsErrors: any = useMemo(() => {
    if (isArray(errors.emails)) {
      return errors.emails.filter(email => !!email);
    }
  }, [errors.emails]);

  const timeOptionsForSelect = useMemo(
    () =>
      timeOptions.map((el, index) => ({
        value: timeOptions24h[index],
        label: isBrowserLocale24h() ? el.slice(0, 5) : el,
      })),
    [timeOptions],
  );

  const timeStartValue = useMemo(() => timeOptionsForSelect.find(el => el.value === values.preparationTime) || null, [
    timeOptionsForSelect,
    values.preparationTime,
  ]);

  const formatOptionLabel = useCallback(({ value, label }: any) => {
    return !isString(value) ? (
      <div className="table__data-wrapper">
        <Avatar userInfo={value} size="tiny" externalClass="avatar-select" fileSize={36} />
        <span>{label}</span>
      </div>
    ) : (
      <span className="custom-value">{label}</span>
    );
  }, []);

  useSetFieldsErrors(error, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleRecipientsChange = useCallback(value => {
    const emails = value.filter((data: any) => isString(data.value));
    const handleUserSort = ({ value }: { value: UserPreviewInfo }) => value.id;
    const sortedUsers = value.filter(handleUserSort);

    setFieldValue('userIds', sortedUsers.map(handleUserSort));
    setFieldValue(
      'users',
      sortedUsers.map((el: any) => el.value),
    );
    setFieldValue(
      'emails',
      emails.map(({ value }: { value: UserPreviewInfo }) => value),
    );
  }, []);

  const formatCreateLabel = useCallback(inputValue => {
    return (
      <div className="select__create-wrapper">
        <Icon iconName="plus" externalClass="select__add-icon" />
        <span>
          <FormattedMessage {...messages.addButton} /> &nbsp;
          {inputValue}
        </span>
      </div>
    );
  }, []);

  const getOptionLabel = useCallback(value => {
    return value.__isNew__ ? value.value : value.label;
  }, []);

  const handleChangeDateRange = useCallback(
    (start: string, end: string) => {
      const dateStart = moment(start).format();
      const dateEnd = moment(end).format();

      if (!moment(values.invoicingDateStart).isSame(moment(start), 'date')) {
        setFieldValue('invoicingDateStart', moment(dateStart).format(DATE_FORMAT.YYYY_MM_DD));
      }
      if (!moment(values.invoicingDateEnd).isSame(moment(end), 'date')) {
        setFieldValue('invoicingDateEnd', moment(dateEnd).format(DATE_FORMAT.YYYY_MM_DD));
      }
    },
    [values.invoicingDateStart, values.invoicingDateEnd],
  );

  const handleActiveTimeScroll = (ref: any) => {
    setTimeout(() => {
      const selectedItem = ref.current?.select?.menuListRef?.querySelector('.select__option--is-selected');
      selectedItem && selectedItem.scrollIntoView({ block: 'center', inline: 'nearest' });
    }, 1);
  };

  const handleStartTimeChange = useCallback(e => {
    setFieldValue('preparationTime', `${e.target.value}:00`);
    handleActiveTimeScroll(selectTimeStartRef);
  }, []);

  const handleStartTimeSelectChange = useCallback(
    ({ value }) => {
      const input = startTimeInputRef.current;
      if (input) {
        input.value = value.slice(0, 5);
      }
      setFieldValue('preparationTime', value);
    },
    [values],
  );

  const handleChangeDate = useCallback((name, value) => {
    setFieldValue(name, moment(value).format(DATE_FORMAT.YYYY_MM_DD));
  }, []);

  const handleRepeatChange = useCallback(
    selectedOption => {
      setFieldValue('recurrenceType', selectedOption.value);
    },
    [setFieldValue],
  );

  const handleCountUpRecurrenceOccurrences = useCallback(() => {
    const count = values.recurrenceOccurrences || 0;
    setFieldValue(`recurrenceOccurrences`, count + 1);
    if (count < 0) {
      setFieldValue(`recurrenceOccurrences`, 1);
    }
  }, [values]);

  const handleCountDownRecurrenceOccurrences = useCallback(() => {
    const count = values.recurrenceOccurrences || 2;
    if (count > 1) {
      setFieldValue(`recurrenceOccurrences`, count - 1);
    }
  }, [values]);

  const handleCountUpIncrementStep = useCallback(
    (index: number) => {
      const count = values.invoiceNumberFields[index].incrementStep || 0;
      setFieldValue(`invoiceNumberFields[${index}].incrementStep`, count + 1);
      if (count < 0) {
        setFieldValue(`invoiceNumberFields[${index}].incrementStep`, 1);
      }
    },
    [values],
  );

  const handleCountDownIncrementStep = useCallback(
    (index: number) => {
      const count = values.invoiceNumberFields[index].incrementStep || 2;
      if (count > 1) {
        setFieldValue(`invoiceNumberFields[${index}].incrementStep`, count - 1);
      }
    },
    [values],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.cloneInvoiceTitle)}
      classNameModal="invoice-modal-clone"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper" ref={scrollContainerRef}>
          <div className="form__input-block">
            <div className="form__inputs-subwrapper">
              <div className="form__input-block invoice-modal--half-input">
                <Input
                  type="date"
                  label={intl.formatMessage(messages.preparationDate)}
                  name="preparationDate"
                  defaultValue={values.preparationDate}
                  onChange={event => handleChangeDate('preparationDate', event.target.value)}
                  hasError={hasError(`preparationDate`)}
                  errorMessage={errors?.preparationDate}
                />
              </div>
              <div className="form__input-block invoice-modal--half-input">
                <Select
                  selectRef={selectTimeStartRef}
                  options={timeOptionsForSelect}
                  value={timeStartValue}
                  label={intl.formatMessage(messages.preparationTime)}
                  customInput={
                    <Input
                      inputRef={startTimeInputRef}
                      type={'time'}
                      name={'preparationTime'}
                      onChange={handleStartTimeChange}
                      defaultValue={values.preparationTime?.slice(0, 5)}
                    />
                  }
                  externalClass="time-select"
                  errorMessage={errors?.preparationTime}
                  customInputRef={startTimeInputRef}
                  handleChange={handleStartTimeSelectChange}
                  hasError={hasError('preparationTime')}
                />
              </div>
            </div>
            <CustomDateRangePicker
              alwaysShowCalendars
              isFilter
              filterLabel={intl.formatMessage(messages.invoicingPeriod)}
              maxDate={
                values.preparationDate ? moment(values.preparationDate, DATE_FORMAT.YYYY_MM_DD).toDate() : undefined
              }
              hasError={hasError('invoicingDateStart') || hasError('invoicingDateEnd')}
              errorMessage="Required"
              startDate={values.invoicingDateStart}
              endDate={values.invoicingDateEnd}
              dateFormatStart={DATE_FORMAT.YYYY_MM_DD}
              dateFormatEnd={DATE_FORMAT.YYYY_MM_DD}
              containerExternalClass="invoice-modal-clone--datepicker"
              parentEl=".invoice-modal-clone--datepicker"
              onChange={handleChangeDateRange}
            />
            <div className="form__input-block">
              <Checkbox
                externalClass="form__checkbox-capitalazie-label"
                id="groupByMembers"
                label={intl.formatMessage(messages.groupByMembers)}
                checked={values.groupByMembers}
                onChange={e => setFieldValue('groupByMembers', e.target.checked)}
              />
            </div>
            <div className="form__input-block">
              <Select
                isSearchable
                isMulti
                isSelectWithAvatar
                label={intl.formatMessage(messages.recipientsLabel)}
                name={`userIds`}
                value={recipientsValues}
                options={usersOptions}
                errorMessage={emailsErrors && emailsErrors[0]}
                formatOptionLabel={formatOptionLabel}
                formatCreateLabel={formatCreateLabel}
                getOptionLabel={getOptionLabel}
                handleChange={handleRecipientsChange}
              />
            </div>
            <div className="form__input-block">
              <Select
                label={intl.formatMessage(messages.repeatLabel)}
                name="repeat"
                value={repeatOptions.find(option => option.value === values.recurrenceType) || repeatOptions[0]}
                options={repeatOptions}
                handleChange={handleRepeatChange}
              />
            </div>

            {values.recurrenceType && values.recurrenceType !== 'NONE' && (
              <>
                <div className="ends-section">
                  <div className="ends-section__note">
                    <FormattedMessage {...messages.ends} />
                  </div>
                  <div className="ends-section__option">
                    <RadioButton
                      name="endsType"
                      id="on"
                      label={intl.formatMessage(messages.endsOn)}
                      checked={values.endsType === 'on'}
                      externalClass="ends-section__radio"
                      onChange={() => setFieldValue('endsType', 'on')}
                    />
                    <Input
                      type="date"
                      name="recurrenceEndDate"
                      placeholder="ДД.ММ.ГГГГ"
                      defaultValue={values.recurrenceEndDate || ''}
                      onChange={e => setFieldValue('recurrenceEndDate', e.target.value)}
                      hasError={hasError('recurrenceEndDate')}
                      errorMessage={errors?.recurrenceEndDate}
                      externalClass="ends-section__input"
                    />
                  </div>
                  <div className="ends-section__option">
                    <RadioButton
                      name="endsType"
                      id="after"
                      label={intl.formatMessage(messages.endsAfter)}
                      checked={values.endsType === 'after'}
                      externalClass="ends-section__radio"
                      onChange={() => setFieldValue('endsType', 'after')}
                    />
                    <div className="ends-section__input-wrapper">
                      <NumberInput
                        min={1}
                        name="recurrenceOccurrences"
                        onChange={e => setFieldValue('recurrenceOccurrences', e.target.value)}
                        defaultValue={values.recurrenceOccurrences || 1}
                        onCountUp={() => handleCountUpRecurrenceOccurrences()}
                        onCountDown={() => handleCountDownRecurrenceOccurrences()}
                        hasError={hasError('recurrenceOccurrences')}
                        errorMessage={errors?.recurrenceOccurrences}
                        type="number"
                      />
                      <span className="ends-section__label">{intl.formatMessage(messages.occurrence)}</span>
                    </div>
                  </div>
                </div>

                <div className="page__setting-title invoice-number-generation-title">
                  <FormattedMessage {...messages.invoiceNumberGeneration} />
                </div>
                {values.invoiceNumberFields.map((item: any, index: number) => {
                  const isNumeric = /^\d+$/.test(item.value);

                  return (
                    <div className="form__inputs-subwrapper" key={index}>
                      <div className="form__input-block invoice-number-input">
                        <Input
                          name={`invoiceNumberFields[${index}].value`}
                          label={intl.formatMessage(messages.invoiceNumber)}
                          defaultValue={item.value || ''}
                          onChange={e => {
                            const newValue = e.target.value;

                            setFieldValue(`invoiceNumberFields[${index}].value`, newValue);

                            if (!/^\d+$/.test(newValue)) {
                              setFieldValue(`invoiceNumberFields[${index}].incrementStep`, null);
                            }
                          }}
                          hasError={hasError(`invoiceNumberFields[${index}].value`)}
                          type="text"
                        />
                      </div>
                      <div
                        className={`form__input-block increment-step-checkbox ${
                          !isNumeric ? 'hidden-invoice-step' : ''
                        }`}
                      >
                        <Checkbox
                          id={`incrementStep-${index}`}
                          externalClass="form__checkbox-capitalazie-label"
                          label={intl.formatMessage(messages.incrementStep)}
                          checkedValue={item.incrementStep !== null}
                          onChange={() =>
                            setFieldValue(
                              `invoiceNumberFields[${index}].incrementStep`,
                              item.incrementStep === null ? 1 : null,
                            )
                          }
                        />
                      </div>
                      <div
                        className={`form__input-block increment-step-container ${
                          !isNumeric ? 'hidden-invoice-step' : ''
                        }`}
                      >
                        {item.incrementStep !== null && (
                          <NumberInput
                            min={1}
                            name={`invoiceNumberFields[${index}].incrementStep`}
                            onChange={e => setFieldValue(`invoiceNumberFields[${index}].incrementStep`, e.target.value)}
                            defaultValue={item.incrementStep || ''}
                            onCountUp={() => handleCountUpIncrementStep(index)}
                            onCountDown={() => handleCountDownIncrementStep(index)}
                            hasError={hasError(`invoiceNumberFields[${index}].incrementStep`)}
                            type="number"
                          />
                        )}
                      </div>
                      <button
                        className="form__btn-clean-inputs"
                        type="button"
                        onClick={() => {
                          const updatedFields = values.invoiceNumberFields.filter((_, i) => i !== index);
                          setFieldValue('invoiceNumberFields', updatedFields);
                        }}
                      >
                        <Icon iconName={'cross'} />
                      </button>
                    </div>
                  );
                })}

                <div className="form__input-block add_button_block">
                  <Button
                    type="button"
                    externalClass="form__btn-add-group"
                    color="gray"
                    onClick={() => {
                      const newField = { value: '', incrementStep: null };
                      setFieldValue('invoiceNumberFields', [...values.invoiceNumberFields, newField]);
                    }}
                  >
                    <Icon iconName="plus" externalClass="form__icon-btn-add" />
                    <FormattedMessage {...messages.addButton} />
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button
            color={'gray'}
            externalClass={'button--modal button--cancel'}
            type={'button'}
            onClick={onCloseRequest}
          >
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalCloneInvoice;
