import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Icon from '../../components/Icon';
import { FinanceProject } from '../../enums/finance/finance.enum';
import { ESTIMATE } from '../../constants/finance.constants';
import { DELETE_FINANCE_PROJECT, UPDATE_FINANCE_PROJECT } from '../../constants/policies.constants';
import { isEmpty } from 'lodash-es';

export const useDataForTable = (
  setProjectClicked: (row: FinanceProject) => void,
  setModalEditProjectIsOpen: (value: boolean) => void,
  changeProjectStatus: (rowId: string, value: string) => void,
  setModalDeleteProjectIsOpen: (value: boolean) => void,
) => {
  const intl = useIntl();

  const checkActiveStatus = (isActive: boolean) =>
    isActive ? (
      <span className="active-status">{intl.formatMessage(messages.activeLabel)}</span>
    ) : (
      <span className="inactive-status">{intl.formatMessage(messages.inactiveLabel)}</span>
    );

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: FinanceProject) => (
          <div className={'table__data-wrapper'}>
            <div className="name-capital-letter-block">
              <span>{row.name[0]}</span>
            </div>
            <div>{row.name}</div>
          </div>
        ),
        className: 'table__data--bold',
        sortName: 'name',
      },
      {
        name: intl.formatMessage(messages.clientLabel),
        modifier: (row: FinanceProject) => <div className={'table__data-wrapper'}>{row.client.name}</div>,
        sortName: 'client',
      },
      {
        name: intl.formatMessage(messages.projectTypeLabel),
        modifier: (row: FinanceProject) => row.projectType.name,
      },
      {
        name: intl.formatMessage(messages.paymentTypeColumn),
        modifier: (row: FinanceProject) =>
          row.paymentType === ESTIMATE
            ? intl.formatMessage(messages.estimateLabel)
            : intl.formatMessage(messages.timeAndMaterialLabel),
      },
      {
        name: intl.formatMessage(messages.hourPriceLabel),
        modifier: (row: FinanceProject) => row.currentRate,
      },
      {
        name: intl.formatMessage(messages.paymentTermsLabel),
        modifier: (row: FinanceProject) => row.currentPayTerms,
      },
      {
        name: intl.formatMessage(messages.jiraProjectsLabel),
        modifier: (row: FinanceProject) =>
          !isEmpty(row.jiraProjects) && (
            <div className={'jira-projects-wrapper'}>
              {row.jiraProjects.map(item => (
                <div key={item.id}>{item.name}</div>
              ))}
            </div>
          ),
        className: 'table__data--bold',
      },
      {
        name: intl.formatMessage(messages.lastUpdateLabel),
        modifier: (row: FinanceProject) => row.formattedLastUpdateDate,
      },
      {
        name: intl.formatMessage(messages.updaterColumn),
        modifier: (row: FinanceProject) => row.updater.fullName,
      },
      {
        name: intl.formatMessage(messages.statusLabel),
        modifier: (row: FinanceProject) => checkActiveStatus(row.isActive),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinanceProject) => {
          setProjectClicked(row);
          setModalEditProjectIsOpen(true);
        },
        verifiablePolicies: [UPDATE_FINANCE_PROJECT],
      },
      {
        label: (row: FinanceProject) =>
          row.client.isActive || row.isActive ? (
            <>
              <Icon iconName={row.isActive ? 'toggle-on' : 'toggle-off'} externalClass={'dropdown__list-item__icon'} />
              {row.isActive ? (
                <FormattedMessage {...messages.deactivateLabel} />
              ) : (
                <FormattedMessage {...messages.activateLabel} />
              )}
            </>
          ) : null,
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinanceProject) => {
          changeProjectStatus(row.id, `${!row.isActive}`);
        },
        verifiablePolicies: [UPDATE_FINANCE_PROJECT],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinanceProject) => {
          setProjectClicked(row);
          setModalDeleteProjectIsOpen(true);
        },
        verifiablePolicies: [DELETE_FINANCE_PROJECT],
      },
    ],
    [],
  );

  return {
    tableColumns,
    tableActions,
  };
};
