import {
  UPDATE_COMPETENCE,
  UPDATE_POLL,
  UPDATE_SKILL_QUESTION,
  UPDATE_TEMPLATE,
  UPDATE_TRANSACTION,
  VIEW_ABSENCE_MANUAL_INFO,
  VIEW_ABSENCE_PERIOD,
  VIEW_ACTIVE_EMPLOYEE,
  VIEW_ACTIVE_INACTIVE_HOURS_REPORT,
  VIEW_ALL_EMPLOYEE_ACTIVITY,
  VIEW_ASSESSMENT,
  VIEW_BONUS_BY_GRADES,
  VIEW_CANDIDATE,
  VIEW_CANDIDATE_RECEIVING_SOURCE,
  VIEW_CANDIDATE_SPECIALIZATION,
  VIEW_CANDIDATE_STATUS,
  VIEW_CANDIDATE_TECHNOLOGY,
  VIEW_CASH_FLOW,
  VIEW_CLIENT,
  VIEW_COMPANY_POSITION,
  VIEW_COMPETENCE,
  VIEW_COMPETENCE_TYPE,
  VIEW_CURRENCY,
  VIEW_DASHBOARD,
  VIEW_DEPARTMENT,
  VIEW_EMPLOYEE_GROUP,
  VIEW_EMPLOYEE_REPORT,
  VIEW_EMPLOYEE_TIMESHEET_REPORT,
  VIEW_EMPLOYEES_AND_ISSUE_TYPE,
  VIEW_EVENT,
  VIEW_EVENT_EXTENDED,
  VIEW_EVENT_TYPE,
  VIEW_EXPENSE_TYPE,
  VIEW_FINANCE_PLAN,
  VIEW_FINANCE_PROJECT,
  VIEW_FOREIGN_LANGUAGE,
  VIEW_GRADE,
  VIEW_INCOME_TYPE,
  VIEW_INCOMES_EXPENSES_REPORT,
  VIEW_INVOICE,
  VIEW_LANGUAGE_LEVEL,
  VIEW_MAIL_SETTING,
  VIEW_NOTIFICATION,
  VIEW_NOTIFICATION_TEMPLATE,
  VIEW_OFFICE,
  VIEW_OFFICE_BALANCE,
  VIEW_PERSONAL_ACTIVITY,
  VIEW_PLANNED_EXPENSE,
  VIEW_POLICY,
  VIEW_POLL,
  VIEW_POLL_EXTENDED,
  VIEW_PORTAL_BRANDING,
  VIEW_PROFIT_LOSS_REPORT,
  VIEW_PROJECT,
  VIEW_PROJECT_GROUP,
  VIEW_PROJECT_REPORT,
  VIEW_PROJECT_TYPE,
  VIEW_PUBLIC_EVENT,
  VIEW_RECOGNITION_EXTENDED,
  VIEW_RECOGNITION_TYPE,
  VIEW_RESOURCE,
  VIEW_RESOURCE_LINKS_MENU,
  VIEW_RESUME,
  VIEW_SALARY,
  VIEW_SKILL_LEVEL,
  VIEW_SKILL_QUESTION,
  VIEW_SOFTWARE,
  VIEW_SPECIALIZATION,
  VIEW_SPECIALIZATION_COMPETENCE,
  VIEW_SUPPLIER,
  VIEW_TECHNICAL_SKILL,
  VIEW_TEMPLATE,
  VIEW_TEMPLATE_CATEGORY,
  VIEW_TRANSACTION,
  VIEW_USER_EXTENDED,
  VIEW_USER_HOUR,
  VIEW_USERS_HOURS_ABSENCE_REPORT,
} from './constants/policies.constants';
import r from './constants/routes.constants';
import AbsencePeriods from './pages/AbsencePeriods';
import ActiveandInactiveHours from './pages/ActiveandInactiveHours';
import ActiveEmployees from './pages/ActiveEmployees';
import Activity from './pages/Activity';
import Assessments from './pages/Assessments';
import AssessmentsInfo from './pages/Assessments/AssessmentsInfo';
import BonusesByGrades from './pages/BonusesByGrades';
import Branding from './pages/Branding';
import CandidateManagement from './pages/CandidateManagement';
import CandidateProfile from './pages/CandidateProfle';
import CandidateReceivingSources from './pages/CandidateReceivingSources';
import CandidateSpecializations from './pages/CandidateSpecializations';
import CandidateStatuses from './pages/CandidateStatuses';
import CandidateTechnologies from './pages/CandidateTechnologies';
import Clients from './pages/Clients';
import CompanyPositions from './pages/CompanyPositions';
import CompetenceInfo from './pages/Competencies/CompetenceInfo';
import Competencies from './pages/Competencies/Competencies';
import EditCompetence from './pages/Competencies/EditCompetence';
import NewCompetence from './pages/Competencies/NewCompetence';
import CompetenciesTypes from './pages/CompetenciesTypes';
import Currencies from './pages/Currencies';
import DashboardManagement from './pages/DashboardManagement';
import DaysUsedLimit from './pages/DaysUsedLimit';
import Departments from './pages/Departments';
import EmployeeGroups from './pages/EmployeeGroups';
import EmployeesIssueTypes from './pages/EmployeesIssueTypes';
import EmployeesReport from './pages/EmployeesReport';
import EmployeesTimesheet from './pages/EmployeesTimesheet';
import EventTypes from './pages/EventTypes';
import ExpenseTypes from './pages/ExpenseTypes';
import FinanceProjects from './pages/FinanceProjects';
import FollowUps from './pages/FollowUps';
import ForeignLanguages from './pages/ForeignLanguages';
import Grades from './pages/Grades';
import IncomesExpensesReport from './pages/IncomesExpensesReport';
import IncomeTypes from './pages/IncomeTypes';
import LanguageLevels from './pages/LanguageLevels';
import MailerSettings from './pages/MailerSettings';
import MyPolls from './pages/MyPolls';
import NotificationTemplates from './pages/NotificationTemplates';
import Offices from './pages/Offices';
import OfficesBalance from './pages/OfficesBalance';
import CashFlowReport from './pages/CashFlowReport';
import Users from './pages/People';
import Permissions from './pages/Permissions';
import PlannedExpense from './pages/PlannedExpense';
import Planning from './pages/Planning';
import PlanInfo from './pages/Planning/PlanInfo';
import PollCategories from './pages/PollCategories';
import Polls from './pages/Polls';
import PollInfo from './pages/Polls/PollInfo';
import PollTemplates from './pages/PollTemplates';
import EditPollTemplate from './pages/PollTemplates/EditPollTemplate';
import NewPollTemplate from './pages/PollTemplates/NewPollTemplate';
import ProjectGroups from './pages/ProjectGroups';
import ProjectHours from './pages/ProjectHours';
import Projects from './pages/Projects';
import ProjectsReport from './pages/ProjectsReport';
import ProjectTypes from './pages/ProjectTypes';
import QuestionsForSkills from './pages/QuestionsForSkill';
import NewQuestionsSkill from './pages/QuestionsForSkill/CreateNewSkillQuestions';
import EditSkillQuestions from './pages/QuestionsForSkill/EditSkillQuestions';
import Questions from './pages/QuestionsForSkill/Questions';
import Recognitions from './pages/Recognitions';
import RecognitionTypes from './pages/RecognitionTypes';
import Resources from './pages/Resources';
import ResumesManagement from './pages/ResumesManagement';
import ResumeInfo from './pages/ResumesManagement/ResumeInfo';
import Salaries from './pages/Salaries';
import Schedule from './pages/Schedule';
import EventInformation from './pages/Schedule/EventInformation/EventInformation';
import SignIn from './pages/SignIn';
import SkillLevels from './pages/SkillLevels';
import Specializations from './pages/Specializations';
import SpecializationsCompetencies from './pages/SpecializationsCompetencies';
import Suppliers from './pages/Suppliers';
import TaskTrackerSoftware from './pages/TaskTrackerSoftware';
import TechnicalSkills from './pages/TechnicalSkills';
import Transactions from './pages/Transactions';
import ImportTransactions from './pages/Transactions/ImportTransactions';
import UserHours from './pages/UserHours';
import UserProfile from './pages/UserProfile';
import UsersHoursAbsences from './pages/UsersHoursAbsences';
import { checkPolicies } from './utils/policies.utils';
import Invoices from './pages/Invoices';
import ProfitLossReport from './pages/ProfitLossReport';
import Dashboard from './pages/Dashboard';

export const routeAuthConfig = [
  {
    id: 'a78ac362-a722-11eb-bcbc-0242ac130002',
    path: r.signIn,
    component: SignIn,
    exact: true,
  },
];

export const getRouteUserConfig = (userPolicies: any) => {
  return [
    {
      id: 'b35ba042-ad91-11eb-8529-0242ac130003',
      path: `${r.users}/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`,
      component: UserProfile,
      exact: true,
    },
    checkPolicies([VIEW_OFFICE], userPolicies)
      ? {
          id: '1fc77d70-a81d-11eb-bcbc-0242ac130002',
          path: r.offices,
          component: Offices,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_GRADE], userPolicies)
      ? {
          id: 'e6e90b6e-56c8-4f50-ade7-2068113d5ff9',
          path: r.grades,
          component: Grades,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_SPECIALIZATION], userPolicies)
      ? {
          id: 'de2b7e33-0df3-4ea3-8d9a-a2c554b39a9b',
          path: r.specializations,
          component: Specializations,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_COMPANY_POSITION], userPolicies)
      ? {
          id: 'e9d065f8-954e-4e91-a6a0-f12a35e54821',
          path: r.companyPositions,
          component: CompanyPositions,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_DEPARTMENT], userPolicies)
      ? {
          id: '579c916e-55bf-451e-825e-8766d2ee2eb1',
          path: r.departments,
          component: Departments,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_RECOGNITION_TYPE], userPolicies)
      ? {
          id: '3aa42b68-c38c-11eb-8529-0242ac130003',
          path: r.recognitionTypes,
          component: RecognitionTypes,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_RECOGNITION_EXTENDED], userPolicies)
      ? {
          id: '3aa42b68-c38c-11eb-8529-0242ac130003',
          path: r.recognitions,
          component: Recognitions,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_COMPETENCE_TYPE], userPolicies)
      ? {
          id: '93672926-c7a1-11eb-b8bc-0242ac130003',
          path: r.competenciesTypes,
          component: CompetenciesTypes,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_TECHNICAL_SKILL], userPolicies)
      ? {
          id: '92b9fe5e-0103-11ec-9a03-0242ac130003',
          path: r.technicalSkills,
          component: TechnicalSkills,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_PROJECT], userPolicies)
      ? {
          id: 'adf2fecd-08c7-4de5-8f41-464ded936818',
          path: r.projects,
          component: Projects,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_EVENT_TYPE], userPolicies)
      ? {
          id: '3c83f7a4-927f-47e9-860a-0ba2afb45014',
          path: r.eventTypes,
          component: EventTypes,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_PORTAL_BRANDING], userPolicies)
      ? {
          id: '5ffe3ec9-5a5b-45f7-9814-e320c5c189f8',
          path: r.branding,
          component: Branding,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_SPECIALIZATION_COMPETENCE], userPolicies)
      ? {
          id: 'ff650a4c-da59-11eb-8d19-0242ac130003',
          path: r.specializationsCompetencies,
          component: SpecializationsCompetencies,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_USER_EXTENDED], userPolicies)
      ? {
          id: '87405427-cbe8-4a86-ac5f-ca928d983c3c',
          path: r.users,
          component: Users,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_POLL], userPolicies)
      ? {
          id: '81fc743a-328e-11ed-a261-0242ac120002',
          path: r.myPolls,
          component: MyPolls,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_COMPETENCE], userPolicies)
      ? {
          id: '67d79a00-c92e-11eb-b8bc-0242ac130003',
          path: r.competencies,
          component: Competencies,
          exact: true,
        }
      : null,
    checkPolicies([UPDATE_COMPETENCE], userPolicies)
      ? {
          id: '196ff2ee-c9d4-11eb-b8bc-0242ac130003',
          path: r.newCompetence,
          component: NewCompetence,
          exact: true,
        }
      : null,
    checkPolicies([UPDATE_COMPETENCE], userPolicies)
      ? {
          id: '5162307a-d020-11eb-b8bc-0242ac130003',
          path: r.editCompetence,
          component: EditCompetence,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_COMPETENCE], userPolicies)
      ? {
          id: 'f2d22d2e-d422-11eb-b8bc-0242ac130003',
          path: r.competenceInfo,
          component: CompetenceInfo,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_ASSESSMENT], userPolicies)
      ? {
          id: '3bbf2df2-f91c-11eb-9a03-0242ac130003',
          path: r.assessments,
          component: Assessments,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_ASSESSMENT], userPolicies)
      ? {
          id: '9a0f893e-18d8-11ec-9621-0242ac130002',
          path: r.assessmentsInfo,
          component: AssessmentsInfo,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_SKILL_QUESTION], userPolicies)
      ? {
          id: '28661666-eec3-11eb-9a03-0242ac130003',
          path: r.skillQuestions,
          component: QuestionsForSkills,
          exact: true,
        }
      : null,
    checkPolicies([UPDATE_SKILL_QUESTION], userPolicies)
      ? {
          id: 'c222c14e-ef7e-11eb-9a03-0242ac130003',
          path: r.newSkillQuestions,
          component: NewQuestionsSkill,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_SKILL_QUESTION], userPolicies)
      ? {
          id: 'd18c09d0-efd6-11eb-9a03-0242ac130003',
          path: r.questions,
          component: Questions,
          exact: true,
        }
      : null,
    checkPolicies([UPDATE_SKILL_QUESTION], userPolicies)
      ? {
          id: '552d3382-f076-11eb-9a03-0242ac130003',
          path: r.editSkillQuestions,
          component: EditSkillQuestions,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_SKILL_LEVEL], userPolicies)
      ? {
          id: '9eb36238-0bf1-11ec-9a03-0242ac130003',
          path: r.skillLevels,
          component: SkillLevels,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_POLL_EXTENDED], userPolicies)
      ? {
          id: 'bf31a256-1c74-11ed-861d-0242ac120002',
          path: r.polls,
          component: Polls,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_POLL_EXTENDED, UPDATE_POLL], userPolicies)
      ? {
          id: 'bff26396-2382-11ed-861d-0242ac120002',
          path: r.pollsInfo,
          component: PollInfo,
        }
      : null,
    checkPolicies([VIEW_TEMPLATE_CATEGORY], userPolicies)
      ? {
          id: 'a1b1e81e-e053-11ed-b5ea-0242ac120002',
          path: r.pollCategories,
          component: PollCategories,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_TEMPLATE], userPolicies)
      ? {
          id: '0bd8814b-a322-44f6-9f37-d30d7d6f3748',
          path: r.pollTemplates,
          component: PollTemplates,
          exact: true,
        }
      : null,
    checkPolicies([UPDATE_TEMPLATE], userPolicies)
      ? {
          id: '46304129-239b-4c9a-be9f-e89f56f45547',
          path: r.newPollTemplate,
          component: NewPollTemplate,
          exact: true,
        }
      : null,
    checkPolicies([UPDATE_TEMPLATE], userPolicies)
      ? {
          id: 'bad9eef7-cdd5-489f-9766-2e6f0f8fd8d3',
          path: r.editPollTemplate,
          component: EditPollTemplate,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_LANGUAGE_LEVEL], userPolicies)
      ? {
          id: '00162750-40a8-11ec-973a-0242ac130003',
          path: r.languageLevels,
          component: LanguageLevels,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_FOREIGN_LANGUAGE], userPolicies)
      ? {
          id: '759c105e-3895-11ec-8d3d-0242ac130003',
          path: r.foreignLanguages,
          component: ForeignLanguages,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_PROJECT_GROUP], userPolicies)
      ? {
          id: 'ae55851b-ac01-44f0-9bc4-c30a56030785',
          path: r.projectGroups,
          component: ProjectGroups,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_RESOURCE], userPolicies)
      ? {
          id: 'dcb54831-cc86-493d-8909-6b76c20908bd',
          path: r.resources,
          component: Resources,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_USER_HOUR], userPolicies)
      ? {
          id: 'ff0aa914-9596-4272-9309-c2471b3b792b',
          path: r.projectHours,
          component: ProjectHours,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_USER_HOUR], userPolicies)
      ? {
          id: '44ac3a6b-727a-47b4-981d-03cdfb177f1d',
          path: r.userHours,
          component: UserHours,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_ACTIVE_EMPLOYEE], userPolicies)
      ? {
          id: 'ae55851b-ac01-44f0-9bc4-c30a56030785',
          path: r.activeEmployees,
          component: ActiveEmployees,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_RESUME], userPolicies)
      ? {
          id: 'ae55851b-ac01-44f0-9bc4-c30a56030785',
          path: r.resumesManagement,
          component: ResumesManagement,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_EVENT_EXTENDED, VIEW_EVENT, VIEW_PUBLIC_EVENT], userPolicies)
      ? {
          id: '0374c021-58ba-4f86-b19e-2ee9e3c477d4',
          path: r.schedule,
          component: Schedule,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_EVENT_EXTENDED, VIEW_EVENT, VIEW_PUBLIC_EVENT], userPolicies)
      ? {
          id: 'b4ba6e97-051a-44bf-906c-a8b6f96d9968',
          path: r.eventInformation,
          component: EventInformation,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_BONUS_BY_GRADES], userPolicies)
      ? {
          id: 'd5b5cacc-ffb7-11ec-b939-0242ac120002',
          path: r.bonusesByGrades,
          component: BonusesByGrades,
          exact: true,
        }
      : null,

    checkPolicies([VIEW_RESUME], userPolicies)
      ? {
          id: '6bc9450a-db97-48e3-b2f1-480d277ebaf2',
          path: r.resumeInfo,
          component: ResumeInfo,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_RESOURCE_LINKS_MENU], userPolicies)
      ? {
          id: '1548cc6e-181f-11ed-861d-0242ac120002',
          path: r.dashboardManagement,
          component: DashboardManagement,
        }
      : null,
    checkPolicies([VIEW_MAIL_SETTING], userPolicies)
      ? {
          id: '056692b2-14d3-11ed-861d-0242ac120002',
          path: r.mailerSettings,
          component: MailerSettings,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_EMPLOYEE_GROUP], userPolicies)
      ? {
          id: '527157de-171e-11ed-861d-0242ac120002',
          path: r.employeeGroups,
          component: EmployeeGroups,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_NOTIFICATION], userPolicies)
      ? {
          id: '18a8a2c4-188f-11ed-861d-0242ac120002',
          path: r.followUps,
          component: FollowUps,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_INCOME_TYPE], userPolicies)
      ? {
          id: '0d0ef2cc-af50-4052-96ef-ed2fb1107bb7',
          path: r.incomeTypes,
          component: IncomeTypes,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_CURRENCY], userPolicies)
      ? {
          id: '147b68e1-08ad-4692-931f-548672f17eee',
          path: r.currencies,
          component: Currencies,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_SUPPLIER], userPolicies)
      ? {
          id: '791a693d-73c3-449d-b132-2dc27f85475b',
          path: r.suppliers,
          component: Suppliers,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_PROJECT_TYPE], userPolicies)
      ? {
          id: '10bf062e-b280-4ac8-8043-9a570a99b3bf',
          path: r.projectTypes,
          component: ProjectTypes,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_EXPENSE_TYPE], userPolicies)
      ? {
          id: '73092e9d-145d-4122-a634-08aa6d2e8efd',
          path: r.expenseTypes,
          component: ExpenseTypes,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_OFFICE_BALANCE], userPolicies)
      ? {
          id: '694d8819-36b0-4dbb-b2d7-5526f8434582',
          path: r.officesBalance,
          component: OfficesBalance,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_CASH_FLOW], userPolicies)
      ? {
          id: '9fc97351-75a2-4173-a250-16c734256e9a',
          path: r.cashFlowReport,
          component: CashFlowReport,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_DASHBOARD], userPolicies)
      ? {
          id: '910d3706-2dbd-4df3-b5b5-1e7a9f31018c',
          path: r.dashboard,
          component: Dashboard,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_INVOICE], userPolicies)
      ? {
          id: 'b90bd6d9-0d6e-4846-ab0e-838cbd4009b0',
          path: r.invoiceManagement,
          component: Invoices,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_PROFIT_LOSS_REPORT], userPolicies)
      ? {
          id: 'cdb859ee-e157-4436-84a6-b794d5f1db81',
          path: r.profitLossReport,
          component: ProfitLossReport,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_CLIENT], userPolicies)
      ? {
          id: '62e1c5e1-a94f-43b9-a843-765e7b420349',
          path: r.clients,
          component: Clients,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_SALARY], userPolicies)
      ? {
          id: 'af1cea57-4167-4636-994d-4084f3a25082',
          path: r.salaries,
          component: Salaries,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_FINANCE_PROJECT], userPolicies)
      ? {
          id: '7282bc8a-ecbd-4c59-b52d-8da627eca5a0',
          path: r.financeProjects,
          component: FinanceProjects,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_EMPLOYEE_REPORT], userPolicies)
      ? {
          id: 'a9ac4f7c-6fde-11ed-a1eb-0242ac120002',
          path: r.employeesReport,
          component: EmployeesReport,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_PROJECT_REPORT], userPolicies)
      ? {
          id: 'c92276de-7177-11ed-a1eb-0242ac120002',
          path: r.projectsReport,
          component: ProjectsReport,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_FINANCE_PLAN], userPolicies)
      ? {
          id: '9909b3bb-fba9-46d3-abf3-492a7a3bfd99',
          path: r.planning,
          component: Planning,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_FINANCE_PLAN], userPolicies)
      ? {
          id: '0b1688de-b340-4c7f-8bd0-4d7506f4ed67',
          path: r.projectPlan,
          component: PlanInfo,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_INCOMES_EXPENSES_REPORT], userPolicies)
      ? {
          id: '0f9f210f-8bb7-482e-b561-ac8852a1322d',
          path: r.incomesExpensesReport,
          component: IncomesExpensesReport,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_EMPLOYEES_AND_ISSUE_TYPE], userPolicies)
      ? {
          id: 'e5d6fd65-5708-4979-9d1d-287c4b6dfb33',
          path: r.employeesIssueTypes,
          component: EmployeesIssueTypes,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_EMPLOYEE_TIMESHEET_REPORT], userPolicies)
      ? {
          id: 'da8ff82e-9304-4625-bd1d-1a78145b1e6f',
          path: r.employeesTimesheet,
          component: EmployeesTimesheet,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_USERS_HOURS_ABSENCE_REPORT], userPolicies)
      ? {
          id: '1bb00b0c-53ea-493d-8eb3-f1c7414b4101',
          path: r.usersHoursAbsences,
          component: UsersHoursAbsences,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_ABSENCE_PERIOD], userPolicies)
      ? {
          id: '7f939613-6dae-4620-bf87-ee1ddee24738',
          path: r.absencePeriods,
          component: AbsencePeriods,
          exact: true,
        }
      : null,

    checkPolicies([VIEW_ACTIVE_INACTIVE_HOURS_REPORT], userPolicies)
      ? {
          id: '5854f19a-11f6-411e-a5d7-c8b5fb613dc7',
          path: r.activeAndInactiveHours,
          component: ActiveandInactiveHours,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_ABSENCE_MANUAL_INFO], userPolicies)
      ? {
          id: 'f48fb241-1903-4221-80dd-8a72ad4094ac',
          path: r.daysUsedLimit,
          component: DaysUsedLimit,
        }
      : null,
    checkPolicies([VIEW_ALL_EMPLOYEE_ACTIVITY, VIEW_PERSONAL_ACTIVITY], userPolicies)
      ? {
          id: '2ff4cbfa-76dc-11ed-a1eb-0242ac120002',
          path: r.activity,
          component: Activity,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_SOFTWARE], userPolicies)
      ? {
          id: '2ff4cbfa-76dc-11ed-a1eb-0242ac120002',
          path: r.taskTrackerSoftware,
          component: TaskTrackerSoftware,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_NOTIFICATION_TEMPLATE], userPolicies)
      ? {
          id: '677f42aa-78d0-400a-8cc2-97545ea0ddf3',
          path: r.notificationTemplates,
          component: NotificationTemplates,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_TRANSACTION], userPolicies)
      ? {
          id: 'c4c5e520-b831-11ed-afa1-0242ac120002',
          path: r.transactions,
          component: Transactions,
          exact: true,
        }
      : null,

    checkPolicies([UPDATE_TRANSACTION], userPolicies)
      ? {
          id: 'a2c7901c-b29e-483e-bb61-45699b4c6e6e',
          path: r.importTransactions,
          component: ImportTransactions,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_CANDIDATE_STATUS], userPolicies)
      ? {
          id: 'c7c6a98e-c8db-11ed-afa1-0242ac120002',
          path: r.candidatesStatuses,
          component: CandidateStatuses,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_CANDIDATE_SPECIALIZATION], userPolicies)
      ? {
          id: 'dbc20fe2-c9b1-11ed-afa1-0242ac120002',
          path: r.candidatesSpecializations,
          component: CandidateSpecializations,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_CANDIDATE_TECHNOLOGY], userPolicies)
      ? {
          id: 'cc996c46-ca28-11ed-afa1-0242ac120002',
          path: r.candidatesTechnologies,
          component: CandidateTechnologies,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_CANDIDATE_RECEIVING_SOURCE], userPolicies)
      ? {
          id: '03efea8c-ca2c-11ed-afa1-0242ac120002',
          path: r.candidatesReceivingSources,
          component: CandidateReceivingSources,
          exact: true,
        }
      : null,
    ...(checkPolicies([VIEW_CANDIDATE], userPolicies)
      ? [
          {
            id: '05d383c2-cd6f-11ed-afa1-0242ac120002',
            path: r.candidates,
            component: CandidateManagement,
            exact: true,
          },
          {
            id: '73b0ff0e-cfa0-11ed-afa1-0242ac120002',
            path: `${r.candidates}/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`,
            component: CandidateProfile,
            exact: true,
          },
        ]
      : [null]),
    checkPolicies([VIEW_POLICY], userPolicies)
      ? {
          id: 'b0a44efa-a832-4c05-8810-c5cfa0d34a3d',
          path: r.permissions,
          component: Permissions,
          exact: true,
        }
      : null,
    checkPolicies([VIEW_PLANNED_EXPENSE], userPolicies)
      ? {
          id: '43546842-88ad-48a0-8ea7-8644f89b2a51',
          path: r.plannedExpense,
          component: PlannedExpense,
          exact: true,
        }
      : null,
  ].filter(item => item);
};
