import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFormik } from 'formik';
import get from 'lodash-es/get';
import moment from 'moment';

import Button from '../../Button';
import Modal from '../../Modal';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { CurrencyFormatter, InvoiceClass, INVOICE_UPLOAD_SCHEMA, Invoice } from '../../../enums/finance/finance.enum';
import { scrollToError } from '../../../utils';
import { DATE_FORMAT } from '../../../constants/date.constants';
import Input from '../../Input';
import CurrencyInput from '../../CurrencyInput';
import { EFileName } from '../../../constants/export.constants';

type ModalUploadInvoiceProps = {
  onCloseRequest: () => void;
  createInvoice: (data: { data: InvoiceClass; callback: (id?: string) => void }) => void;
  invoice: Invoice;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
  onDeleteRequest: (data: { id: string }) => void;
};

function ModalUploadInvoice({
  onCloseRequest,
  error,
  isLoading,
  isOpen,
  invoice,
  createInvoice,
  onDeleteRequest,
}: ModalUploadInvoiceProps) {
  const intl = useIntl();

  const fileRef = useRef<HTMLInputElement>(null);

  const [fileName, setFileName] = useState('');

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit, setFieldError } = useFormik({
    initialValues: new InvoiceClass(invoice),
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: INVOICE_UPLOAD_SCHEMA,
    onSubmit: data => {
      return createInvoice({
        data,
        callback: () => {
          onCloseRequest();
        },
      });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  useEffect(() => {
    if (invoice?.invoiceFile?.url) {
      setFileName(EFileName.INVOICE + '.pdf');
    }
  }, [invoice]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleChangeFile = useCallback(
    event => {
      const file = event.target.files[0];
      if (file) {
        setFieldValue('invoiceFile', file);

        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = () => {
          if (typeof fr.result === 'string') {
            setFileName(file.name);
          }
        };
      }
    },
    [fileRef, invoice],
  );

  const handleReset = useCallback(() => {
    setFieldValue('invoiceFile', '');
    setFileName('');
    if (invoice?.invoiceFile?.id) {
      onDeleteClick();
    }
  }, [invoice]);

  const onDeleteClick = useCallback(() => {
    onDeleteRequest({ id: invoice.id });
  }, [invoice]);

  const handleInvoiceAmount = useCallback(item => {
    const amount = new CurrencyFormatter({
      ...item,
      float: item.float || 0,
    });
    setFieldValue('invoiceAmount', amount);
  }, []);

  const handleChangeDate = useCallback((name, value) => {
    if (!value) {
      setFieldValue(name, '');
      return;
    }

    setFieldValue(name, moment(value).format(DATE_FORMAT.YYYY_MM_DD));
  }, []);

  const handleUploadClick = useCallback(() => {
    fileRef.current?.click();
  }, [fileRef]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.uploadInvoiceFileTitle)}
      classNameModal="invoice-modal"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <span className="invoice-file-label">
              <FormattedMessage {...messages.invoiceFilePdfButton} />
            </span>
            <div className="form__input-block flex invoice-modal--file-input">
              <input
                type="file"
                ref={fileRef}
                name="invoiceFile"
                onChange={handleChangeFile}
                accept=".pdf"
                className="file-input"
              />
              <button type="button" onClick={handleUploadClick} className="file-button">
                <FormattedMessage {...messages.choseFileButton} />
              </button>
              <span className="file-placeholder">
                {fileName || <FormattedMessage {...messages.noFileChosenButton} />}
              </span>
              <span
                onClick={handleReset}
                className={`recognition__icon-reset${!values.invoiceFile ? ' disabled' : ''}`}
              >
                <FormattedMessage {...messages.removeButton} />
              </span>
            </div>
            <div className="form__inputs-subwrapper">
              <div className="form__input-block invoice-modal--half-input">
                <Input
                  type="date"
                  label={intl.formatMessage(messages.sendingDate)}
                  name="sendingDate"
                  defaultValue={values.sendingDate || undefined}
                  onChange={event => handleChangeDate('sendingDate', event.target.value)}
                  hasError={hasError(`sendingDate`)}
                  errorMessage={errors?.sendingDate}
                />
              </div>
              <div className="form__input-block invoice-modal--half-input">
                <Input
                  type="date"
                  label={intl.formatMessage(messages.closingDate)}
                  name="closingDate"
                  max={moment().format(DATE_FORMAT.YYYY_MM_DD)}
                  defaultValue={values.closingDate || undefined}
                  onChange={event => handleChangeDate('closingDate', event.target.value)}
                  hasError={hasError(`closingDate`)}
                  errorMessage={errors?.closingDate}
                />
              </div>
            </div>
            <div className="form__input-block">
              <Input
                name="invoiceNumber"
                label={intl.formatMessage(messages.invoiceNumber)}
                onChange={handleChange}
                defaultValue={values.invoiceNumber || 0}
                hasError={hasError('invoiceNumber')}
                errorMessage={errors?.invoiceNumber}
              />
            </div>
            <div className="form__inputs-subwrapper">
              <CurrencyInput
                name="invoiceAmount"
                label={intl.formatMessage(messages.invoiceAmount)}
                value={values.invoiceAmount.value}
                onChange={handleInvoiceAmount}
                //@ts-ignore
                errorMessage={errors?.invoiceAmount?.float || errors?.invoiceAmount}
                hasError={hasError('invoiceAmount')}
                wrapperClass={'rate-input'}
              />
            </div>
          </div>
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button
            color={'gray'}
            externalClass={'button--modal button--cancel'}
            type={'button'}
            onClick={onCloseRequest}
          >
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalUploadInvoice;
