import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import * as filtersActions from '../../actions/filters.actions';
import * as financeActions from '../../actions/finance.actions';
import AccessChecker from '../../components/AccessChecker';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import InlineDatePicker from '../../components/InlineDatePicker';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { transactionsUnsavedParams } from '../../constants/finance.constants';
import { UPDATE_INVOICE } from '../../constants/policies.constants';
import { Invoice, InvoiceClass, InvoiceCloneClass } from '../../enums/finance/finance.enum';
import { getBaseCurrency } from '../../utils';
import { checkParamsMatch } from '../../utils/filters.utils';
import InvoiceFilter from '../../components/Invoices/Filter';
import { useParamsChange } from '../../utils/hooks.utils';
import messages from './messages';
import { useDataForTable } from './useDataForTable';
import { InvoicesParams } from '../../enums/params/cashflow.params';
import ModalNewInvoice from '../../components/Invoices/Modal/ModalNewInvoice';
import ModalEditInvoice from '../../components/Invoices/Modal/ModalEditInvoice';
import ModalDeleteInvoice from '../../components/Invoices/Modal/ModalDeleteInvoice';
import ModalCloneInvoice from '../../components/Invoices/Modal/ModalCloneInvoice';
import ModalUploadInvoice from '../../components/Invoices/Modal/ModalUploadInvoice';

function Invoices({
  tableData,
  financeProjectsFilter,
  clientsFilter,
  userList,
  currencies,
  isLoading,
  invoiceError,
  invoiceListError,
  createInvoice,
  editInvoice,
  deleteInvoice,
  cloneInvoice,
  uploadInvoice,
  deleteInvoiceFile,
  generateTaskList,
  downloadInvoice,
  setInvoicesParams,
  getInvoicesList,
  getFinanceProjectsFilter,
  getClientsFilter,
  getUsersFilter,
  getCurrenciesFilter,
  resetErrors,
  resetState,
  params,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();

  const [modalNewInvoiceIsOpen, setModalNewInvoiceIsOpen] = useState(false);
  const [modalEditInvoiceIsOpen, setModalEditInvoiceIsOpen] = useState(false);
  const [modalDeleteInvoiceIsOpen, setModalDeleteInvoiceIsOpen] = useState(false);
  const [modalCloneInvoiceIsOpen, setModalCloneInvoiceIsOpen] = useState(false);
  const [modalUploadInvoiceIsOpen, setModalUploadInvoiceIsOpen] = useState(false);
  const [invoiceClicked, setInvoiceClicked] = useState<Invoice>(new Invoice());

  useEffect(() => {
    getClientsFilter();
    getCurrenciesFilter();
    getUsersFilter();

    getInvoicesList();

    return () => {
      resetState();
    };
  }, []);

  const openNewInvoiceModal = useCallback(() => {
    setModalNewInvoiceIsOpen(true);
  }, []);

  const closeNewInvoiceModal = useCallback(() => {
    setModalNewInvoiceIsOpen(false);
    resetErrors();
  }, []);

  const closeEditInvoiceModal = useCallback(() => {
    setModalEditInvoiceIsOpen(false);
    resetErrors();
  }, []);

  const closeDeleteInvoiceModal = useCallback(() => {
    setModalDeleteInvoiceIsOpen(false);
    resetErrors();
  }, []);

  const closeCloneInvoiceModal = useCallback(() => {
    setModalCloneInvoiceIsOpen(false);
    resetErrors();
  }, []);

  const closeUploadInvoiceModal = useCallback(() => {
    setModalUploadInvoiceIsOpen(false);
    resetErrors();
  }, []);

  const handleSort = useCallback((sortBy, direction) => setInvoicesParams({ sortBy, direction }), []);

  const onDateChange = (start: string, end: string) => {
    setInvoicesParams({ dateFrom: start.split(' ')[0], dateTo: end.split(' ')[0] });
  };

  const handleMultiParamsChange = useParamsChange(setInvoicesParams, dispatch);

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  const convertedData = useMemo(() => {
    if (tableData?.content && tableData.content.length) {
      const table = tableData.content;

      const totalInvoice = tableData.content.reduce(
        (sum: { jiraHours: number; autoAmount: number; invoiceAmount: number }, current: Invoice) => {
          return {
            jiraHours: sum.jiraHours + Number(current.jiraHours || 0),
            autoAmount: sum.autoAmount + Number(current.autoAmount || 0),
            invoiceAmount: sum.invoiceAmount + Number(current.invoiceAmount || 0),
          };
        },
        { jiraHours: 0, autoAmount: 0, invoiceAmount: 0 },
      );

      table.push({ ...totalInvoice, total: true, currency: baseCurrency });

      return table;
    }

    return [];
  }, [tableData]);

  const pageable = useMemo(
    () => ({
      ...tableData?.pageable,
      ...tableData?.sort,
      totalElements: tableData?.totalElements,
      numberOfElements: tableData?.numberOfElements,
      totalPages: tableData?.totalPages,
    }),
    [tableData],
  );

  const renderDropdownBtn = useCallback((row: Invoice) => {
    return row.invoiceFile;
  }, []);

  const { tableColumns, tableActions } = useDataForTable(
    baseCurrency,
    pageable,
    generateTaskList,
    downloadInvoice,
    renderDropdownBtn,
    setInvoiceClicked,
    setModalEditInvoiceIsOpen,
    setModalDeleteInvoiceIsOpen,
    setModalCloneInvoiceIsOpen,
    setModalUploadInvoiceIsOpen,
  );

  const handlePageChange = useCallback(({ selected }) => {
    setInvoicesParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setInvoicesParams({ size: data, page: 0 });
  }, []);

  const handleClear = useCallback(() => {
    setInvoicesParams(new InvoicesParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }));
  }, [params]);

  const showClearButton = useMemo(() => !checkParamsMatch(params, new InvoicesParams(), transactionsUnsavedParams), [
    params,
  ]);

  return (
    <>
      <div className="page__panel page__panel--fixed transactions__page-panel">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <InlineDatePicker
              onDateChange={onDateChange}
              defaultPeriodStart={params.dateFrom}
              defaultPeriodEnd={params.dateTo}
            />
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_INVOICE]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewInvoiceModal}>
                    <Icon iconName="plus" externalClass="button__icon" />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <InvoiceFilter
                  handleMultiParamsChange={handleMultiParamsChange}
                  filters={clientsFilter}
                  values={params}
                  handleClear={handleClear}
                  handleSort={handleSort}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table expense-types-table'}
            tableColumns={tableColumns}
            tableData={convertedData}
            loading={isLoading.getInvoices}
            error={invoiceListError}
            tableActions={tableActions}
          />
          <Pagination pageable={pageable} onPageChange={handlePageChange} onPageSizeChange={handleSizeChange} />
        </div>
      </div>
      {modalUploadInvoiceIsOpen && (
        <ModalUploadInvoice
          isOpen
          error={invoiceError}
          isLoading={isLoading.uploadInvoice}
          invoice={invoiceClicked}
          createInvoice={uploadInvoice}
          onDeleteRequest={deleteInvoiceFile}
          onCloseRequest={closeUploadInvoiceModal}
        />
      )}
      {modalNewInvoiceIsOpen && (
        <ModalNewInvoice
          isOpen
          error={invoiceError}
          isLoading={isLoading.createInvoice}
          users={userList}
          clients={clientsFilter?.clients}
          projects={financeProjectsFilter?.financeProjects}
          currencies={currencies}
          getCurrenciesList={getCurrenciesFilter}
          createInvoice={createInvoice}
          getProjectsList={getFinanceProjectsFilter}
          onCloseRequest={closeNewInvoiceModal}
        />
      )}
      {modalEditInvoiceIsOpen && (
        <ModalEditInvoice
          isOpen
          error={invoiceError}
          isLoading={isLoading.createInvoice}
          invoice={invoiceClicked}
          users={userList}
          clients={clientsFilter?.clients}
          projects={financeProjectsFilter?.financeProjects}
          currencies={currencies}
          getCurrenciesList={getCurrenciesFilter}
          createInvoice={editInvoice}
          getProjectsList={getFinanceProjectsFilter}
          onCloseRequest={closeEditInvoiceModal}
          onDeleteRequest={deleteInvoiceFile}
        />
      )}
      {modalDeleteInvoiceIsOpen && (
        <ModalDeleteInvoice
          isOpen
          onCloseRequest={closeDeleteInvoiceModal}
          onDeleteRequest={deleteInvoice}
          isLoading={isLoading.deleteInvoice}
          error={invoiceError}
          invoice={invoiceClicked}
        />
      )}
      {modalCloneInvoiceIsOpen && (
        <ModalCloneInvoice
          isOpen
          error={invoiceError}
          isLoading={isLoading.cloneInvoice}
          invoice={invoiceClicked}
          users={userList}
          cloneInvoice={cloneInvoice}
          onCloseRequest={closeCloneInvoiceModal}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ finance, filters, auth }: RootState) => ({
  invoiceError: finance.errors.invoiceError,
  invoiceListError: finance.errors.invoiceListError,
  isLoading: finance.loading,
  tableData: finance.invoicesListData,
  userList: filters.usersFilter.users,
  financeProjectsFilter: filters.financeProjectsFilter,
  clientsFilter: filters.clientsFilter,
  currencies: filters.currenciesFilter.currencies,
  params: finance.invoicesParams,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInvoicesList: () => dispatch(financeActions.getInvoicesList()),
  createInvoice: (data: { data: InvoiceClass; callback: () => void }) => dispatch(financeActions.createInvoice(data)),
  editInvoice: (data: { data: InvoiceClass; callback: () => void }) => dispatch(financeActions.editInvoice(data)),
  deleteInvoice: (data: { id: string; deleteFutureInvoices: boolean; callback: () => void }) =>
    dispatch(financeActions.deleteInvoice(data)),
  cloneInvoice: (data: { data: InvoiceCloneClass; callback: () => void }) =>
    dispatch(financeActions.cloneInvoice(data)),
  uploadInvoice: (data: { data: InvoiceClass; callback: () => void }) => dispatch(financeActions.uploadInvoice(data)),
  generateTaskList: (data: Invoice) => dispatch(financeActions.generateTaskList(data)),
  downloadInvoice: (id: string) => dispatch(financeActions.downloadInvoice(id)),
  setInvoicesParams: (data: Partial<InvoicesParams>) => dispatch(financeActions.setInvoicesParams(data)),
  deleteInvoiceFile: (data: { id: string }) => dispatch(financeActions.deleteInvoiceFile(data)),

  getClientsFilter: () => dispatch(filtersActions.getClientsFilter()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getFinanceProjectsFilter: () => dispatch(filtersActions.getFinanceProjectsFilter()),
  getCurrenciesFilter: () => dispatch(filtersActions.getCurrenciesFilter()),

  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Invoices);
