import React, { useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { UserInfo } from '../../../enums/users.enum';
import r from '../../../constants/routes.constants';
import Table from '../../Table';
import Icon from '../../Icon';
import Avatar from '../../Profile/Avatar';
import { URI_SCHEMES } from '../../../constants/users.constants';
import Label from '../../Label';
import CopyToClickboard from '../../CopyToClickboard/CopyToClickboard';
import { useIntl, FormattedMessage } from 'react-intl';
import messages from '../messages';
import { DELETE_ACTIVE_EMPLOYEE } from '../../../constants/policies.constants';
import PoliciesContext from '../../../PoliciesContext';
import { checkPolicies } from '../../../utils/policies.utils';

type ListLayoutProps = {
  users: UserInfo[];
  loading: boolean;
  error: string | null;
  updateUserPartialInfo: (uuid: string, data: any, cb: () => void) => void;
  getUsers: () => void;
  authUserId: string;
};

function ListLayout({ users, loading, error, updateUserPartialInfo, getUsers, authUserId }: ListLayoutProps) {
  const intl = useIntl();
  const policies = useContext(PoliciesContext);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: UserInfo) => (
          <>
            <NavLink to={`${r.users}/${row.id}`} className="list-employee">
              <div className="list-employee__avatar-wrapper">
                <Avatar userInfo={row} externalClass="grid-employee__avatar" size="large" fileSize={120} />
                {row.hidden && (
                  <div className="list-employee__avatar-icon">
                    <Icon iconName="eye-crossed" />
                  </div>
                )}
              </div>
              <div>
                <span>
                  <CopyToClickboard text={row.fullName}>
                    <span className="table__data--bold text-to-copy">{row.fullName}</span>
                  </CopyToClickboard>
                </span>
                <span>{row.positionName}</span>
              </div>
            </NavLink>
          </>
        ),
      },
      {
        name: intl.formatMessage(messages.labelsTitle),
        className: 'table__data--labels',
        modifier: (row: UserInfo) => (
          <div className="list-employee__labels">
            {row.showRecCount && (
              <NavLink to={`${r.users}/${row.id}?tab_name=recognitions`}>
                <Label type="recognitions">
                  <Icon iconName="thumbs-up" />
                  {row.recognitionsCount}
                </Label>
              </NavLink>
            )}
            {row.showAbsences && (
              <div className="grid-employee__absence list">
                <NavLink to={`${r.users}/${row.id}?tab_name=schedule`}>
                  <Label type="absence" color={row.absence.eventTypeColor}>
                    {row.absence.eventTypeName}
                  </Label>
                </NavLink>
              </div>
            )}
          </div>
        ),
      },
      {
        name: intl.formatMessage(messages.locationTitle),
        modifier: (row: UserInfo) => (
          <div>
            <span>
              <Icon iconName={'building'} /> {row.departmentName}
            </span>
            <span>
              <Icon iconName={'map-marker'} /> {row.officeName}
            </span>
          </div>
        ),
      },
      {
        name: intl.formatMessage(messages.contactsTitle),
        modifier: (row: UserInfo) => (
          <div>
            <span>
              <Icon iconName={'envelope'} />
              <CopyToClickboard text={row.email}>
                <a href={`mailto:${row.email}`} className="page__profile-contact text-to-copy">
                  {row.email}
                </a>
              </CopyToClickboard>
            </span>
            <span>
              <Icon iconName={'jira'} />
              <a
                href={`https://jira.softarex.com/secure/ViewProfile.jspa?name=${row.jiraName}`}
                className="page__profile-contact"
                target="_blank"
                rel="noreferrer"
              >
                {row.jiraName}
              </a>
            </span>
          </div>
        ),
      },
      {
        name: intl.formatMessage(messages.phonesLabel),
        modifier: (row: UserInfo) => (
          <div>
            <span>
              <Icon iconName={'skype'} />
              {row.skypeContact === '-' ? (
                <a href={`${URI_SCHEMES.SKYPE}${row.skypeContact}`} className="page__profile-contact">
                  {row.skypeContact}
                </a>
              ) : (
                <CopyToClickboard text={row.skypeContact}>
                  <a href={`${URI_SCHEMES.SKYPE}${row.skypeContact}`} className="page__profile-contact text-to-copy">
                    {row.skypeContact}
                  </a>
                </CopyToClickboard>
              )}
            </span>
            <span>
              <Icon iconName={'phone'} />
              <a href={`tel:${row.phoneNumber}`} className="page__profile-contact">
                {row.phoneNumber}
              </a>
            </span>
          </div>
        ),
      },
    ],
    [],
  );

  const tableActions = useMemo(() => {
    return [
      ...(checkPolicies([DELETE_ACTIVE_EMPLOYEE], policies)
        ? [
            {
              label: (row: any) =>
                !!(row.id !== authUserId) ? (
                  row.hidden ? (
                    <FormattedMessage {...messages.unhideUserAccountButton} />
                  ) : (
                    <FormattedMessage {...messages.hideUserAccountButton} />
                  )
                ) : null,
              itemClassName: 'dropdown__list-item__button',
              handler: (row: any) => {
                updateUserPartialInfo(row.id, row.hidden ? { hidden: !row.hidden } : { hidden: !row.hidden }, getUsers);
              },
            },
          ]
        : []),
      ...(checkPolicies([DELETE_ACTIVE_EMPLOYEE], policies)
        ? [
            {
              label: (row: any) =>
                !!(row.id !== authUserId) ? (
                  row.active ? (
                    <FormattedMessage {...messages.deactivateUserAccountButton} />
                  ) : (
                    <FormattedMessage {...messages.activateUserAccountButton} />
                  )
                ) : null,
              itemClassName: 'dropdown__list-item__button',
              handler: (row: UserInfo) => {
                updateUserPartialInfo(
                  row.id,
                  row.active ? { active: !row.active, includedInPlanning: false } : { active: !row.active },
                  getUsers,
                );
              },
            },
          ]
        : []),
    ];
  }, [authUserId]);

  return (
    <Table
      externalClass="table--employees"
      tableColumns={tableColumns}
      tableData={users}
      tableActions={tableActions}
      loading={loading}
      error={error}
      hideHead
    />
  );
}

export default ListLayout;
