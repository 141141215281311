import React, { useCallback, useState } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { Invoice } from '../../../enums/finance/finance.enum';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import Checkbox from '../../Checkbox';
import { formatValue } from 'react-currency-input-field';

type ModalDeleteInvoiceProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  onDeleteRequest: (data: { id: string; deleteFutureInvoices: boolean; callback: () => void }) => void;
  invoice: Invoice;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
};

function ModalDeleteInvoice({
  isOpen,
  onCloseRequest,
  onDeleteRequest,
  invoice,
  error,
  isLoading,
}: ModalDeleteInvoiceProps) {
  const intl = useIntl();

  const [deleteFutureInvoices, setDeleteFutureInvoices] = useState(false);

  const onDeleteClick = useCallback(() => {
    onDeleteRequest({ id: invoice.id, deleteFutureInvoices: deleteFutureInvoices, callback: onCloseRequest });
  }, [invoice, deleteFutureInvoices]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.deleteInvoiceTitle)}
      size={'small'}
      shouldCloseOnOverlayClick
      classNameModal={'salary-delete-modal'}
    >
      <div className="form__inputs-wrapper">
        <div className="data-block flex-block">
          <div>
            <div className="label-block">
              <FormattedMessage {...messages.clientLabel} />
            </div>
            <div>{invoice.client?.name}</div>
          </div>
          <div>
            <div className="label-block">
              <FormattedMessage {...messages.projectLabel} />
            </div>
            <div>{invoice.financeProject?.name}</div>
          </div>
        </div>
        <div className="data-block">
          <div className="label-block">
            <FormattedMessage {...messages.invoicingPeriod} />
          </div>
          <div>
            {moment(invoice.invoicingDateStart).format(DATE_FORMAT.YYYY_MM_DD)} -{' '}
            {moment(invoice.invoicingDateEnd).format(DATE_FORMAT.YYYY_MM_DD)}
          </div>
        </div>
        <div className="data-block flex-block">
          <div>
            <div className="label-block">
              <FormattedMessage {...messages.jiraHours} />
            </div>
            <div>{invoice.jiraHours}</div>
          </div>
          <div>
            <div className="label-block">
              <FormattedMessage {...messages.autoAmount} />
            </div>
            <div>
              {formatValue({
                value: invoice.autoAmount.toString(),
              })}
            </div>
          </div>
          <div>
            <div className="label-block">
              <FormattedMessage {...messages.invoiceAmount} />
            </div>
            <div>
              {formatValue({
                value: invoice.invoiceAmount.toString(),
              })}
            </div>
          </div>
        </div>
        <Checkbox
          externalClass="form__checkbox-capitalazie-label"
          onChange={event => setDeleteFutureInvoices(event.target.checked)}
          id="deleteFutureInvoices"
          label={intl.formatMessage(messages.deleteFutureInvoices)}
          checked={deleteFutureInvoices}
        />
      </div>
      <ErrorMessage>{error}</ErrorMessage>
      <div className={'form__buttons'}>
        <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={onCloseRequest} block>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button externalClass={'button--modal'} onClick={onDeleteClick} loading={isLoading} disabled={isLoading} block>
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteInvoice;
