import { get } from 'lodash-es';
import { getDateParams } from '../../utils/params.utils';
import { SortParams } from '../params.enum';
import { ExpenseType, ProfitLossSubExpenseBlock, SubExpenseType } from '../../types/finance';
import { OfficeInfo } from '../libraries.enum';

export class CashFlowReportParams {
  dateFrom: string;
  dateTo: string;
  officeIds: string[];
  blockType: string;

  constructor(params?: unknown) {
    this.dateFrom = get(params, 'dateFrom', getDateParams({ withTime: false, period: 'year' }));
    this.dateTo = get(params, 'dateTo', getDateParams({ withTime: false, getFromStart: false, period: 'year' }));
    this.officeIds = get(params, 'officeIds', []);
    this.blockType = get(params, 'blockType', 'FIRST_INCOME_BLOCK');
  }
}

export class CashFlowSettingClass {
  name: string;
  showEmptyRecords: boolean;
  cashFlowExpenseTypeBlockSettings: {
    expenseTypeId: string | undefined;
    positionNumber: number;
  };
  cashFlowIncomeTypeBlockSettings: {
    incomeTypeId: string | undefined;
    positionNumber: number;
  };
  profitLossReportIncomeBlockSettings: {
    incomeTypeId: string | undefined;
    positionNumber: number;
  };

  constructor(params?: unknown) {
    this.name = get(params, 'name');
    this.showEmptyRecords = get(params, 'showEmptyRecords');
    this.cashFlowExpenseTypeBlockSettings = get(params, 'cashFlowExpenseTypeBlockSettings', undefined);
    this.cashFlowIncomeTypeBlockSettings = get(params, 'cashFlowIncomeTypeBlockSettings', undefined);
    this.profitLossReportIncomeBlockSettings = get(params, 'profitLossReportIncomeBlockSettings', undefined);
  }
}

export class InvoicesParams extends SortParams {
  size: number;
  page: number;
  dateFrom: string;
  dateTo: string;
  clientIds: string[];
  projectIds: string[];
  isFromTransaction: boolean | null;

  constructor(params?: unknown) {
    super('client', params);
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
    this.dateFrom = get(params, 'dateFrom', getDateParams({ withTime: false, period: 'month' }));
    this.dateTo = get(params, 'dateTo', getDateParams({ withTime: false, getFromStart: false, period: 'month' }));
    this.clientIds = get(params, 'clientIds', []);
    this.projectIds = get(params, 'projectIds', []);
    this.isFromTransaction = get(params, 'isFromTransaction', null);
  }
}

export class ProfitLossCostProjectClass {
  name: string;
  groupByClient: boolean;
  blockType: 'COST_OF_GOODS' | 'COST_OF_PRODUCTS';
  profitLossReportProjectBlockSettings: {
    financeProjectId: string;
    positionNumber: number;
  }[];

  constructor(params?: unknown) {
    this.name = get(params, 'name');
    this.groupByClient = get(params, 'groupByClient');
    this.blockType = get(params, 'blockType', 'COST_OF_GOODS');
    this.profitLossReportProjectBlockSettings = get(params, 'profitLossReportProjectBlockSettings', undefined);
  }
}

export class ProfitLossValue {
  amount: string | number | null;
  endDate: string | null;
  expenseType: ExpenseType;
  expenseTypeId: string;
  id: string;
  office: OfficeInfo;
  officeId: string;
  startDate: string | null;
  subExpenseType: SubExpenseType;
  subExpenseTypeId: string;

  constructor(params?: unknown) {
    this.id = get(params, 'id');
    this.amount = get(params, 'amount');
    this.expenseTypeId = get(params, 'expenseTypeId');
    this.expenseType = get(params, 'expenseType');
    this.endDate = get(params, 'endDate');
    this.office = get(params, 'office');
    this.officeId = get(params, 'officeId');
    this.startDate = get(params, 'startDate');
    this.subExpenseType = get(params, 'subExpenseType');
    this.subExpenseTypeId = get(params, 'subExpenseTypeId');
  }
}

export class ProfitLossExpenseBlock {
  profitLossReportFinancialExpenseBlockSettingId: string;
  expenseTypeId: string;
  expenseType?: ExpenseType;
  plannedExpenses?: ProfitLossValue[];
  positionNumber: number;
  profitLossReportSubExpenseBlockSettings: ProfitLossSubExpenseBlock[];

  constructor(params?: unknown) {
    this.profitLossReportFinancialExpenseBlockSettingId = get(params, 'profitLossReportFinancialExpenseBlockSettingId');
    this.expenseTypeId = get(params, 'expenseTypeId');
    this.expenseType = get(params, 'expenseType');
    this.positionNumber = get(params, 'positionNumber');
    this.profitLossReportSubExpenseBlockSettings = get(params, 'profitLossReportSubExpenseBlockSettings');
  }
}

export class ProfitLossFinancialExpense {
  name: string;
  showEmptyRecords: boolean;
  blockType: 'OPERATING_EXPENSES' | 'OTHER_OPERATING_EXPENSES';
  profitLossReportExpenseBlockSettings: ProfitLossExpenseBlock[];

  constructor(params?: unknown) {
    this.name = get(params, 'name');
    this.showEmptyRecords = get(params, 'showEmptyRecords');
    this.blockType = get(params, 'blockType', 'OPERATING_EXPENSES');
    this.profitLossReportExpenseBlockSettings = get(params, 'profitLossReportExpenseBlockSettings', undefined);
  }
}

export class ProfitLossRevenueClient {
  name: string;
  profitLossReportClientBlockSettings: {
    profitLossReportRevenueFromClientBlockSettingId: string;
    clientId: string;
    positionNumber: number;
  }[];

  constructor(params?: unknown) {
    this.name = get(params, 'name');
    this.profitLossReportClientBlockSettings = get(params, 'profitLossReportClientBlockSettings', undefined);
  }
}
