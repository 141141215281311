export const DATE_FORMAT = {
  D: 'D',
  DD: 'DD',
  dddd: 'dddd',
  ddd_D: 'ddd D',
  HH_mm: 'HH:mm',
  HH_mm_ss: 'HH:mm:ss',
  hh_mm_A: 'hh:mm A',
  ll: 'll',
  lll: 'lll',
  LL: 'LL',
  MMMM: 'MMMM',
  LT: 'LT',
  MMM: 'MMM',
  MMM_DD: 'MMM DD',
  MMM_YYYY: 'MMM, YYYY',
  MMMM_YYYY: 'MMMM YYYY',
  MMM_D: 'MMM D',
  MMMM_D: 'MMMM, D',
  MMM_D_YYYY: 'MMM D, YYYY',
  MMM_DD_YYYY: 'MMM DD, YYYY',
  MMMM_D_YYYY_h_mm_A: 'MMMM D, YYYY, h:mm A',
  YYYY: 'YYYY',
  YYYY_MM: 'YYYY-MM',
  YYYY_MM_DD: 'YYYY-MM-DD',
  YYYY_MM_DD_DOT: 'YYYY.MM.DD',
  YYYY_MM_DD_HH_mm_ss: 'YYYY-MM-DD HH:mm:ss',
  YYYY_MM_DDTHH_mm: 'YYYY-MM-DDTHH:mm',
  dddd_MMM_DD_YYYY: 'dddd, MMM DD, YYYY',
  DD_MM_YYY: 'DD.MM.YYYY',
};

export const weekday = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
