import { defineMessages } from 'react-intl';
import {
  cancelButton,
  commentLabel,
  dateLabel,
  clientLabel,
  deleteButton,
  editButton,
  lastUpdateColumn,
  newButton,
  officeLabel,
  projectLabel,
  totalRow,
  rateLabel,
  updaterColumn,
  cloneButton,
} from '../../i18n/defaultMessage';

export default defineMessages({
  dateLabel,
  newButton,
  officeLabel,
  clientLabel,
  totalRow,
  projectLabel,
  rateLabel,
  editButton,
  deleteButton,
  cloneButton,
  updaterColumn,
  lastUpdateColumn,
  cancelButton,
  commentLabel,
  pageTitle: {
    id: 'finance.transactions.title.invoice.management',
    defaultMessage: 'Invoice Management',
  },
  jiraHours: {
    id: 'finance.transactions.table.jira.hours',
    defaultMessage: 'Jira Hours (h)',
  },
  preparationDate: {
    id: 'finance.transactions.table.preparation.date',
    defaultMessage: 'Preparation date',
  },
  sendingDate: {
    id: 'finance.transactions.table.sending.date',
    defaultMessage: 'Sending date',
  },
  closingDate: {
    id: 'finance.transactions.table.closing.date',
    defaultMessage: 'Closing date',
  },
  invoiceNum: {
    id: 'finance.transactions.table.invoice.num',
    defaultMessage: 'Invoice Num',
  },
  autoAmount: {
    id: 'finance.transactions.table.auto.amount',
    defaultMessage: 'Auto Amount',
  },
  invoiceAmount: {
    id: 'finance.transactions.table.invoice.amount',
    defaultMessage: 'Invoice Amount',
  },
  uploadInvoiceFileButton: {
    id: 'finance.transactions.table.upload.invoice.file.button',
    defaultMessage: 'Upload Invoice File',
  },
  generateTaskListButton: {
    id: 'finance.transactions.table.generate.task.list.button',
    defaultMessage: 'Generate Task List',
  },
  downloadInvoiceFileButton: {
    id: 'finance.transactions.table.download.invoice.file.button',
    defaultMessage: 'Download Invoice File',
  },
});
