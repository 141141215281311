import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Papa, { ParseStepResult } from 'papaparse';
import { useFormik } from 'formik';
import * as filtersActions from '../../../actions/filters.actions';
import * as financeActions from '../../../actions/finance.actions';
import TransactionsTable from './TransactionsTable';
import {
  DecimalSeparatorEnum,
  delimitersToGuess,
  encodingPapaparse,
  ExpenseIncomeTypesGroupEnum,
  getButtonContinueIsDisabled,
  getMatchField,
  PayerRecipientGroupEnum,
  setTransactionsFieldValue,
} from '../utils';
import FilePreview from './FilePreview';
import { MatchOptionsValues } from '../utils';
import Button from '../../../components/Button';
import { NavLink, useHistory } from 'react-router-dom';
import r from '../../../constants/routes.constants';
import {
  Client,
  CurrencyFormatter,
  ImportTransaction,
  IMPORT_TRANSACTIONS_SCHEMA,
  FinanceProject,
  Invoice,
} from '../../../enums/finance/finance.enum';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';
import { getDefaultCurrency } from '../../../components/Transactions/utils';
import { connect, ConnectedProps } from 'react-redux';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { UserInfo } from '../../../enums/users.enum';
import { CurrencyType, ExpenseType, IncomeType, SupplierType } from '../../../types/finance';
import { get, groupBy, isArray, isEmpty } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import Icon from '../../../components/Icon';
import { batchCreateTransactions } from '../../../actions/finance.actions';
import { getBaseCurrency } from '../../../utils';
import { InvoicesParams } from '../../../enums/params/cashflow.params';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';

const csvFileExt = ['text/csv', 'application/vnd.ms-excel'];
const initialTransactionsValues: { transactions: ImportTransaction[] } = { transactions: [] };
function ImportTransactions({
  officesFilter,
  expenseTypesFilter,
  incomeTypesFilter,
  clientsFilter,
  suppliersFilter,
  usersFilter,
  financeProjectsFilter,
  invoiceNumbersFilter,
  currencies,
  importTransactionsLoading,
  importTransationsError,
  getOfficesFilter,
  getExpenseTypesFilter,
  getIncomeTypesFilter,
  getClientsFilter,
  getSuppliersFilter,
  getUsersFilter,
  getFinanceProjectsFilter,
  getInvoiceNumbersFilter,
  getCurrenciesFilter,
  batchCreateTransactions,
}: ConnectedProps<typeof connector>) {
  const histrory = useHistory();
  const [fileExtError, setFileExtError] = useState(false);
  const [parsedCsvData, setParsedCsvData] = useState<ParseStepResult<any> | null>(null);
  const [importLayout, setImportLayout] = useState(false);
  const [firstSubmitValidate, setFirstSubmitValidate] = useState(false);
  const [decimalSeparator, setDecimalSeparator] = useState(DecimalSeparatorEnum.DOT);
  const {
    values: transactions,
    errors,
    setFieldError,
    setFieldValue,
    handleChange,
    resetForm,
    validateForm,
    handleSubmit,
  } = useFormik({
    initialValues: initialTransactionsValues,
    enableReinitialize: false,
    validationSchema: IMPORT_TRANSACTIONS_SCHEMA,
    validateOnChange: firstSubmitValidate,
    validate: () => {
      !firstSubmitValidate && setFirstSubmitValidate(true);
    },
    onSubmit: transactions => {
      batchCreateTransactions({ data: transactions.transactions, callback: backHandler });
    },
  });

  const backHandler = () => histrory.push(r.transactions);

  useEffect(() => {
    getOfficesFilter();
    getExpenseTypesFilter();
    getIncomeTypesFilter();
    getClientsFilter();
    getSuppliersFilter();
    getUsersFilter();
    getCurrenciesFilter();
    getFinanceProjectsFilter();
    getInvoiceNumbersFilter({
      isFromTransaction: true,
      dateFrom: '',
      dateTo: moment().format(DATE_FORMAT.YYYY_MM_DD),
    });
  }, []);

  const { values: matchFormValues, setFieldValue: setMatchField } = useFormik({
    initialValues: {
      matchInfo: parsedCsvData?.meta.fields
        ? parsedCsvData.meta.fields.map(field => ({
            field,
            value: MatchOptionsValues.DO_NOT_IMPORT,
          }))
        : [],
    },
    enableReinitialize: true,
    validateOnChange: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  useEffect(() => {
    validateForm().then(() => {
      if (isArray(importTransationsError) && !isEmpty(importTransationsError)) {
        const grouppedErrors: Record<string, any> = groupBy(importTransationsError, error => error.field.split('.')[0]);
        const importedTransactions = { transactions: transactions.transactions.filter(item => item.importTransaction) };
        const fieldsErrors: {
          id: string;
          errors: {
            field: string;
            error: string;
          }[];
        }[] = [];
        for (const prop in grouppedErrors) {
          const rowWithError = get(importedTransactions, prop);

          fieldsErrors.push({
            id: rowWithError.id,
            errors: grouppedErrors[prop],
          });
        }
        transactions.transactions.forEach((transaction, index) => {
          const rowErrors = fieldsErrors.find(item => item.id === transaction.id)?.errors;
          if (rowErrors) {
            rowErrors.forEach(item => {
              const field = item.field.split('.')[1];
              setTransactionsFieldValue(setFieldError, field, index, item.error, false);
            });
          }
        });
      }
    });
  }, [importTransationsError]);

  const handleFileUpload = useCallback(event => {
    const file = event.target.files[0];
    resetForm();
    setFirstSubmitValidate(false);
    if (file) {
      const fileType = file?.type;
      const isCorrectExt = csvFileExt.some(ext => ext === fileType);

      if (isCorrectExt) {
        setFileExtError(false);
        parseFile(file);
      } else {
        setFileExtError(true);
        setParsedCsvData(null);
      }
    }
  }, []);

  const parseFile = (file: File) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      encoding: encodingPapaparse,
      delimitersToGuess: delimitersToGuess,
      complete: results => {
        setParsedCsvData(results);
      },
    });
  };

  const handleMatchFieldChange = useCallback(
    (field: string, value: any) => {
      setMatchField(field, value);
      if (!isEmpty(transactions.transactions)) {
        resetForm();
        setFirstSubmitValidate(false);
      }
    },
    [transactions],
  );

  const handleDecimalSeparatorChange = useCallback(
    (separator: DecimalSeparatorEnum) => {
      setDecimalSeparator(separator);
      if (!isEmpty(transactions.transactions)) {
        resetForm();
        setFirstSubmitValidate(false);
      }
    },
    [transactions],
  );

  const expenseTypesOptions = useMemo(
    () =>
      expenseTypesFilter.expenseTypes.map((el: ExpenseType) => ({
        label: el.name,
        value: el,
        type: ExpenseIncomeTypesGroupEnum.EXPENSE_TYPES,
      })),
    [expenseTypesFilter.expenseTypes],
  );

  const incomeTypesOptions = useMemo(
    () =>
      incomeTypesFilter.incomeTypes.map((el: IncomeType) => ({
        label: el.name,
        value: el.id,
        type: ExpenseIncomeTypesGroupEnum.EXPENSE_TYPES,
      })),
    [incomeTypesFilter.incomeTypes],
  );

  const officesOptions = useMemo(
    () =>
      officesFilter.offices.map((office: OfficeInfo) => {
        return {
          label: office.name,
          value: office.id,
          type: PayerRecipientGroupEnum.OFFICES,
        };
      }),
    [officesFilter.offices],
  );

  const usersOptions = useMemo(
    () =>
      usersFilter.users.map((user: UserInfo) => ({
        label: user.fullName,
        value: user,
        type: PayerRecipientGroupEnum.USERS,
      })),
    [usersFilter.users],
  );

  const clientsOptions = useMemo(
    () =>
      clientsFilter.clients.map((client: Client) => ({
        label: client.name,
        value: client.id,
        type: PayerRecipientGroupEnum.CLIENTS,
      })),
    [clientsFilter.clients],
  );

  const suppliersOptions = useMemo(
    () =>
      suppliersFilter.suppliers.map((supplier: SupplierType) => ({
        label: supplier.name,
        value: supplier,
        type: PayerRecipientGroupEnum.SUPPLIERS,
      })),
    [suppliersFilter.suppliers],
  );

  const financeProjectsOptions = useMemo(
    () =>
      financeProjectsFilter.financeProjects.map((financeProject: FinanceProject) => ({
        label: financeProject.name,
        value: financeProject,
      })),
    [financeProjectsFilter.financeProjects],
  );

  const invoicesOptions = useMemo(
    () =>
      invoiceNumbersFilter?.content
        ? invoiceNumbersFilter.content.map((invoice: Invoice) => ({
            label: invoice.invoiceNumber || '',
            value: invoice,
          }))
        : [],
    [invoiceNumbersFilter.content],
  );

  const currenciesOptions = useMemo(
    () =>
      currencies.map((currency: CurrencyType) => ({
        label: currency.name,
        value: currency,
      })),
    [currencies],
  );

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  const handleAmountChange = useCallback(
    (value: CurrencyInputOnChangeValues, row: ImportTransaction, index = 0) => {
      if (value.value !== row.amount.value) {
        const amount = value.value ? value : getDefaultCurrency();

        setTransactionsFieldValue(setFieldValue, 'amountHasParseError', index, false);
        const unifiedAmountValue = (Number(value.float) * Number(row.rate?.float)).toFixed(2);
        setTransactionsFieldValue(
          setFieldValue,
          'unifiedAmount',
          index,
          new CurrencyFormatter({
            float: Number(unifiedAmountValue),
            value: unifiedAmountValue,
            formatted: unifiedAmountValue,
          }),
          false,
        );
        setTransactionsFieldValue(setFieldValue, 'amount', index, amount, false);
        firstSubmitValidate && setTimeout(validateForm, 1);
      }
    },
    [firstSubmitValidate],
  );

  const handleRateChange = useCallback(
    (value: CurrencyInputOnChangeValues, row: ImportTransaction, index = 0) => {
      if (value.value !== row.rate.value) {
        const rate = value.value
          ? new CurrencyFormatter({
              ...value,
              float: value.float || 0,
            })
          : getDefaultCurrency();

        const unifiedAmountValue = (Number(value.float) * Number(row.amount.float)).toFixed(2);
        setTransactionsFieldValue(
          setFieldValue,
          'unifiedAmount',
          index,
          new CurrencyFormatter({
            float: Number(unifiedAmountValue),
            value: unifiedAmountValue,
            formatted: unifiedAmountValue,
          }),
          false,
        );
        setTransactionsFieldValue(setFieldValue, 'rate', index, rate, false);
        firstSubmitValidate && setTimeout(validateForm, 1);
      }
    },
    [firstSubmitValidate],
  );

  const handleUnifiedAmountChange = useCallback((value: CurrencyInputOnChangeValues, index = 0) => {
    const unifiedAmount = value.value
      ? new CurrencyFormatter({
          ...value,
          float: value.float || 0,
        })
      : getDefaultCurrency();
    setTransactionsFieldValue(setFieldValue, 'unifiedAmount', index, unifiedAmount);
  }, []);

  const handleContinueBtn = () => {
    setImportLayout(true);
    if (isEmpty(transactions.transactions)) {
      const matchField = getMatchField(matchFormValues.matchInfo);
      const transactionsValues = parsedCsvData?.data.map(
        (el: Record<string, string>) =>
          new ImportTransaction(
            {
              transactionDate: matchField.transactionsDate ? el[matchField.transactionsDate] : undefined,
              amount: matchField.originalAmount ? el[matchField.originalAmount] : undefined,
              comment: matchField.comment ? el[matchField.comment] : undefined,
              operationType: matchField.operationType ? el[matchField.operationType] : undefined,
              rate: '1.00',
              unifiedAmount: matchField.originalAmount ? el[matchField.originalAmount] : undefined,
              currencyId: baseCurrency?.id,
              currency: baseCurrency,
            },
            decimalSeparator,
          ),
      );
      setFieldValue('transactions', transactionsValues);
    }
  };

  const handleImportBtn = () => {
    handleSubmit();
  };

  const handleFileInputClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    (e.target as HTMLInputElement).value = '';
    setParsedCsvData(null);
  };

  const countOfImportRecords = transactions.transactions.filter(item => item.importTransaction).length;

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.importPageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom" style={{ display: importLayout ? 'none' : '' }}>
            <div>
              <input
                type="file"
                name="transactions"
                onChange={handleFileUpload}
                onClick={handleFileInputClick}
                accept=".csv"
              />
              {fileExtError && (
                <p className="form__error">
                  <FormattedMessage {...messages.fileExtensionError} />
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="page__content page__transactions">
        <div className="page__wrapper">
          <div className="transactions__content-wrapper">
            {importLayout ? (
              <TransactionsTable
                transactions={transactions.transactions}
                errors={errors}
                officesOptions={officesOptions}
                expenseTypesOptions={expenseTypesOptions}
                incomeTypesOptions={incomeTypesOptions}
                clientsOptions={clientsOptions}
                suppliersOptions={suppliersOptions}
                usersOptions={usersOptions}
                financeProjectsOptions={financeProjectsOptions}
                invoiceNumbersOptions={invoicesOptions}
                currenciesOptions={currenciesOptions}
                firstSubmitValidate={firstSubmitValidate}
                baseCurrency={baseCurrency}
                handleChange={handleChange}
                handleAmountChange={handleAmountChange}
                handleRateChange={handleRateChange}
                handleUnifiedAmountChange={handleUnifiedAmountChange}
                setFieldValue={setFieldValue}
                validateForm={validateForm}
              />
            ) : (
              <FilePreview
                parsedCsvData={parsedCsvData}
                matchFormValues={matchFormValues}
                decimalSeparator={decimalSeparator}
                setMatchField={handleMatchFieldChange}
                setDecimalSeparator={handleDecimalSeparatorChange}
              />
            )}
          </div>
          <div className="form__buttons">
            <NavLink to={r.transactions}>
              <Button externalClass="button--modal button--cancel" color="gray">
                <FormattedMessage {...messages.cancelButton} />
              </Button>
            </NavLink>
            {importLayout ? (
              <>
                <Button externalClass="button--modal button--back" onClick={() => setImportLayout(false)} color="gray">
                  <Icon iconName="pagination-prev" />
                  <FormattedMessage {...messages.backBtn} />
                </Button>
                <Button
                  externalClass="button--modal button--continue"
                  onClick={handleImportBtn}
                  type="submit"
                  loading={importTransactionsLoading}
                  disabled={countOfImportRecords === 0 || importTransactionsLoading}
                >
                  <FormattedMessage values={{ count: countOfImportRecords }} {...messages.importBtn} />
                </Button>
              </>
            ) : (
              <Button
                externalClass="button--modal button--continue"
                onClick={handleContinueBtn}
                disabled={!parsedCsvData || !getButtonContinueIsDisabled(matchFormValues.matchInfo)}
              >
                <span>
                  <FormattedMessage {...messages.continueBtn} />
                </span>
                <Icon iconName="angle-right" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ filters, finance }: RootState) => ({
  officesFilter: filters.officesFilter,
  expenseTypesFilter: filters.expenseTypesFilter,
  incomeTypesFilter: filters.incomeTypesFilter,
  clientsFilter: filters.clientsFilter,
  suppliersFilter: filters.suppliersFilter,
  usersFilter: filters.usersFilter,
  financeProjectsFilter: filters.financeProjectsFilter,
  invoiceNumbersFilter: finance.invoicesListData,
  currencies: filters.currenciesFilter.currencies,
  importTransationsError: finance.errors.importTransationsError,
  importTransactionsLoading: finance.loading.importTransactions,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getExpenseTypesFilter: () => dispatch(filtersActions.getExpenseTypesFilter()),
  getIncomeTypesFilter: () => dispatch(filtersActions.getIncomeTypesFilter()),
  getClientsFilter: () => dispatch(filtersActions.getClientsFilter()),
  getSuppliersFilter: () => dispatch(filtersActions.getSuppliersFilter()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getFinanceProjectsFilter: () => dispatch(filtersActions.getFinanceProjectsFilter()),
  getInvoiceNumbersFilter: (data: Partial<InvoicesParams>) => dispatch(financeActions.getInvoicesFilter(data)),
  getCurrenciesFilter: () => dispatch(filtersActions.getCurrenciesFilter()),
  batchCreateTransactions: (data: { data: ImportTransaction[]; callback: () => void }) =>
    dispatch(batchCreateTransactions(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ImportTransactions);
