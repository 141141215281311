import { membersLabel } from './../../i18n/defaultMessage';
import { defineMessages } from 'react-intl';
import { editButton } from '../../i18n/defaultMessage';

export default defineMessages({
  editButton,
  membersLabel,
  editPermissionsButton: {
    id: 'people.user.profile.menu.btn.edit.permissions',
    defaultMessage: 'Edit Permissions',
  },
  changePasswordButton: {
    id: 'people.user.profile.menu.btn.change.password',
    defaultMessage: 'Change Password',
  },
  positionsHistoryLabel: {
    id: 'peaople.user.profile.menu.positions.label.positions.history',
    defaultMessage: 'Positions History',
  },
  editPositionsLabel: {
    id: 'peaople.user.profile.menu.positionslabel.edit.positions',
    defaultMessage: 'Edit Positions',
  },
  activateAccountLabel: {
    id: 'peaople.user.profile.menu.profile.activate.account',
    defaultMessage: 'Activate Account',
  },
  deactivateAccountLabel: {
    id: 'peaople.user.profile.menu.profile.deactivate.account',
    defaultMessage: 'Deactivate Account',
  },
  hideAccountLabel: {
    id: 'peaople.user.profile.menu.profile.hide.account',
    defaultMessage: 'Hide Account',
  },
  unhideAccountLabel: {
    id: 'peaople.user.profile.menu.profile.unhide.account',
    defaultMessage: 'Unhide Account',
  },
  passwordSuccessfullyChangedText: {
    id: 'peaople.user.profile.text.your.password.successfully.changed',
    defaultMessage: 'Your Password successfully changed',
  },
});
