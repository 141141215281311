import React, { useContext, useMemo } from 'react';
import { UserInfo } from '../../../enums/users.enum';
import { NavLink } from 'react-router-dom';
import r from '../../../constants/routes.constants';
import Icon from '../../Icon';
import Avatar from '../../Profile/Avatar';
import Label from '../../Label';
import Dropdown from '../../Dropdown';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import PoliciesContext from '../../../PoliciesContext';
import { checkPolicies } from '../../../utils/policies.utils';
import { DELETE_ACTIVE_EMPLOYEE } from '../../../constants/policies.constants';
import { isEmpty } from 'lodash-es';
import classNames from 'classnames';

type GridLayoutProps = {
  users: UserInfo[];
  loading: boolean;
  error: string | null;
  updateUserPartialInfo: (uuid: string, data: any, cb: () => void) => void;
  getUsers: () => void;
  authUserId: string;
};

function GridLayout({ users, loading, updateUserPartialInfo, getUsers, authUserId }: GridLayoutProps) {
  const policies = useContext(PoliciesContext);

  // const isCurrentUser = useMemo(() => id === authUserInfo.id, [id, authUserInfo]);

  const noDataClassName = classNames('table__no-data users-no-data', {
    'loading': loading,
    'no-data': isEmpty(users),
    'hidden': !isEmpty(users),
  });

  const tableActions = useMemo(() => {
    return [
      ...(checkPolicies([DELETE_ACTIVE_EMPLOYEE], policies)
        ? [
            {
              label: (row: any) =>
                !!(row.id !== authUserId) ? (
                  row.hidden ? (
                    <FormattedMessage {...messages.unhideUserAccountButton} />
                  ) : (
                    <FormattedMessage {...messages.hideUserAccountButton} />
                  )
                ) : null,
              itemClassName: 'dropdown__list-item__button',
              handler: (row: any) => {
                updateUserPartialInfo(row.id, row.hidden ? { hidden: !row.hidden } : { hidden: !row.hidden }, getUsers);
              },
            },
          ]
        : []),
      ...(checkPolicies([DELETE_ACTIVE_EMPLOYEE], policies)
        ? [
            {
              label: (row: any) =>
                !!(row.id !== authUserId) ? (
                  row.active ? (
                    <FormattedMessage {...messages.deactivateUserAccountButton} />
                  ) : (
                    <FormattedMessage {...messages.activateUserAccountButton} />
                  )
                ) : null,
              itemClassName: 'dropdown__list-item__button',
              handler: (row: any) => {
                updateUserPartialInfo(
                  row.id,
                  row.active ? { active: !row.active, includedInPlanning: false } : { active: !row.active },
                  getUsers,
                );
              },
            },
          ]
        : []),
    ];
  }, [authUserId]);

  return (
    <>
      {!isEmpty(users) && !loading && (
        <div className="page__content-grid active">
          {users.map((el: UserInfo) => {
            return (
              <div key={el.id} className="grid-employee-wrapper">
                <NavLink to={`${r.users}/${el.id}`} className="grid-employee">
                  <div className="grid-employee__block-avatar">
                    <Avatar userInfo={el} externalClass="grid-employee__block-avatar" size="huge" fileSize={240} />
                    {el.hidden && (
                      <div className="grid-employee__icon-top-right">
                        <Icon iconName="eye-crossed" />
                      </div>
                    )}
                  </div>
                  <div className="grid-employee__info">
                    <div className="grid-employee__name">{el.fullName}</div>
                    <div className="grid-employee__post">
                      <Icon iconName={'post'} />
                      {el.positionName}
                    </div>
                    <div className="grid-employee__department">
                      <Icon iconName={'building'} />
                      {el.departmentName}
                    </div>
                    <div className="grid-employee__town">
                      <Icon iconName={'map-marker'} />
                      {el.officeName}
                    </div>
                  </div>
                </NavLink>
                <div className="grid-employee__labels">
                  {el.showRecCount && (
                    <NavLink
                      to={`${r.users}/${el.id}?tab_name=recognitions`}
                      className="grid-employee_recognitions-link"
                    >
                      <Label type="recognitions">
                        <Icon iconName="thumbs-up" />
                        {el.recognitionsCount}
                      </Label>
                    </NavLink>
                  )}
                  {el.showAbsences && (
                    <div className="grid-employee__absence">
                      <NavLink to={`${r.users}/${el.id}?tab_name=schedule`}>
                        <Label type="absence" color={el.absence.eventTypeColor}>
                          {el.absence.eventTypeName}
                        </Label>
                      </NavLink>
                    </div>
                  )}
                </div>
                {!!tableActions.filter(item => !!item.label(el)).length && (
                  <Dropdown
                    dropdownClass="dropdown--no-bg"
                    dropdownToggle={<Icon iconName={'dots'} externalClass={'dropdown__button-main-icon'} />}
                    dropdownList={tableActions.filter(item => item)}
                    dropdownInfo={el}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
      <div className={noDataClassName} />
    </>
  );
}

export default GridLayout;
