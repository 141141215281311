import React, { useMemo } from 'react';
import { formatValue } from 'react-currency-input-field';
import { FormattedMessage, useIntl } from 'react-intl';

import moment from 'moment';

import Icon from '../../components/Icon';
import { DATE_FORMAT } from '../../constants/date.constants';
import { DELETE_INVOICE, UPDATE_INVOICE, VIEW_INVOICE } from '../../constants/policies.constants';
import { Invoice } from '../../enums/finance/finance.enum';
import { CurrencyType } from '../../types/finance';
import messages from './messages';

export const useDataForTable = (
  baseCurrency: CurrencyType | undefined,
  pageable: any,
  generateTaskList: (invoice: Invoice) => void,
  downloadInvoice: (value: string) => void,
  renderDropdownBtn: (invoice: Invoice) => boolean,
  setInvoiceClicked: (row: Invoice) => void,
  setModalEditInvoiceIsOpen: (value: boolean) => void,
  setModalDeleteInvoiceIsOpen: (value: boolean) => void,
  setModalCloneInvoiceIsOpen: (value: boolean) => void,
  setModalUploadInvoiceIsOpen: (value: boolean) => void,
) => {
  const intl = useIntl();

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.clientLabel),
        modifier: (row: Invoice) =>
          !row.total ? (
            <div className={'table__data-wrapper'}>
              <div className="name-capital-letter-block">
                <span>{row.client?.name[0]}</span>
              </div>
              <div>{row.client?.name}</div>
            </div>
          ) : (
            <FormattedMessage {...messages.totalRow} />
          ),
        sortName: 'name',
        className: 'table__data--bold',
      },
      {
        name: intl.formatMessage(messages.projectLabel),
        modifier: (row: Invoice) => <span>{row.financeProject?.name}</span>,
      },
      {
        name: intl.formatMessage(messages.jiraHours),
        modifier: (row: Invoice) => {
          const jiraHours = Number(row.jiraHours || 0);
          return jiraHours > 0 ? <span>{jiraHours}</span> : null;
        },
      },
      {
        name: intl.formatMessage(messages.preparationDate),
        modifier: (row: Invoice) => {
          const { preparationDate, preparationTime } = row;
          const hasPreparationTime = Boolean(preparationTime);

          const preparationDateTimeUtc =
            preparationDate && preparationTime ? `${preparationDate}T${preparationTime}Z` : null;
          const localPreparationDateTime = preparationDateTimeUtc ? moment(preparationDateTimeUtc).local() : null;
          const formattedPreparationDate = localPreparationDateTime?.format(DATE_FORMAT.ll) || '';

          const preparationDateTime = new Date(
            hasPreparationTime ? `${preparationDate}T${preparationTime}` : preparationDate,
          );

          const now = new Date();
          const isFutureDate = hasPreparationTime && !isNaN(preparationDateTime.getTime()) && preparationDateTime > now;

          return (
            <div className="invoice-table__time">
              {formattedPreparationDate}{' '}
              {isFutureDate && (
                <div
                  title={new Date(`1970-01-01T${preparationTime}`).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                >
                  <Icon iconName="time-quarter" />
                </div>
              )}
            </div>
          );
        },
      },
      {
        name: intl.formatMessage(messages.sendingDate),
        modifier: (row: Invoice) => {
          const sendingDate = moment(row.sendingDate, 'YYYY-MM-DD');
          const now = moment();

          return (
            <span className="invoice-table__time">
              {sendingDate.isValid() ? sendingDate.format(DATE_FORMAT.ll) : null}
              {sendingDate.isValid() && sendingDate.isAfter(now) ? (
                <div title={sendingDate.format(DATE_FORMAT.ll)}>
                  <Icon iconName="time-quarter" />
                </div>
              ) : null}
            </span>
          );
        },
      },
      {
        name: intl.formatMessage(messages.closingDate),
        modifier: (row: Invoice) => (
          <span>{row.closingDate ? moment(row.closingDate).format(DATE_FORMAT.ll) : null}</span>
        ),
      },
      {
        name: intl.formatMessage(messages.invoiceNum),
        modifier: (row: Invoice) => <span>{row.invoiceNumber}</span>,
      },
      {
        name: intl.formatMessage(messages.autoAmount),
        modifier: (row: Invoice) => {
          const autoAmount = Number(row.autoAmount || 0);
          return autoAmount > 0 ? (
            <span>
              {formatValue({
                value: autoAmount.toFixed(2),
              })}
              &nbsp;
              {row.currency?.name}
            </span>
          ) : null;
        },
      },
      {
        name: intl.formatMessage(messages.invoiceAmount),
        modifier: (row: Invoice) => {
          const invoiceAmount = Number(row.invoiceAmount || 0);
          const hasInvoiceFile = !!row.invoiceFile;

          return invoiceAmount > 0 || hasInvoiceFile ? (
            <span>
              {invoiceAmount > 0 ? (
                <span className="invoice-amount">
                  {formatValue({
                    value: invoiceAmount.toFixed(2),
                  })}
                  &nbsp;
                  {row.currency?.name}
                </span>
              ) : (
                <span className="no-amount">-</span>
              )}
              {hasInvoiceFile && <Icon iconName="invoice-light" externalClass={'invoice-icon'} />}
            </span>
          ) : null;
        },
      },
    ],
    [baseCurrency, pageable],
  );

  const tableActions = useMemo(
    () => [
      {
        label: () => (
          <>
            <Icon iconName={'download'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.generateTaskListButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Invoice) => {
          if (!row.id) return;
          generateTaskList(row);
        },
        verifiablePolicies: [VIEW_INVOICE],
      },
      {
        label: () => (
          <>
            <Icon iconName={'upload'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.uploadInvoiceFileButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Invoice) => {
          setInvoiceClicked(row);
          setModalUploadInvoiceIsOpen(true);
        },
        verifiablePolicies: [UPDATE_INVOICE],
      },
      {
        label: () => (
          <>
            <Icon iconName={'download'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.downloadInvoiceFileButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Invoice) => {
          if (!row.id) return;
          downloadInvoice(row.id);
        },
        verifiablePolicies: [VIEW_INVOICE],
        handleRenderDropdownItem: (row: Invoice) => renderDropdownBtn(row),
      },
      {
        label: () => (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Invoice) => {
          setInvoiceClicked(row);
          setModalEditInvoiceIsOpen(true);
        },
        verifiablePolicies: [UPDATE_INVOICE],
      },
      {
        label: () => (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Invoice) => {
          setInvoiceClicked(row);
          setModalDeleteInvoiceIsOpen(true);
        },
        verifiablePolicies: [DELETE_INVOICE],
      },
      {
        label: () => (
          <>
            <Icon iconName={'copy'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.cloneButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Invoice) => {
          setInvoiceClicked(row);
          setModalCloneInvoiceIsOpen(true);
        },
        verifiablePolicies: [UPDATE_INVOICE],
      },
    ],
    [],
  );

  return {
    tableColumns,
    tableActions,
  };
};
