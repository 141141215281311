import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';
import { FormattedMessage, useIntl } from 'react-intl';
import { OptionTypeBase } from 'react-select';

import { useFormik } from 'formik';
import { isNull, isString } from 'lodash-es';
import get from 'lodash-es/get';

import PoliciesContext from '../../../PoliciesContext';
import { UPDATE_TRANSACTION } from '../../../constants/policies.constants';
import { RejectValueErrors } from '../../../enums/error.enum';
import { CurrencyFormatter, Transaction, TRANSACTION_SCHEMA } from '../../../enums/finance/finance.enum';
import { FinanceProjectsParams } from '../../../enums/params/finance.params';
import { UserInfo } from '../../../enums/users.enum';
import {
  expenseIncomeTypesGroup,
  ExpenseIncomeTypesGroupEnum,
  ExpenseIncomeTypesOptionsType,
  getPayerRecipientOptions,
  payerRecipientGroup,
  PayerRecipientGroupEnum,
  PayerRecipientOptionsType,
} from '../../../pages/Transactions/utils';
import { CurrencyType, ExpenseType, SubExpenseType } from '../../../types/finance';
import { scrollToError } from '../../../utils';
import { getCurrentRateDate } from '../../../utils/finance.utils';
import { useFiltersListValue, useSetFieldsErrors } from '../../../utils/hooks.utils';
import Button from '../../Button';
import CurrencyInput from '../../CurrencyInput';
import ErrorMessage from '../../ErrorMessage';
import { FilterOptionType } from '../../Filter';
import Input from '../../Input';
import Modal from '../../Modal';
import Select from '../../Select';
import messages from '../messages';
import { getDefaultCurrency } from '../utils';
import { InvoicesParams } from '../../../enums/params/cashflow.params';
import moment from 'moment/moment';
import { DATE_FORMAT } from '../../../constants/date.constants';

type ModalNewTransactionProps = {
  isOpen: boolean;
  error: string | RejectValueErrors[] | null;
  userInfo: UserInfo;
  isLoading: boolean;
  isFinanceProjectsFilterLoading: boolean;
  expenseTypesOptions: ExpenseIncomeTypesOptionsType[];
  incomeTypesOptions: ExpenseIncomeTypesOptionsType[];
  officesOptions: PayerRecipientOptionsType[];
  usersOptions: PayerRecipientOptionsType[];
  clientsOptions: PayerRecipientOptionsType[];
  suppliersOptions: PayerRecipientOptionsType[];
  financeProjectsOptions: FilterOptionType[];
  invoiceNumbersOptions: FilterOptionType[];
  currenciesOptions: FilterOptionType[];
  baseCurrency: CurrencyType | undefined;
  createTransaction: (data: { data: Transaction; callback: () => void }) => void;
  getFinanceProjectsFilter: (data: Partial<FinanceProjectsParams>) => void;
  getInvoiceNumbersFilter: (data: Partial<InvoicesParams>) => void;
  onCloseRequest: () => void;
};

function ModalNewTransaction({
  isOpen,
  error,
  userInfo,
  isLoading,
  isFinanceProjectsFilterLoading,
  expenseTypesOptions,
  incomeTypesOptions,
  officesOptions,
  usersOptions,
  clientsOptions,
  suppliersOptions,
  financeProjectsOptions,
  invoiceNumbersOptions,
  currenciesOptions,
  baseCurrency,
  createTransaction,
  getFinanceProjectsFilter,
  getInvoiceNumbersFilter,
  onCloseRequest,
}: ModalNewTransactionProps) {
  const intl = useIntl();
  const policies = useContext(PoliciesContext);

  const updatePolicy = useMemo(() => policies.find(policy => policy.policy.name === UPDATE_TRANSACTION), [policies]);

  const [payerRecipient, setPayerRecipient] = useState<PayerRecipientGroupEnum | null>(null);
  const { values, errors, touched, handleChange, handleSubmit, setFieldError, setFieldValue } = useFormik({
    initialValues: new Transaction({
      officeId: userInfo?.office?.id,
      currencyId: baseCurrency?.id,
      currency: baseCurrency,
    }),
    validationSchema: TRANSACTION_SCHEMA,
    validateOnChange: false,
    enableReinitialize: true,
    validate: scrollToError,
    onSubmit: data => {
      return createTransaction({
        data,
        callback: onCloseRequest,
      });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  useEffect(() => {
    if (values.currencyId === baseCurrency?.id) {
      setFieldValue('rate', getDefaultCurrency(1));
      setFieldValue('unifiedAmount', values.amount);
    }
  }, [values.currencyId]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const payerRecipientOptions = useMemo(() => {
    if (values.subExpenseType && values.subExpenseType.isPersonal) {
      return usersOptions;
    }

    return payerRecipientGroup
      .filter(({ type }) => type !== PayerRecipientGroupEnum.USERS)
      .map(({ type, name }) => ({
        label: name,
        type,
        options: getPayerRecipientOptions(type, { clientsOptions, suppliersOptions, officesOptions }),
      }));
  }, [clientsOptions, suppliersOptions, officesOptions, usersOptions, values.subExpenseType]);

  const expenseIncomeTypesOptions = useMemo(() => {
    return expenseIncomeTypesGroup.map(({ type, name }) => ({
      label: name,
      type,
      options: type === ExpenseIncomeTypesGroupEnum.EXPENSE_TYPES ? expenseTypesOptions : incomeTypesOptions,
    }));
  }, [expenseTypesOptions, incomeTypesOptions]);

  const expenseSubTypesOptions = useMemo(() => {
    return values.expenseType.subExpenseTypes.map(({ id, name }) => ({
      label: name,
      value: id,
      type: ExpenseIncomeTypesGroupEnum.EXPENSE_SUB_TYPES,
    }));
  }, [values.expenseType]);

  const payerRecipientValues = useMemo(() => {
    if (isNull(payerRecipient)) {
      return [];
    }

    if (values.subExpenseTypeId && values.subExpenseType.isPersonal) {
      //@ts-ignore
      return usersOptions.find(opt => opt.value.id === values.userPayerRecipientId);
    } else {
      return (
        payerRecipientOptions
          //@ts-ignore
          .find(opt => opt?.type === payerRecipient)
          //@ts-ignore
          ?.options.find(({ value }: PayerRecipientOptionsType) => {
            const id = value instanceof Object ? value.id : value;
            return (
              id === values.clientPayerRecipientId ||
              id === values.supplierPayerRecipientId ||
              id === values.officePayerRecipientId
            );
          })
      );
    }
  }, [
    values.clientPayerRecipientId,
    values.supplierPayerRecipientId,
    values.officePayerRecipientId,
    values.userPayerRecipientId,
    values.subExpenseType,
  ]);

  const filteredOfficesOptions = useMemo(
    () =>
      officesOptions?.filter(
        el => !updatePolicy?.isOfficeSpecific || updatePolicy?.officeIds.some(id => id === el.value),
      ),
    [officesOptions, updatePolicy],
  );

  const financeProjectsValues = useFiltersListValue(financeProjectsOptions, [values.financeProjectId]);

  const invoiceNumberValues = useFiltersListValue(invoiceNumbersOptions, [values.invoiceId]);

  const officeValues = useFiltersListValue(filteredOfficesOptions, [values.officeId]);

  const subExpenseTypeValues = useFiltersListValue(expenseSubTypesOptions, [values.subExpenseTypeId]);

  const currencyValues = useFiltersListValue(currenciesOptions, [values.currencyId]);

  const handleOfficeChange = useCallback(({ value }: OptionTypeBase) => {
    setFieldValue('officeId', value);
  }, []);

  const handleExpenseSubTypesChange = useCallback(
    ({ value }: { value: string | SubExpenseType; label: string }) => {
      const subType = values.expenseType.subExpenseTypes.find(subType => subType.id === value);

      if (subType) {
        setFieldValue('subExpenseTypeId', value);
        setFieldValue('subExpenseType', subType);

        if (values.subExpenseType.isPersonal !== subType.isPersonal) {
          resetPayerRecipientValues();
        }

        if (subType.isPersonal) {
          setPayerRecipient(PayerRecipientGroupEnum.USERS);
          setCurrency();
        }
      } else {
        setFieldValue('subExpenseTypeId', '');
        setFieldValue('subExpenseType', { id: '', name: '', isPersonal: false });
      }
    },
    [values.subExpenseType, values.expenseType],
  );

  const handleExpenseIncomeTypesChange = useCallback(
    ({ value, type }: { value: string | ExpenseType; label: string; type: ExpenseIncomeTypesGroupEnum }) => {
      setFieldValue('subExpenseTypeId', '');
      setFieldValue('subExpenseType', { id: '', name: '', isPersonal: false });

      if (type === ExpenseIncomeTypesGroupEnum.EXPENSE_TYPES && !isString(value)) {
        setFieldValue('expenseTypeId', value.id);
        setFieldValue('expenseType', value);
        setFieldValue('incomeTypeId', '');
      } else {
        setFieldValue('incomeTypeId', value);
        setFieldValue('expenseTypeId', '');
        setFieldValue('expenseType', {
          name: '',
          id: '',
          subExpenseTypes: [
            {
              id: '',
              name: '',
              isPersonal: false,
            },
          ],
        });
      }
    },
    [values.expenseType],
  );

  const setCurrency = (currency?: CurrencyType) => {
    const value = currency ? currency : baseCurrency;
    if (value) {
      setFieldValue('currencyId', value.id);
      setFieldValue('currency', value);
    }
  };

  const resetPayerRecipientValues = () => {
    setFieldValue('clientPayerRecipientId', '');
    setFieldValue('supplierPayerRecipientId', '');
    setFieldValue('officePayerRecipientId', '');
    setFieldValue('userPayerRecipientId', '');
  };

  const handlePayerRecipientChange = useCallback(
    ({ value, type }: { value: string | Record<string, any>; label: string; type: PayerRecipientGroupEnum }) => {
      setPayerRecipient(type);
      switch (type) {
        case PayerRecipientGroupEnum.CLIENTS: {
          resetPayerRecipientValues();
          setFieldValue('clientPayerRecipientId', value);
          if (isString(value) && values.clientPayerRecipientId !== value) {
            setFieldValue('financeProjectId', '');
            getFinanceProjectsFilter({ clientIds: [value] });
          }

          break;
        }
        case PayerRecipientGroupEnum.SUPPLIERS: {
          if (value instanceof Object) {
            resetPayerRecipientValues();
            setFieldValue('financeProjectId', '');
            setFieldValue('supplierPayerRecipientId', value.id);
            setCurrency(value.currency);
          }
          break;
        }
        case PayerRecipientGroupEnum.OFFICES: {
          resetPayerRecipientValues();
          setFieldValue('financeProjectId', '');
          setFieldValue('officePayerRecipientId', value);
          setCurrency();
          break;
        }
        case PayerRecipientGroupEnum.USERS: {
          if (value instanceof Object) {
            resetPayerRecipientValues();
            setFieldValue('financeProjectId', '');
            setFieldValue('userPayerRecipientId', value.id);
          }
          break;
        }
      }
    },
    [values.clientPayerRecipientId, currenciesOptions],
  );

  const handleFinanceProjectsChange = useCallback(({ value }: FilterOptionType) => {
    setFieldValue('financeProjectId', value.id);
    getInvoiceNumbersFilter({
      projectIds: [value.id],
      isFromTransaction: true,
      dateFrom: '',
      dateTo: moment().format(DATE_FORMAT.YYYY_MM_DD),
    });
    const rate = getCurrentRateDate(value.rates);
    if (rate) {
      setCurrency(rate.currency);
    }
  }, []);

  const handleInvoiceNumbersChange = useCallback(({ value }: FilterOptionType) => {
    setFieldValue('invoiceId', value.id);
  }, []);

  const handleCurrenciesChange = useCallback(({ value }: FilterOptionType) => {
    setCurrency(value);
  }, []);

  const payerRecipientErrorMessage =
    errors?.officePayerRecipientId ||
    errors?.clientPayerRecipientId ||
    errors?.supplierPayerRecipientId ||
    errors?.userPayerRecipientId;

  const payerRecipientHasError =
    hasError('officePayerRecipientId') ||
    hasError('clientPayerRecipientId') ||
    hasError('supplierPayerRecipientId') ||
    hasError('userPayerRecipientId');

  const handleAmountChange = useCallback(
    (value: CurrencyInputOnChangeValues) => {
      const amount = value.value ? value : getDefaultCurrency();
      setFieldValue('amount', amount);
      const unifiedAmountValue = (Number(value.float) * Number(values.rate.float)).toFixed(2);
      setFieldValue(
        'unifiedAmount',
        new CurrencyFormatter({
          float: Number(unifiedAmountValue),
          value: unifiedAmountValue,
          formatted: unifiedAmountValue,
        }),
      );
    },
    [values.rate],
  );

  const handleRateChange = useCallback(
    (value: CurrencyInputOnChangeValues) => {
      const rate = value.value
        ? new CurrencyFormatter({
            ...value,
            float: value.float || 0,
          })
        : getDefaultCurrency();
      setFieldValue('rate', rate);
      const unifiedAmountValue = (Number(value.float) * Number(values.amount.float)).toFixed(2);
      setFieldValue(
        'unifiedAmount',
        new CurrencyFormatter({
          float: Number(unifiedAmountValue),
          value: unifiedAmountValue,
          formatted: unifiedAmountValue,
        }),
      );
    },
    [values.amount],
  );

  const handleUnifiedAmountChange = useCallback(
    (value: CurrencyInputOnChangeValues) => {
      const unifiedAmount = value.value
        ? new CurrencyFormatter({
            ...value,
            float: value.float || 0,
          })
        : getDefaultCurrency();
      setFieldValue('unifiedAmount', unifiedAmount);
    },
    [values.unifiedAmount],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages.newTransactionTitle)}
        classNameModal="center"
      >
        <form className="modal__form modal__transaction form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__inputs-subwrapper">
              <Input
                id="transactionDate"
                name="transactionDate"
                type="date"
                wrapperClass="form__input-date"
                defaultValue={values.transactionDate}
                label={intl.formatMessage(messages.dateLabel)}
                onChange={handleChange}
                hasError={hasError('transactionDate')}
                errorMessage={errors?.transactionDate}
              />
              <Select
                label={intl.formatMessage(messages.officeLabel)}
                options={filteredOfficesOptions}
                value={officeValues}
                errorMessage={errors?.officeId}
                hasError={hasError('officeId')}
                handleChange={handleOfficeChange}
              />
            </div>
            <Select
              isSearchable
              isGrouped
              label={intl.formatMessage(messages.expenseIncomeTypeLabel)}
              options={expenseIncomeTypesOptions}
              errorMessage={errors?.incomeTypeId || errors?.expenseTypeId}
              handleChange={handleExpenseIncomeTypesChange}
              hasError={hasError('incomeTypeId') || hasError('expenseTypeId')}
            />
            {values.expenseTypeId && values.expenseType ? (
              <Select
                label={intl.formatMessage(messages.expenseTypeLabel)}
                options={expenseSubTypesOptions}
                value={subExpenseTypeValues}
                errorMessage={errors?.subExpenseTypeId}
                handleChange={handleExpenseSubTypesChange}
                hasError={hasError('subExpenseTypeId')}
              />
            ) : null}
            <Select
              isSearchable
              isGrouped
              label={intl.formatMessage(messages.payerRecipientLabel)}
              options={payerRecipientOptions}
              value={payerRecipientValues || null}
              errorMessage={payerRecipientErrorMessage}
              handleChange={handlePayerRecipientChange}
              hasError={payerRecipientHasError}
            />
            <Select
              isSearchable
              label={intl.formatMessage(messages.projectLabel)}
              options={financeProjectsOptions}
              value={financeProjectsValues}
              isDisabled={payerRecipient !== PayerRecipientGroupEnum.CLIENTS || isFinanceProjectsFilterLoading}
              isLoading={isFinanceProjectsFilterLoading}
              handleChange={handleFinanceProjectsChange}
              errorMessage={errors?.financeProjectId}
              hasError={hasError('financeProjectId')}
            />
            {values.incomeTypeId && financeProjectsValues ? (
              <Select
                label={intl.formatMessage(messages.invoiceNumberLabel)}
                options={invoiceNumbersOptions}
                value={invoiceNumberValues}
                errorMessage={errors?.invoiceId}
                handleChange={handleInvoiceNumbersChange}
                hasError={hasError('invoiceId')}
              />
            ) : null}
            <div className="form__inputs-subwrapper">
              <Select
                options={currenciesOptions}
                externalClass="select__no-label currency-select"
                value={currencyValues}
                handleChange={handleCurrenciesChange}
                errorMessage={errors?.currencyId}
                hasError={hasError('currencyId')}
              />
              <CurrencyInput
                name="amount"
                label={intl.formatMessage(messages.originalAmountLabel)}
                value={values.amount.value}
                onChange={handleAmountChange}
                //@ts-ignore
                errorMessage={errors?.amount?.float || errors?.amount}
                hasError={hasError('amount')}
                allowNegativeValue={true}
              />
              <div className="form__currency-operations">x</div>
              <CurrencyInput
                name="rate"
                label={intl.formatMessage(messages.rateLabel)}
                value={values.rate.value}
                decimalsLimit={4}
                onChange={handleRateChange}
                wrapperClass="form__rate-input"
                //@ts-ignore
                errorMessage={errors?.rate?.float || errors?.rate}
                hasError={hasError('rate')}
              />
              <div className="form__currency-operations">=</div>
              <CurrencyInput
                name="unifiedAmount"
                label={intl.formatMessage(messages.unifiedAmountLabel)}
                suffix={baseCurrency?.id ? ` ${baseCurrency.name}` : undefined}
                value={values.unifiedAmount.value}
                onChange={handleUnifiedAmountChange}
                //@ts-ignore
                errorMessage={errors?.unifiedAmount?.float || errors?.unifiedAmount}
                hasError={hasError('unifiedAmount')}
                allowNegativeValue={true}
              />
            </div>
            <Input name="comment" onChange={handleChange} label={intl.formatMessage(messages.commentLabel)} />
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons">
            <Button
              color={'gray'}
              externalClass={'button--modal button--cancel'}
              onClick={onCloseRequest}
              type={'button'}
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ModalNewTransaction;
