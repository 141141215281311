import React from 'react';

import Icon from '../../../Icon';
import Switch from '../../../Switch';
import { DashboardBlockClass } from '../../../../enums/finance/finance.enum';

type ModalDraggableSettingsProps = {
  item: any;
  dragHandleProps: any;
  data: any;
  changeVisibility: (data: any) => void;
};

export const Item = ({ item, dragHandleProps, data, changeVisibility }: ModalDraggableSettingsProps) => {
  const index = data.findIndex((it: DashboardBlockClass) => it.id === item.id);

  const { onMouseDown, onTouchStart } = dragHandleProps;

  return (
    <div className="form__group-wrapper form__group-wrapper--with-gap dashboard-modal-draggable" key={index}>
      {data.length > 1 ? (
        <button
          className="dashboard-modal-draggable__button"
          type={'button'}
          onTouchStart={e => {
            e.preventDefault();
            onTouchStart(e);
          }}
          onMouseDown={e => {
            onMouseDown(e);
          }}
        >
          <Icon iconName={'drag-indicator'} externalClass={'dashboard-drag'} />
        </button>
      ) : null}
      <div className="form__inputs-subwrapper align-start dashboard-switch">
        <Switch onChange={() => changeVisibility(item)} checked={item.show} />
        <div className="switch__title">{item.name}</div>
      </div>
    </div>
  );
};
