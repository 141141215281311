import React, { useCallback, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DraggableList from 'react-draggable-list';

import { useFormik } from 'formik';

import { RejectValueErrors } from '../../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../../utils/hooks.utils';
import Button from '../../../Button';
import ErrorMessage from '../../../ErrorMessage';
import Modal from '../../../Modal';
import messages from '../../messages';
import { Item } from './ModalDashboardItem';
import { DashboardBlockClass } from '../../../../enums/finance/finance.enum';

type DashboardModalProps = {
  onCloseRequest: () => void;
  editSetting: (data: { params: any; callback: () => void }) => void;
  isOpen: boolean;
  data: any;
  loading: boolean;
  error: string | RejectValueErrors[] | null;
};

function DashboardModal({ onCloseRequest, editSetting, error, data, loading, isOpen }: DashboardModalProps) {
  const intl = useIntl();

  const containerRef = useRef(null);

  const onListChange = (newList: any[]) => {
    setFieldValue('types', newList);
  };

  const { values, handleSubmit, setFieldValue, setFieldError } = useFormik<{
    types: any[];
  }>({
    initialValues: {
      types: [],
    },
    onSubmit: values => {
      const params = values.types.map(
        (item, index) =>
          new DashboardBlockClass({
            ...item,
            positionNumber: index,
          }),
      );

      return editSetting({ params: { ...data, dashboardBlockSettings: params }, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  useEffect(() => {
    if (data) {
      setFieldValue(
        'types',
        data.dashboardBlockSettings.map((item: DashboardBlockClass) => ({
          ...item,
          name: intl.formatMessage(messages[item.blockType]),
        })),
      );
    }
  }, [data]);

  const changeVisibility = useCallback(
    (data: any) => {
      setFieldValue(
        'types',
        values.types.map(item => (item.id === data.id ? { ...item, show: !item.show } : item)),
      );
    },
    [values],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages.dashboard)}
        size="small"
        classNameModal={'dashboard-modal center'}
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            {values.types?.length ? (
              <div ref={containerRef} className="dashboard-draggable-container">
                <DraggableList
                  itemKey="id"
                  //@ts-ignore
                  template={temp =>
                    Item({
                      item: temp.item,
                      dragHandleProps: temp.dragHandleProps,
                      data: values.types,
                      changeVisibility,
                    })
                  }
                  list={values.types}
                  onMoveEnd={(newList: any) => onListChange(newList)}
                  container={() => containerRef.current}
                />
              </div>
            ) : null}
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons display-center">
            <Button
              color="gray"
              externalClass="button--modal button--cancel"
              onClick={onCloseRequest}
              type="button"
              block
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass="button--modal" type="submit" loading={loading} disabled={loading} block>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default DashboardModal;
