import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';
import { CurrencyType, ExpenseType, SubExpenseType } from '../../../types/finance';
import Button from '../../Button';
import Icon from '../../Icon';
import HierarchicalTable from '../../HierarchicalTable';
import { useTableData } from '../../../utils/hooks.utils';
import { EDashboardTitles } from '../../../pages/Dashboard';
import ModalEditPlannedExpense from '../Modal/PlannedExpense/PlannedExpenseModal';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { useDataForTable } from './useTableData';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { DashboardExpenses, DashboardPlannedExpense, PutExpenseDashboard } from '../../../enums/finance/finance.enum';

type PlannedExpenseTableProps = {
  title: EDashboardTitles;
  name?: string;
  tableData: DashboardPlannedExpense[];
  errors: string | null;
  isLoading: boolean;
  baseCurrency: CurrencyType | undefined;
  settingsData: any;
  types: any;
  settingsLoading: boolean;
  settingsError: string | null;
  getTypesList: (data: any) => void;
  getSettings: (data: EDashboardTitles) => void;
  putSettings: (data: PutExpenseDashboard) => void;
  onCloseSettings: () => void;
};

const PlannedExpenseTable = ({
  title,
  name,
  tableData,
  baseCurrency,
  errors,
  isLoading,
  settingsData,
  settingsError,
  settingsLoading,
  getTypesList,
  getSettings,
  putSettings,
  onCloseSettings,
}: PlannedExpenseTableProps) => {
  const [openSettingsModal, setOpenSettingModal] = useState(false);

  const getObject = (id: string, name: string, data: DashboardExpenses[]) => {
    return {
      name: name,
      id: id,
      actualAmount: data
        .map((it: DashboardExpenses) => Number(it.actualAmount || 0))
        .reduce((partialSum: number, a: number) => partialSum + a, 0),
      plannedAmount: data
        .map((it: DashboardExpenses) => Number(it.plannedAmount || 0))
        .reduce((partialSum: number, a: number) => partialSum + a, 0),
    };
  };

  const getOfficeExpense = (data: DashboardPlannedExpense[]) => {
    const offices = data
      .map((data: DashboardPlannedExpense) => data.expenseValues)
      .flat()
      .map((data: DashboardExpenses) => data.office)
      .reduce((item: OfficeInfo[], o: OfficeInfo) => {
        if (!item.some((obj: OfficeInfo) => obj.id === o.id)) {
          item.push(o);
        }

        return item;
      }, []);

    if (offices?.length) {
      const tableOffices = offices.map((office: OfficeInfo) => {
        const tempData = data.filter((item: DashboardPlannedExpense) =>
          item.expenseValues.some((it: DashboardExpenses) => it.officeId === office.id),
        );

        const filteredData = tempData.map((item: DashboardPlannedExpense) => ({
          ...item,
          expenseValues: item.expenseValues.filter((it: DashboardExpenses) => it.officeId === office.id),
        }));

        const types = filteredData
          ?.map((data: DashboardPlannedExpense) =>
            data.expenseType.subExpenseTypes.length === data.subExpenseTypeBlockSettings.length
              ? data.expenseType
              : data.subExpenseTypeBlockSettings[0].subExpenseType,
          )
          .reduce((item: any, o: ExpenseType | SubExpenseType) => {
            if (!item.some((obj: any) => obj.id === o.id)) {
              item.push(o);
            }

            return item;
          }, []);

        return {
          ...getObject(
            office.id,
            office.name,
            filteredData.map((data: DashboardPlannedExpense) => data.expenseValues).flat(),
          ),
          subTypes: types.map((type: ExpenseType | SubExpenseType) => {
            //@ts-ignore
            const isSubtype = !type.subExpenseTypes;

            if (isSubtype) {
              const subData = filteredData
                .map((data: DashboardPlannedExpense) => data.expenseValues)
                .flat()
                .filter((value: DashboardExpenses) => value.subExpenseTypeId === type.id && !!value.reportDate);

              return {
                ...getObject(type.id, type.name, subData),
                subTypes: subData.map((sub: DashboardExpenses, index: number) => ({
                  name: moment(sub.reportDate).format(DATE_FORMAT.MMM_YYYY),
                  id: index,
                  actualAmount: Number(sub.actualAmount),
                  plannedAmount: Number(sub.plannedAmount),
                })),
              };
            } else {
              const filtData = filteredData
                .filter((expense: DashboardPlannedExpense) => expense.expenseTypeId === type.id)
                .map((data: DashboardPlannedExpense) => data.expenseValues)
                .flat();

              const subs = filtData
                .map((data: DashboardExpenses) => data.subExpenseType)
                .reduce((item: SubExpenseType[], o: SubExpenseType) => {
                  if (!item.some((obj: any) => obj.id === o.id)) {
                    item.push(o);
                  }

                  return item;
                }, []);

              return {
                ...getObject(type.id, type.name, filtData),
                subTypes: subs.map((sub: SubExpenseType) => {
                  const subData = filtData.filter(
                    (value: DashboardExpenses) => value.subExpenseTypeId === sub.id && !!value.reportDate,
                  );

                  return {
                    ...getObject(sub.id, sub.name, subData),
                    subTypes: subData.map((sub: DashboardExpenses, index: number) => ({
                      name: moment(sub.reportDate).format(DATE_FORMAT.MMM_YYYY),
                      id: index,
                      actualAmount: Number(sub.actualAmount),
                      plannedAmount: Number(sub.plannedAmount),
                    })),
                  };
                }),
              };
            }
          }),
        };
      });

      const total = {
        totalItem: true,
        actualAmount: data
          .map((data: DashboardPlannedExpense) => data.expenseValues)
          .flat()
          .map((it: DashboardExpenses) => Number(it.actualAmount || 0))
          .reduce((partialSum: number, a: number) => partialSum + a, 0),
        plannedAmount: data
          .map((data: DashboardPlannedExpense) => data.expenseValues)
          .flat()
          .map((it: DashboardExpenses) => Number(it.plannedAmount || 0))
          .reduce((partialSum: number, a: number) => partialSum + a, 0),
      };

      return [...tableOffices, total];
    }
  };

  const getFavoriteExpense = (data: DashboardPlannedExpense[]) => {
    const types = data
      ?.map((data: DashboardPlannedExpense) =>
        data.expenseType.subExpenseTypes.length === data.subExpenseTypeBlockSettings.length
          ? data.expenseType
          : data.subExpenseTypeBlockSettings[0].subExpenseType,
      )
      .reduce((item: any, o: ExpenseType | SubExpenseType) => {
        if (!item.some((obj: ExpenseType | SubExpenseType) => obj.id === o.id)) {
          item.push(o);
        }

        return item;
      }, []);

    if (types?.length) {
      const tableTypes = types.map((type: ExpenseType | SubExpenseType) => {
        //@ts-ignore
        const isSubtype = !type.subExpenseTypes;

        if (isSubtype) {
          const subData = data
            .map((data: DashboardPlannedExpense) => data.expenseValues)
            .flat()
            .filter((value: DashboardExpenses) => value.subExpenseTypeId === type.id);

          const offices = subData
            .map((data: DashboardExpenses) => data.office)
            .reduce((item: OfficeInfo[], o: OfficeInfo) => {
              if (!item.some((obj: OfficeInfo) => obj.id === o.id)) {
                item.push(o);
              }

              return item;
            }, []);

          return {
            ...getObject(type.id, type.name, subData),
            subTypes: offices.map((office: OfficeInfo) => {
              const filteredData = subData.filter(
                (it: DashboardExpenses) => it.officeId === office.id && !!it.reportDate,
              );

              return {
                ...getObject(office.id, office.name, filteredData),
                subTypes: filteredData.map((filt: DashboardExpenses, index: number) => ({
                  id: index,
                  name: moment(filt.reportDate).format(DATE_FORMAT.MMM_YYYY),
                  actualAmount: Number(filt.actualAmount),
                  plannedAmount: Number(filt.plannedAmount),
                })),
              };
            }),
          };
        } else {
          const filtData = data
            .filter((expense: DashboardPlannedExpense) => expense.expenseTypeId === type.id)
            .map((data: DashboardPlannedExpense) => data.expenseValues)
            .flat();

          const subs = filtData
            .map((data: DashboardExpenses) => data.subExpenseType)
            .reduce((item: SubExpenseType[], o: SubExpenseType) => {
              if (!item.some((obj: SubExpenseType) => obj.id === o.id)) {
                item.push(o);
              }

              return item;
            }, []);

          return {
            ...getObject(type.id, type.name, filtData),
            subTypes: subs.map((sub: SubExpenseType) => {
              const subData = filtData.filter(
                (value: DashboardExpenses) => value.subExpenseTypeId === sub.id && !!value.reportDate,
              );

              const offices = subData
                .map((data: DashboardExpenses) => data.office)
                .reduce((item: OfficeInfo[], o: OfficeInfo) => {
                  if (!item.some((obj: OfficeInfo) => obj.id === o.id)) {
                    item.push(o);
                  }

                  return item;
                }, []);

              return {
                ...getObject(sub.id, sub.name, subData),
                subTypes: offices.map((office: OfficeInfo) => {
                  const officeData = subData.filter((value: DashboardExpenses) => value.officeId === office.id);

                  return {
                    ...getObject(office.id, office.name, officeData),
                    subTypes: officeData.map((filt: DashboardExpenses, index: number) => ({
                      id: index,
                      name: moment(filt.reportDate).format(DATE_FORMAT.MMM_YYYY),
                      actualAmount: Number(filt.actualAmount),
                      plannedAmount: Number(filt.plannedAmount),
                    })),
                  };
                }),
              };
            }),
          };
        }
      });

      tableTypes?.push({
        totalItem: true,
        actualAmount: data
          .map((data: DashboardPlannedExpense) => data.expenseValues)
          .flat()
          .map((it: DashboardExpenses) => Number(it.actualAmount || 0))
          .reduce((partialSum: number, a: number) => partialSum + a, 0),
        plannedAmount: data
          .map((data: DashboardPlannedExpense) => data.expenseValues)
          .flat()
          .map((it: DashboardExpenses) => Number(it.plannedAmount || 0))
          .reduce((partialSum: number, a: number) => partialSum + a, 0),
      });

      return tableTypes;
    }
  };

  const convertedData = useMemo(() => {
    if (tableData && tableData.length) {
      if (title === EDashboardTitles.PLANNED_EXPENSES_BY_OFFICE) {
        return getOfficeExpense(tableData);
      } else {
        return getFavoriteExpense(tableData);
      }
    }
    return [];
  }, [tableData]);

  const openSetting = () => {
    getSettings(title);
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    onCloseSettings();
    setOpenSettingModal(false);
  };

  const editSetting = (params: PutExpenseDashboard) => {
    putSettings(params);
  };

  const { tableColumns } = useDataForTable(
    convertedData,
    baseCurrency,
    title === EDashboardTitles.PLANNED_EXPENSES_BY_OFFICE ? 'officeColumn' : 'expenseColumn',
  );

  return (
    <div className="dashboard-table__block">
      <div className="dashboard-table__block-item">
        <h2 className="dashboard-table__block-title">{name ?? <FormattedMessage {...messages[title]} />}</h2>
        <Button color="gray" externalClass="cash-flow__report-button" onClick={openSetting}>
          <Icon iconName="pencil" externalClass="button__icon" />
        </Button>
      </div>
      <div className="dashboard-wrapper">
        <HierarchicalTable
          tableData={useTableData(convertedData, ['subTypes'])}
          tableColumns={tableColumns}
          loading={isLoading}
          error={errors}
        />
      </div>
      {openSettingsModal && (
        <ModalEditPlannedExpense
          isOpen={openSettingsModal}
          getTypesList={getTypesList}
          type={title}
          onCloseRequest={closeSetting}
          editSetting={editSetting}
          loading={settingsLoading}
          error={settingsError}
          data={settingsData ? settingsData[title] : null}
        />
      )}
    </div>
  );
};

export default PlannedExpenseTable;
