import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';

import moment from 'moment';

import * as filtersActions from '../../actions/filters.actions';
import * as financeActions from '../../actions/finance.actions';
import InlineDatePicker from '../../components/InlineDatePicker';
import { DATE_FORMAT } from '../../constants/date.constants';
import {
  DashboardBlockClass,
  OfficeBalanceClass,
  PutDashboard,
  PutExpenseDashboard,
} from '../../enums/finance/finance.enum';
import { getBaseCurrency } from '../../utils';
import messages from './messages';
import DashboardTableBlock from '../../components/Dashboard/Table/DashboardTable';
import { OfficesBalanceParams } from '../../enums/params/finance.params';
import PlannedExpenseTable from '../../components/Dashboard/Table/PlannedExpense';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import DashboardModal from '../../components/Dashboard/Modal/DashboardSettings/DashboardModal';
import DashboardPieChart from '../../components/Dashboard/Table/DashboardPieChart';
import DashboardBarChart from '../../components/Dashboard/Table/DashboardBar';
import PoliciesContext from '../../PoliciesContext';
import { VIEW_DASHBOARD } from '../../constants/policies.constants';

export enum EDashboardTitles {
  REVENUE_AND_EXPENSE = 'REVENUE_AND_EXPENSE',
  PLANNED_EXPENSES_BY_OFFICE = 'PLANNED_EXPENSES_BY_OFFICE',
  FAVORITE_PLANNED_EXPENSE = 'FAVORITE_PLANNED_EXPENSE',
  PLANNED_AND_ACTUAL_REVENUE = 'PLANNED_AND_ACTUAL_REVENUE',
  CUSTOMER_INVOICE = 'CUSTOMER_INVOICE',
  REVENUE_BY_CUSTOMER = 'REVENUE_BY_CUSTOMER',
  EXPENSES_STRUCTURE = 'EXPENSES_STRUCTURE',
}

export enum EDashboardSettings {
  REVENUE_AND_EXPENSE = 'revenueAndExpenses',
  PLANNED_EXPENSES_BY_OFFICE = 'plannedExpensesByOffices',
  FAVORITE_PLANNED_EXPENSE = 'favoritePlannedExpenses',
  PLANNED_AND_ACTUAL_REVENUE = 'plannedAndActualRevenues',
  CUSTOMER_INVOICE = 'customerInvoices',
  REVENUE_BY_CUSTOMER = 'revenueByCustomers',
  EXPENSES_STRUCTURE = 'expenseStructures',
}

export enum EDashboardSettingNames {
  REVENUE_AND_EXPENSE = 'revenueAndExpenseBlockName',
  PLANNED_EXPENSES_BY_OFFICE = 'plannedExpensesByOfficeBlockName',
  FAVORITE_PLANNED_EXPENSE = 'favoritePlannedExpenseBlockName',
  PLANNED_AND_ACTUAL_REVENUE = 'plannedAndActualRevenueBlockName',
  CUSTOMER_INVOICE = 'customerInvoiceBlockName',
  REVENUE_BY_CUSTOMER = 'revenueByCustomerBlockName',
  EXPENSES_STRUCTURE = 'expenseStructureBlockName',
}

function Dashboard({
  tableData,
  isLoading,
  errors,
  params,
  finance,

  setDashboardParams,
  getOfficesFilter,
  getCurrenciesFilter,
  getClientList,
  getExpenseTypesList,
  getIncomeTypesList,

  getRevenueAndExpense,
  getDashboardPlannedExpenses,
  getDashboardClientSetting,
  getDashboardSetting,

  putRevenueAndExpense,
  putDashboardPlannedExpenses,
  putDashboardClientSetting,
  putDashboardSetting,

  resetState,
  resetErrors,
  currencies,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();
  const policies = useContext(PoliciesContext);

  const viewPolicy = useMemo(() => policies.find(policy => policy.policy.name === VIEW_DASHBOARD), [policies]);

  const [openSettingsModal, setOpenSettingModal] = useState(false);

  useEffect(() => {
    setDashboardParams({});
  }, [viewPolicy]);

  useEffect(() => {
    getOfficesFilter();
    getCurrenciesFilter();

    getDashboardSetting();

    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    if (finance.dashboardSettingData) {
      finance.dashboardSettingData.dashboardBlockSettings.map((item: DashboardBlockClass) => {
        if (
          [EDashboardTitles.PLANNED_EXPENSES_BY_OFFICE, EDashboardTitles.FAVORITE_PLANNED_EXPENSE].includes(
            item.blockType,
          )
        ) {
          getDashboardPlannedExpenses(item.blockType);
        } else if (
          [
            EDashboardTitles.PLANNED_AND_ACTUAL_REVENUE,
            EDashboardTitles.CUSTOMER_INVOICE,
            EDashboardTitles.REVENUE_BY_CUSTOMER,
          ].includes(item.blockType)
        ) {
          getDashboardClientSetting(item.blockType);
        } else {
          getRevenueAndExpense();
        }
      });
    }
  }, [finance.dashboardSettingData]);

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  const handleDataChange = useCallback((start: string, end: string) => {
    setDashboardParams({
      dateFrom: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      dateTo: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
  }, []);

  const months = useMemo(() => {
    const startDate = moment(params.dateFrom);
    const endDate = moment(params.dateTo);

    const diff = endDate.diff(startDate, 'months', true);
    const range = [];

    for (let i = 0; i < diff; i++) {
      range.push(moment(params.dateFrom).add(i, 'month').format('YYYY-MM-DD'));
    }

    return range;
  }, [params.dateFrom, params.dateTo]);

  const putClientSettings = (data: PutDashboard, blockType: EDashboardTitles) => {
    if (blockType === EDashboardTitles.REVENUE_AND_EXPENSE) {
      const revenue = {
        name: data.params.name,
        blockType,
        incomeTypeBlockSettings: data.params.types.map((item: any, index: number) => ({
          incomeTypeId: item.value,
          positionNumber: index,
        })),
      };

      putRevenueAndExpense({ ...data, params: revenue });
    } else {
      const client = {
        name: data.params.name,
        blockType,
        clientBlockSettings: data.params.types.map((item: any, index: number) => ({
          clientId: item.value,
          positionNumber: index,
        })),
      };

      putDashboardClientSetting({ ...data, params: client });
    }
  };

  const openSetting = () => {
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    setOpenSettingModal(false);
  };

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <div className="pagination page__panel-top__control__pagination">
                <InlineDatePicker
                  onDateChange={handleDataChange}
                  defaultPeriodStart={params.dateFrom}
                  defaultPeriodEnd={params.dateTo}
                  todayBtn={false}
                  weekBtn={false}
                />
                <Button color="gray" externalClass="cash-flow__report-button" onClick={openSetting}>
                  <Icon iconName="pencil" externalClass="button__icon" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content dashboard-page">
        <div className="page__wrapper dashboard-table">
          {finance.dashboardSettingData
            ? finance.dashboardSettingData.dashboardBlockSettings
                .filter((setting: DashboardBlockClass) => !!setting.show)
                .map((setting: DashboardBlockClass) =>
                  [EDashboardTitles.PLANNED_AND_ACTUAL_REVENUE, EDashboardTitles.CUSTOMER_INVOICE].includes(
                    setting.blockType,
                  ) ? (
                    <DashboardTableBlock
                      title={setting.blockType}
                      name={tableData[EDashboardSettingNames[setting.blockType]]}
                      tableData={tableData?.[EDashboardSettings[setting.blockType]]}
                      typeLabel={intl.formatMessage(messages.clientLabel)}
                      typesList={finance.clientsListData}
                      baseCurrency={baseCurrency}
                      isLoading={finance.loading.getDashboard}
                      errors={errors}
                      settingsData={finance.dashboardClientSettingData}
                      settingsLoading={finance.loading.getDashboardClientSetting}
                      settingsError={finance.errors.dashboardClientSettingError}
                      getTypeList={getClientList}
                      putSettingsData={data => putClientSettings(data, setting.blockType)}
                      onCloseSettings={resetErrors}
                    />
                  ) : [EDashboardTitles.PLANNED_EXPENSES_BY_OFFICE, EDashboardTitles.FAVORITE_PLANNED_EXPENSE].includes(
                      setting.blockType,
                    ) ? (
                    <PlannedExpenseTable
                      key={setting.blockType}
                      title={setting.blockType}
                      name={tableData[EDashboardSettingNames[setting.blockType]]}
                      tableData={tableData[EDashboardSettings[setting.blockType]]}
                      errors={errors}
                      types={finance.expenseTypesListData}
                      settingsData={finance.dashboardPlannedExpensesData}
                      settingsLoading={finance.loading.getDashboardPlannedExpenses}
                      settingsError={finance.errors.dashboardPlannedExpensesError}
                      isLoading={isLoading.getDashboard}
                      baseCurrency={baseCurrency}
                      getSettings={getDashboardPlannedExpenses}
                      putSettings={putDashboardPlannedExpenses}
                      getTypesList={getExpenseTypesList}
                      onCloseSettings={resetErrors}
                    />
                  ) : [EDashboardTitles.EXPENSES_STRUCTURE, EDashboardTitles.REVENUE_BY_CUSTOMER].includes(
                      setting.blockType,
                    ) ? (
                    <DashboardPieChart
                      title={setting.blockType}
                      name={tableData[EDashboardSettingNames[setting.blockType]]}
                      data={tableData[EDashboardSettings[setting.blockType]]}
                      settingsData={finance.dashboardClientSettingData}
                      settingsLoading={finance.loading.getDashboardClientSetting}
                      settingsError={finance.errors.dashboardClientSettingError}
                      putSettingsData={data => putClientSettings(data, setting.blockType)}
                      typeLabel={intl.formatMessage(messages.clientLabel)}
                      typesList={finance.clientsListData}
                      baseCurrency={baseCurrency}
                      errors={errors}
                      getTypeList={getClientList}
                      onCloseSettings={resetErrors}
                    />
                  ) : (
                    <DashboardBarChart
                      data={tableData?.revenueAndExpenses}
                      months={months}
                      title={EDashboardTitles.REVENUE_AND_EXPENSE}
                      name={tableData[EDashboardSettingNames.REVENUE_AND_EXPENSE]}
                      typeLabel={intl.formatMessage(messages.incomeTypeLabel)}
                      typesList={finance.incomeTypesListData}
                      settingsData={finance.revenueAndExpenseData}
                      settingsLoading={finance.loading.getRevenueAndExpense}
                      settingsError={finance.errors.revenueAndExpenseError}
                      getTypeList={getIncomeTypesList}
                      errors={errors}
                      onCloseSettings={resetErrors}
                      putSettingsData={data => putClientSettings(data, setting.blockType)}
                    />
                  ),
                )
            : null}
        </div>
      </div>
      {openSettingsModal && (
        <DashboardModal
          isOpen={openSettingsModal}
          onCloseRequest={closeSetting}
          editSetting={putDashboardSetting}
          loading={finance.loading.getDashboardSetting}
          error={finance.errors.dashboardSettingError}
          data={finance.dashboardSettingData}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ filters, auth, finance }: RootState) => ({
  finance: finance,
  tableData: finance.dashboardListData,
  officesFilter: filters.officesFilter,
  isLoading: finance.loading,
  errors: finance.errors.dashboardError,
  params: finance.dashboardParams,
  authUserId: auth.currentUserInfo.id,
  currencies: filters.currenciesFilter.currencies,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getCurrenciesFilter: () => dispatch(filtersActions.getCurrenciesFilter()),

  setDashboardParams: (data: Partial<OfficesBalanceParams>) => dispatch(financeActions.setDashboardParams(data)),
  editOfficeBalance: (data: { data: OfficeBalanceClass; callback: () => void }) =>
    dispatch(financeActions.editOfficeBalance(data)),

  getIncomeTypesList: () => dispatch(financeActions.getIncomeTypesList()),
  getExpenseTypesList: (data: any) => dispatch(financeActions.getExpenseTypesFullList(data)),
  getProjectsList: () => dispatch(financeActions.getProjectsList()),
  getClientList: () => dispatch(financeActions.getClientsList()),

  getRevenueAndExpense: () => dispatch(financeActions.getRevenueAndExpense()),
  getDashboardPlannedExpenses: (data: string) => dispatch(financeActions.getDashboardPlannedExpenses(data)),
  getDashboardClientSetting: (data: string) => dispatch(financeActions.getDashboardClientSetting(data)),
  getDashboardSetting: () => dispatch(financeActions.getDashboardSetting()),

  putRevenueAndExpense: (data: PutExpenseDashboard) => dispatch(financeActions.putRevenueAndExpense(data)),
  putDashboardPlannedExpenses: (data: PutExpenseDashboard) =>
    dispatch(financeActions.putDashboardPlannedExpenses(data)),
  putDashboardClientSetting: (data: PutExpenseDashboard) => dispatch(financeActions.putDashboardClientSetting(data)),
  putDashboardSetting: (data: any) => dispatch(financeActions.putDashboardSetting(data)),

  resetErrors: () => dispatch(financeActions.resetErrors()),
  resetState: () => dispatch(financeActions.resetState()),
  resetSettings: () => dispatch(financeActions.resetCashFlowReportSetting()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Dashboard);
