import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatValue } from 'react-currency-input-field';

import messages from '../messages';
import { CurrencyType } from '../../../types/finance';
import { getTableCell } from '../../../utils/table.utils';
import { DepthLevels } from '../../../constants/tables.constants';

export const useDataForTable = (
  tableData: any,
  baseCurrency?: CurrencyType,
  title?: 'expenseColumn' | 'officeColumn',
) => {
  const intl = useIntl();

  const tableColumns = useMemo(() => {
    if (tableData?.length) {
      const typesColumns = {
        id: 'name',
        headClassName: 'dashboard-table__max-scope-for-three',
        Header: intl.formatMessage(messages[title ?? 'clientColumn']),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: row => (row.totalItem ? <FormattedMessage {...messages.totalRow} /> : row.name),
            },
            {
              depth: DepthLevels.SECOND,
              content: row => row.name,
            },
            {
              depth: DepthLevels.THIRD,
              content: row => row.name,
            },
            {
              depth: DepthLevels.FOURTH,
              content: row => row.name,
            },
          ]),
      };

      const plannedColumns = {
        id: 'planned',
        headClassName: 'dashboard-table__max-scope-for-three-item',
        Header: intl.formatMessage(messages.plannedAmount),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: row => (
                <span>
                  {row?.plannedAmount ? (
                    <>
                      {formatValue({
                        value: row?.plannedAmount.toFixed(2).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: row => (
                <span>
                  {row?.plannedAmount ? (
                    <>
                      {formatValue({
                        value: row?.plannedAmount.toFixed(2).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
            {
              depth: DepthLevels.THIRD,
              content: row => (
                <span>
                  {row?.plannedAmount ? (
                    <>
                      {formatValue({
                        value: row?.plannedAmount.toFixed(2).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
            {
              depth: DepthLevels.FOURTH,
              content: row => (
                <span>
                  {row?.plannedAmount ? (
                    <>
                      {formatValue({
                        value: row?.plannedAmount.toFixed(2).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
          ]),
      };

      const paidColumns = {
        id: 'paid',
        headClassName: 'dashboard-table__max-scope-for-three-item',
        Header: intl.formatMessage(messages.paidAmount),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: row => (
                <span>
                  {row?.actualAmount ? (
                    <>
                      {formatValue({
                        value: row?.actualAmount.toFixed(2).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: row => (
                <span>
                  {row?.actualAmount ? (
                    <>
                      {formatValue({
                        value: row?.actualAmount.toFixed(2).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
            {
              depth: DepthLevels.THIRD,
              content: row => (
                <span>
                  {row?.actualAmount ? (
                    <>
                      {formatValue({
                        value: row?.actualAmount.toFixed(2).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
            {
              depth: DepthLevels.FOURTH,
              content: row => (
                <span>
                  {row?.actualAmount ? (
                    <>
                      {formatValue({
                        value: row?.actualAmount.toFixed(2).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
          ]),
      };

      return [typesColumns, plannedColumns, paidColumns];
    }
    return [];
  }, [tableData]);

  return {
    tableColumns,
  };
};
