export const GET_USERS_FILTER_REQUEST = 'GET_USERS_FILTER_REQUEST';
export const GET_USERS_FILTER_SUCCESS = 'GET_USERS_FILTER_SUCCESS';
export const GET_USERS_FILTER_FAILURE = 'GET_USERS_FILTER_FAILURE';
export const SET_USERS_FILTER_VALUE = 'SET_USERS_FILTER_VALUE';
export const SET_USERS_FILTER_PARAMS = 'SET_USERS_FILTER_PARAMS';
export const RESET_USERS_FILTER_PARAMS = 'RESET_USERS_FILTER_PARAMS';

export const GET_OFFICES_FILTER_REQUEST = 'GET_OFFICES_FILTER_REQUEST';
export const GET_OFFICES_FILTER_SUCCESS = 'GET_OFFICES_FILTER_SUCCESS';
export const GET_OFFICES_FILTER_FAILURE = 'GET_OFFICES_FILTER_FAILURE';
export const SET_OFFICES_FILTER_VALUE = 'SET_OFFICES_FILTER_VALUE';

export const GET_DEPARTMENTS_FILTER_REQUEST = 'GET_DEPARTMENTS_FILTER_REQUEST';
export const GET_DEPARTMENTS_FILTER_SUCCESS = 'GET_DEPARTMENTS_FILTER_SUCCESS';
export const GET_DEPARTMENTS_FILTER_FAILURE = 'GET_DEPARTMENTS_FILTER_FAILURE';
export const SET_DEPARTMENTS_FILTER_VALUE = 'SET_DEPARTMENTS_FILTER_VALUE';

export const GET_PROJECT_GROUPS_FILTER_REQUEST = 'GET_PROJECT_GROUPS_FILTER_REQUEST';
export const GET_PROJECT_GROUPS_FILTER_SUCCESS = 'GET_PROJECT_GROUPS_FILTER_SUCCESS';
export const GET_PROJECT_GROUPS_FILTER_FAILURE = 'GET_PROJECT_GROUPS_FILTER_FAILURE';
export const SET_PROJECT_GROUPS_FILTER_VALUE = 'SET_PROJECT_GROUPS_FILTER_VALUE';

export const GET_COMPETENCIES_FILTER_REQUEST = 'GET_COMPETENCIES_FILTER_REQUEST';
export const GET_COMPETENCIES_FILTER_SUCCESS = 'GET_COMPETENCIES_FILTER_SUCCESS';
export const GET_COMPETENCIES_FILTER_FAILURE = 'GET_COMPETENCIES_FILTER_FAILURE';
export const SET_COMPETENCIES_FILTER_VALUE = 'SET_COMPETENCIES_FILTER_VALUE';

export const GET_EVENT_TYPES_FILTER_REQUEST = 'GET_EVENT_TYPES_FILTER_REQUEST';
export const GET_EVENT_TYPES_FILTER_SUCCESS = 'GET_EVENT_TYPES_FILTER_SUCCESS';
export const GET_EVENT_TYPES_FILTER_FAILURE = 'GET_EVENT_TYPES_FILTER_FAILURE';
export const SET_EVENT_TYPES_FILTER_VALUE = 'SET_EVENT_TYPES_FILTER_VALUE';

export const GET_POLLS_FILTER_REQUEST = 'GET_POLLS_FILTER_REQUEST';
export const GET_POLLS_FILTER_SUCCESS = 'GET_POLLS_FILTER_SUCCESS';
export const GET_POLLS_FILTER_FAILURE = 'GET_POLLS_FILTER_FAILURE';

export const GET_POLL_TEMPLATES_FILTER_REQUEST = 'GET_POLL_TEMPLATES_FILTER_REQUEST';
export const GET_POLL_TEMPLATES_FILTER_SUCCESS = 'GET_POLL_TEMPLATES_FILTER_SUCCESS';
export const GET_POLL_TEMPLATES_FILTER_FAILURE = 'GET_POLL_TEMPLATES_FILTER_FAILURE';

export const GET_POLL_TEMPLATES_CATEGORY_FILTER_REQUEST = 'GET_POLL_TEMPLATES_CATEGORY_FILTER_REQUEST';
export const GET_POLL_TEMPLATES_CATEGORY_FILTER_SUCCESS = 'GET_POLL_TEMPLATES_CATEGORY_FILTER_SUCCESS';
export const GET_POLL_TEMPLATES_CATEGORY_FILTER_FAILURE = 'GET_POLL_TEMPLATES_FILTER_FAILURE';

export const GET_EVENTS_FILTER_REQUEST = 'GET_EVENTS_FILTER_REQUEST';
export const GET_EVENTS_FILTER_SUCCESS = 'GET_EVENTS_FILTER_SUCCESS';
export const GET_EVENTS_FILTER_FAILURE = 'GET_EVENTS_FILTER_FAILURE';

export const GET_RECOGNITION_TYPES_FILTER_REQUEST = 'GET_RECOGNITION_TYPES_FILTER_REQUEST';
export const GET_RECOGNITION_TYPES_FILTER_SUCCESS = 'GET_RECOGNITION_TYPES_FILTER_SUCCESS';
export const GET_RECOGNITION_TYPES_FILTER_FAILURE = 'GET_RECOGNITION_TYPES_FILTER_FAILURE';

export const GET_COMPANY_POSITIONS_FILTER_REQUEST = 'GET_COMPANY_POSITIONS_FILTER_REQUEST';
export const GET_COMPANY_POSITIONS_FILTER_SUCCESS = 'GET_COMPANY_POSITIONS_FILTER_SUCCESS';
export const GET_COMPANY_POSITIONS_FILTER_FAILURE = 'GET_COMPANY_POSITIONS_FILTER_FAILURE';

export const GET_LANGUAGE_LEVELS_FILTER_REQUEST = 'GET_LANGUAGE_LEVELS_FILTER_REQUEST';
export const GET_LANGUAGE_LEVELS_FILTER_SUCCESS = 'GET_LANGUAGE_LEVELS_FILTER_SUCCESS';
export const GET_LANGUAGE_LEVELS_FILTER_FAILURE = 'GET_LANGUAGE_LEVELS_FILTER_FAILURE';

export const GET_FOREIGN_LANGUAGES_FILTER_REQUEST = 'GET_FOREIGN_LANGUAGES_FILTER_REQUEST';
export const GET_FOREIGN_LANGUAGES_FILTER_SUCCESS = 'GET_FOREIGN_LANGUAGES_FILTER_SUCCESS';
export const GET_FOREIGN_LANGUAGES_FILTER_FAILURE = 'GET_FOREIGN_LANGUAGES_FILTER_FAILURE';

export const GET_TECHNICAL_SKILLS_FILTER_REQUEST = 'GET_TECHNICAL_SKILLS_FILTER_REQUEST';
export const GET_TECHNICAL_SKILLS_FILTER_SUCCESS = 'GET_TECHNICAL_SKILLS_FILTER_SUCCESS';
export const GET_TECHNICAL_SKILLS_FILTER_FAILURE = 'GET_TECHNICAL_SKILLS_FILTER_FAILURE';

export const GET_PROJECTS_FILTER_REQUEST = 'GET_PROJECTS_FILTER_REQUEST';
export const GET_PROJECTS_FILTER_SUCCESS = 'GET_PROJECTS_FILTER_SUCCESS';
export const GET_PROJECTS_FILTER_FAILURE = 'GET_PROJECTS_FILTER_FAILURE';

export const GET_SPECIALIZATIONS_FILTER_REQUEST = 'GET_SPECIALIZATIONS_FILTER_REQUEST';
export const GET_SPECIALIZATIONS_FILTER_SUCCESS = 'GET_SPECIALIZATIONS_FILTER_SUCCESS';
export const GET_SPECIALIZATIONS_FILTER_FAILURE = 'GET_SPECIALIZATIONS_FILTER_FAILURE';

export const GET_ASSESSMENTS_FILTER_REQUEST = 'GET_ASSESSMENTS_FILTER_REQUEST';
export const GET_ASSESSMENTS_FILTER_SUCCESS = 'GET_ASSESSMENTS_FILTER_SUCCESS';
export const GET_ASSESSMENTS_FILTER_FAILURE = 'GET_ASSESSMENTS_FILTER_FAILURE';

export const GET_COMPETENCIES_TYPES_FILTER_REQUEST = 'GET_COMPETENCIES_TYPES_FILTER_REQUEST';
export const GET_COMPETENCIES_TYPES_FILTER_SUCCESS = 'GET_COMPETENCIES_TYPES_FILTER_SUCCESS';
export const GET_COMPETENCIES_TYPES_FILTER_FAILURE = 'GET_COMPETENCIES_TYPES_FILTER_FAILURE';

export const GET_GRADES_FILTER_REQUEST = 'GET_GRADES_FILTER_REQUEST';
export const GET_GRADES_FILTER_SUCCESS = 'GET_GRADES_FILTER_SUCCESS';
export const GET_GRADES_FILTER_FAILURE = 'GET_GRADES_FILTER_FAILURE';

export const GET_EMPLOYEE_GROUPS_FILTER_REQUEST = 'GET_EMPLOYEE_GROUPS_FILTER_REQUEST';
export const GET_EMPLOYEE_GROUPS_FILTER_SUCCESS = 'GET_EMPLOYEE_GROUPS_FILTER_SUCCESS';
export const GET_EMPLOYEE_GROUPS_FILTER_FAILURE = 'GET_EMPLOYEE_GROUPS_FILTER_FAILURE';

export const GET_CLIENTS_FILTER_REQUEST = 'GET_CLIENTS_FILTER_REQUEST';
export const GET_CLIENTS_FILTER_SUCCESS = 'GET_CLIENTS_FILTER_SUCCESS';
export const GET_CLIENTS_FILTER_FAILURE = 'GET_CLIENTS_FILTER_FAILURE';

export const GET_CURRENCIES_FILTER_REQUEST = 'GET_CURRENCIES_FILTER_REQUEST';
export const GET_CURRENCIES_FILTER_SUCCESS = 'GET_CURRENCIES_FILTER_SUCCESS';
export const GET_CURRENCIES_FILTER_FAILURE = 'GET_CURRENCIES_FILTER_FAILURE';

export const GET_PROJECT_TYPES_FILTER_REQUEST = 'GET_PROJECT_TYPES_FILTER_REQUEST';
export const GET_PROJECT_TYPES_FILTER_SUCCESS = 'GET_PROJECT_TYPES_FILTER_SUCCESS';
export const GET_PROJECT_TYPES_FILTER_FAILURE = 'GET_PROJECT_TYPES_FILTER_FAILURE';

export const GET_SKILL_LEVELS_FILTER_REQUEST = 'GET_SKILL_LEVELS_FILTER_REQUEST';
export const GET_SKILL_LEVELS_FILTER_SUCCESS = 'GET_SKILL_LEVELS_FILTER_SUCCESS';
export const GET_SKILL_LEVELS_FILTER_FAILURE = 'GET_SKILL_LEVELS_FILTER_FAILURE';

export const GET_EXPENSE_TYPES_FILTER_REQUEST = 'GET_EXPENSE_TYPES_FILTER_REQUEST';
export const GET_EXPENSE_TYPES_FILTER_SUCCESS = 'GET_EXPENSE_TYPES_FILTER_SUCCESS';
export const GET_EXPENSE_TYPES_FILTER_FAILURE = 'GET_EXPENSE_TYPES_FILTER_FAILURE';

export const GET_INCOME_TYPES_FILTER_REQUEST = 'GET_INCOME_TYPES_FILTER_REQUEST';
export const GET_INCOME_TYPES_FILTER_SUCCESS = 'GET_INCOME_TYPES_FILTER_SUCCESS';
export const GET_INCOME_TYPES_FILTER_FAILURE = 'GET_INCOME_TYPES_FILTER_FAILURE';

export const GET_SUPPLIERS_FILTER_REQUEST = 'GET_SUPPLIERS_FILTER_REQUEST';
export const GET_SUPPLIERS_FILTER_SUCCESS = 'GET_SUPPLIERS_FILTER_SUCCESS';
export const GET_SUPPLIERS_FILTER_FAILURE = 'GET_SUPPLIERS_FILTER_FAILURE';

export const GET_FINANCE_PROJECTS_FILTER_REQUEST = 'GET_FINANCE_PROJECTS_FILTER_REQUEST';
export const GET_FINANCE_PROJECTS_FILTER_SUCCESS = 'GET_FINANCE_PROJECTS_FILTER_SUCCESS';
export const GET_FINANCE_PROJECTS_FILTER_FAILURE = 'GET_FINANCE_PROJECTS_FILTER_FAILURE';

export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';

export const GET_CANDIDATE_STATUSES_FILTER_REQUEST = 'GET_CANDIDATE_STATUSES_FILTER_REQUEST';
export const GET_CANDIDATE_STATUSES_FILTER_SUCCESS = 'GET_CANDIDATE_STATUSES_FILTER_SUCCESS';
export const GET_CANDIDATE_STATUSES_FILTER_FAILURE = 'GET_CANDIDATE_STATUSES_FILTER_FAILURE';

export const GET_CANDIDATE_SPECIALIZATIONS_FILTER_REQUEST = 'GET_CANDIDATE_SPECIALIZATIONS_FILTER_REQUEST';
export const GET_CANDIDATE_SPECIALIZATIONS_FILTER_SUCCESS = 'GET_CANDIDATE_SPECIALIZATIONS_FILTER_SUCCESS';
export const GET_CANDIDATE_SPECIALIZATIONS_FILTER_FAILURE = 'GET_CANDIDATE_SPECIALIZATIONS_FILTER_FAILURE';

export const GET_CANDIDATE_TECHNOLOGIES_FILTER_REQUEST = 'GET_CANDIDATE_TECHNOLOGIES_FILTER_REQUEST';
export const GET_CANDIDATE_TECHNOLOGIES_FILTER_SUCCESS = 'GET_CANDIDATE_TECHNOLOGIES_FILTER_SUCCESS';
export const GET_CANDIDATE_TECHNOLOGIES_FILTER_FAILURE = 'GET_CANDIDATE_TECHNOLOGIES_FILTER_FAILURE';

export const GET_CANDIDATE_RECEIVING_SOURCES_FILTER_REQUEST = 'GET_CANDIDATE_RECEIVING_SOURCES_FILTER_REQUEST';
export const GET_CANDIDATE_RECEIVING_SOURCES_FILTER_SUCCESS = 'GET_CANDIDATE_RECEIVING_SOURCES_FILTER_SUCCESS';
export const GET_CANDIDATE_RECEIVING_SOURCES_FILTER_FAILURE = 'GET_CANDIDATE_RECEIVING_SOURCES_FILTER_FAILURE';

export const GET_HR_CURATORS_FILTER_REQUEST = 'GET_HR_CURATORS_FILTER_REQUEST';
export const GET_HR_CURATORS_FILTER_SUCCESS = 'GET_HR_CURATORS_FILTER_SUCCESS';
export const GET_HR_CURATORS_FILTER_FAILURE = 'GET_HR_CURATORS_FILTER_FAILURE';

export const GET_JIRA_PROJECTS_FILTER_REQUEST = 'GET_JIRA_PROJECTS_FILTER_REQUEST';
export const GET_JIRA_PROJECTS_FILTER_SUCCESS = 'GET_JIRA_PROJECTS_FILTER_SUCCESS';
export const GET_JIRA_PROJECTS_FILTER_FAILURE = 'GET_JIRA_PROJECTS_FILTER_FAILURE';

export const SET_JIRA_PROJECTS_FILTER_VALUE = 'SET_JIRA_PROJECTS_FILTER_VALUE';

export const RESET_EVENTS_FILTER = 'RESET_EVENTS_FILTER';

export const RESET_ASSESSMENTS_FILTER = 'RESET_ASSESSMENTS_FILTER';

export const RESET_FILTERS_STATE = 'RESET_FILTERS_STATE';

export enum FilterParamsName {
  OFFICES = 'offices',
  OFFICE_IDS = 'officeIds',
  DEPARTMENTS = 'departments',
  DEPARTMENT_IDS = 'departmentIds',
  TARGET_EMPLOYEES = 'targetEmployees',
  USER_IDS = 'userIds',
  USERS = 'users',
  EMPLOYEE_IDS = 'employeeIds',
  EMPLOYEES = 'employees',
  PORTAL_USER_IDS = 'portalUserIds',
  EVENT_TYPES = 'eventTypes',
  PROJECT_GROUP_IDS = 'projectGroupIds',
  COMPETENCE_IDS = 'competenceIds',
  POSITIONS_IDS = 'positionIds',
  COMPETENCIES = 'competencies',
  JIRA_PROJECTS = 'jiraProjects',
  JIRA_PROJECT_IDS = 'jiraProjectIds',
}

export const SET_SAVED_FILTERS_LIST_PARAMS = 'SET_SAVED_FILTERS_LIST_PARAMS';

export const GET_SAVED_FILTERS_LIST_SUCCESS = 'GET_SAVED_FILTERS_LIST_SUCCESS';
export const GET_SAVED_FILTERS_LIST_FAILURE = 'GET_SAVED_FILTERS_LIST_FAILURE';

export const CREATE_SAVED_FILTER_REQUEST = 'CREATE_SAVED_FILTER_REQUEST';
export const CREATE_SAVED_FILTER_SUCCESS = 'CREATE_SAVED_FILTER_SUCCESS';
export const CREATE_SAVED_FILTER_FAILURE = 'CREATE_SAVED_FILTER_FAILURE';

export const EDIT_SAVED_FILTER_REQUEST = 'EDIT_SAVED_FILTER_REQUEST';
export const EDIT_SAVED_FILTER_SUCCESS = 'EDIT_SAVED_FILTER_SUCCESS';
export const EDIT_SAVED_FILTER_FAILURE = 'EDIT_SAVED_FILTER_FAILURE';

export const DELETE_SAVED_FILTER_REQUEST = 'DELETE_SAVED_FILTER_REQUEST';
export const DELETE_SAVED_FILTER_SUCCESS = 'DELETE_SAVED_FILTER_SUCCESS';
export const DELETE_SAVED_FILTER_FAILURE = 'DELETE_SAVED_FILTER_FAILURE';

export enum FilterTypes {
  USER_FILTER = 'USER_FILTER',
  EVENT_FILTER = 'EVENT_FILTER',
  ACTIVITY_FILTER = 'ACTIVITY_FILTER',
  PROJECTS_REPORT_FILTER = 'PROJECTS_REPORT_FILTER',
  MEMBERS_REPORT_FILTER = 'MEMBERS_REPORT_FILTER',
  MEMBERS_TIMESHEET_REPORT_FILTER = 'MEMBERS_TIMESHEET_REPORT_FILTER',
  MEMBERS_ISSUE_TYPES_REPORT_FILTER = 'MEMBERS_ISSUE_TYPES_REPORT_FILTER',
  ACTIVE_INACTIVE_HOURS_REPORT_FILTER = 'ACTIVE_INACTIVE_HOURS_REPORT_FILTER',
  USERS_HOURS_ABSENCE_REPORT_FILTER = 'USERS_HOURS_ABSENCE_REPORT_FILTER',
  ABSENCE_PERIODS_REPORT_FILTER = 'ABSENCE_PERIODS_REPORT_FILTER',
  POLLS_FILTER = 'POLLS_FILTER',
  POLL_TEMPLATES_FILTER = 'POLL_TEMPLATES_FILTER',
  RECOGNITIONS_FILTER = 'RECOGNITIONS_FILTER',
  DAYS_USED_LIMIT_FILTER = 'DAYS_USED_LIMIT_FILTER',
  RESOURCES_FILTER = 'RESOURCES_FILTER',
  USER_HOURS_DETAILS_FILTER = 'USER_HOURS_DETAILS_FILTER',
  PROJECT_HOURS_DETAILS_FILTER = 'PROJECT_HOURS_DETAILS_FILTER',
  PROJECT_GROUPS_FILTER = 'PROJECT_GROUPS_FILTER',
  ACTIVE_MEMBERS_FILTER = 'ACTIVE_MEMBERS_FILTER',
  CANDIDATES_FILTER = 'CANDIDATES_FILTER',
  RESUMES_FILTER = 'RESUMES_FILTER',
  ASSESSMENTS_FILTER = 'ASSESSMENTS_FILTER',
  INCOMES_EXPENSES_REPORT_FILTER = 'INCOMES_EXPENSES_REPORT_FILTER',
  TRANSACTIONS_FILTER = 'TRANSACTIONS_FILTER',
  SALARIES_FILTER = 'SALARIES_FILTER',
  PLANNING_FILTER = 'PLANNING_FILTER',
  PERMISSIONS_FILTER = 'PERMISSIONS_FILTER',
  PLANNED_EXPENSES_FILTER = 'PLANNED_EXPENSES_FILTER',
  OFFICES_BALANCE = 'OFFICES_BALANCE',
  INVOICE_FILTER = 'INVOICE_FILTER',
}

export const RESET_SAVED_FILTER_ERRORS = 'RESET_SAVED_FILTER_ERRORS';
