import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DraggableList from 'react-draggable-list';

import { useFormik } from 'formik';
import get from 'lodash-es/get';

import { RejectValueErrors } from '../../../../enums/error.enum';
import {
  CASH_FLOW_REPORT_SCHEMA,
  CashFlowSettingFormValues,
  DashboardExpenseType,
  PutDashboardSetting,
  PutExpenseDashboard,
} from '../../../../enums/finance/finance.enum';
import { useSetFieldsErrors } from '../../../../utils/hooks.utils';
import Button from '../../../Button';
import ErrorMessage from '../../../ErrorMessage';
import Modal from '../../../Modal';
import messages from '../../messages';
import Icon from '../../../Icon';
import Input from '../../../Input';
import { Item } from './ModalPlannedExpenseItem';
import Checkbox from '../../../Checkbox';
import FinancialExpenses from './PlannedExpenseTypes';
import { EDashboardTitles } from '../../../../pages/Dashboard';
import { ExpenseType, SubExpenseType } from '../../../../types/finance';

type ModalEditPlannedExpenseProps = {
  getTypesList: (data: any) => void;
  onCloseRequest: () => void;
  editSetting: (data: PutExpenseDashboard) => void;
  isOpen: boolean;
  data: PutDashboardSetting;
  type: EDashboardTitles;
  loading: boolean;
  error: string | RejectValueErrors[] | null;
};

function ModalEditPlannedExpense({
  onCloseRequest,
  editSetting,
  error,
  data,
  type,
  loading,
  isOpen,
  getTypesList,
}: ModalEditPlannedExpenseProps) {
  const intl = useIntl();

  const containerRef = useRef(null);

  const onListChange = (newList: any) => {
    setFieldValue('expenseTypes', newList);
  };

  const [openTypesModal, setOpenTypesModal] = useState(false);
  const [typesList, setTypesList] = useState<any[]>([]);

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError } = useFormik({
    initialValues: new CashFlowSettingFormValues({
      name: '',
      showEmptyRecords: false,
      expenseTypes: [],
    }),
    validateOnChange: false,
    validationSchema: CASH_FLOW_REPORT_SCHEMA,
    onSubmit: values => {
      const data = {
        name: values.name,
        showEmptyRecords: values.showEmptyRecords,
        blockType: type,
        expenseTypeBlockSettings: values.expenseTypes.map((item, index) => {
          const isSubType = !item.subTypes;

          return isSubType
            ? {
                expenseTypeId: item.expenseType.id,
                positionNumber: index,
                subExpenseTypeBlockSettings: [
                  {
                    subExpenseTypeId: item.id,
                    positionNumber: index,
                  },
                ],
              }
            : {
                expenseTypeId: item.id,
                positionNumber: index,
                subExpenseTypeBlockSettings: item.subTypes.map((sub: string) => ({
                  subExpenseTypeId: sub,
                  positionNumber: index,
                })),
              };
        }),
      };

      return editSetting({ params: data, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  useEffect(() => {
    getTypesList({
      callback: (results: any) => {
        setTypesList(
          results.content.map((item: ExpenseType) => ({
            id: item.id,
            name: item.name + (item.isDeleted ? ' (deleted)' : ''),
            subExpenseTypes: item.subExpenseTypes.map((sub: SubExpenseType) => ({
              id: sub.id,
              name: sub.name + (sub.isDeleted ? ' (deleted)' : ''),
            })),
          })),
        );
      },
    });
  }, []);

  useEffect(() => {
    if (data && typesList.length) {
      setFieldValue('name', data.name);
      setFieldValue('showEmptyRecords', data.showEmptyRecords);
      setFieldValue(
        'expenseTypes',
        data.expenseTypeBlockSettings?.map((type: DashboardExpenseType, index: number) => {
          const selected = typesList.find((item: ExpenseType) => item.id === type.expenseTypeId);

          if (selected && selected.subExpenseTypes?.length === type.subExpenseTypeBlockSettings.length) {
            return {
              id: type.expenseTypeId,
              name: type.expenseType?.name,
              subTypes: type.subExpenseTypeBlockSettings.map((item: any) => item.subExpenseTypeId),
              position: index,
            };
          } else
            return {
              id: type.subExpenseTypeBlockSettings[0].subExpenseTypeId,
              name: type.subExpenseTypeBlockSettings[0].subExpenseType?.name,
              expenseType: selected,
              position: index,
            };
        }),
      );
    }
  }, [data, typesList]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const submitTypes = (types: any[]) => {
    setFieldValue(
      'expenseTypes',
      types.map((it, index) => ({
        ...it,
        position: index,
      })),
    );
    setOpenTypesModal(false);
  };

  const removeType = useCallback(
    index => {
      values.expenseTypes.splice(index, 1);
      setFieldValue('expenseTypes', values.expenseTypes);
    },
    [values],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages[type])}
        size="small"
        classNameModal={'cash-flow-report-modal center'}
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block">
              <Input
                id={'name'}
                name={'name'}
                label={intl.formatMessage(messages.nameColumn)}
                defaultValue={values?.name || ''}
                onChange={handleChange}
                hasError={hasError('name')}
                errorMessage={errors?.name}
              />
            </div>

            <div className="form__input-block">
              <Checkbox
                externalClass="form__checkbox-capitalazie-label"
                onChange={handleChange}
                id="showEmptyRecords"
                name="showEmptyRecords"
                label={intl.formatMessage(messages.showEmptyRecords)}
                checked={values.showEmptyRecords}
                checkedValue={values.showEmptyRecords}
              />
            </div>

            {values.expenseTypes?.length && typesList.length ? (
              <div ref={containerRef} className="cash-flow-draggable-container">
                <DraggableList
                  itemKey="position"
                  // @ts-ignore
                  template={temp =>
                    Item({
                      item: temp.item,
                      dragHandleProps: temp.dragHandleProps,
                      data: values.expenseTypes,
                      removeType,
                    })
                  }
                  list={values.expenseTypes}
                  onMoveEnd={(newList: any) => onListChange(newList)}
                  container={() => containerRef.current}
                />
              </div>
            ) : null}

            <button className="form__btn-add-group" onClick={() => setOpenTypesModal(true)} type={'button'}>
              <Icon iconName={'pencil'} externalClass={'form__icon-btn-add'} />
              <FormattedMessage {...messages.editButton} />
            </button>
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons display-center">
            <Button
              color="gray"
              externalClass="button--modal button--cancel"
              onClick={onCloseRequest}
              type="button"
              block
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass="button--modal" type="submit" loading={loading} disabled={loading} block>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
      {openTypesModal && (
        <FinancialExpenses
          isOpen={openTypesModal}
          onCloseRequest={() => setOpenTypesModal(false)}
          editSetting={submitTypes}
          types={typesList}
          checked={values.expenseTypes}
        />
      )}
    </>
  );
}

export default ModalEditPlannedExpense;
