import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  removeButton,
  deleteButton,
  addButton,
  deleteModalWithItemNameTitle,
  clientLabel,
  rateLabel,
  projectLabel,
  memberLabel,
  membersLabel,
  projectsLabel,
  hourPriceLabel,
  confirmButton,
  choseFileButton,
  noFileChosenButton,
} from '../../i18n/defaultMessage';

export default defineMessages({
  deleteButton,
  cancelButton,
  addButton,
  saveButton,
  removeButton,
  choseFileButton,
  noFileChosenButton,
  deleteModalWithItemNameTitle,
  memberLabel,
  rateLabel,
  clientLabel,
  projectLabel,
  projectsLabel,
  membersLabel,
  hourPriceLabel,
  confirmButton,
  invoiceFilePdfButton: {
    id: 'finance.invoices.upload.invoice.file.pdf.button',
    defaultMessage: 'Invoice File (*.pdf)',
  },
  clientsLabel: {
    id: 'finance.planning.filter.clients',
    defaultMessage: 'Clients',
  },
  newInvoiceTitle: {
    id: 'finance.invoices.modal.new.invoice.title.create.invoice',
    defaultMessage: 'Create Invoice',
  },
  editInvoiceTitle: {
    id: 'finance.invoices.modal.edit.invoice.label.edit.invoice',
    defaultMessage: 'Edit Invoice',
  },
  deleteInvoiceTitle: {
    id: 'finance.invoices.modal.edit.invoice.label.delete.invoice',
    defaultMessage: 'Delete Invoice',
  },
  cloneInvoiceTitle: {
    id: 'finance.invoices.modal.edit.invoice.label.clone.invoice',
    defaultMessage: 'Clone Invoice',
  },
  uploadInvoiceFileTitle: {
    id: 'finance.invoices.modal.edit.invoice.label.upload.invoice.file',
    defaultMessage: 'Upload Invoice File',
  },
  invoiceAmount: {
    id: 'finance.invoices.modal.edit.invoice.label.invoice.amount',
    defaultMessage: 'Invoice Amount',
  },
  preparationDate: {
    id: 'finance.invoices.modal.edit.invoice.label.preparation.date',
    defaultMessage: 'Preparation Date',
  },
  preparationTime: {
    id: 'finance.invoices.modal.edit.invoice.label.preparation.time',
    defaultMessage: 'Preparation Time',
  },
  invoicingPeriod: {
    id: 'finance.invoices.modal.edit.invoice.label.invoicing.period',
    defaultMessage: 'Invoicing Period',
  },
  groupByMembers: {
    id: 'finance.invoices.modal.edit.invoice.label.group.members',
    defaultMessage: 'Group by members',
  },
  sendNotification: {
    id: 'finance.invoices.modal.edit.invoice.label.send.notification',
    defaultMessage: 'Send notification',
  },
  attachedFile: {
    id: 'finance.invoices.modal.edit.invoice.label.attached.file',
    defaultMessage: 'Attached file with tasks and hours ',
  },
  recipientsLabel: {
    id: 'finance.invoices.modal.edit.invoice.label.recipients',
    defaultMessage: 'Recipients',
  },
  repeatLabel: {
    id: 'finance.invoices.modal.edit.invoice.label.repeat',
    defaultMessage: 'Repeat',
  },
  doesNotRepeat: {
    id: 'finance.invoices.modal.edit.invoice.repeat.none',
    defaultMessage: 'Does not repeat',
  },
  weeklyOn: {
    id: 'finance.invoices.modal.edit.invoice.repeat.weekly',
    defaultMessage: 'Weekly on {day}',
  },
  everyTwoWeeksOn: {
    id: 'finance.invoices.modal.edit.invoice.repeat.biweekly',
    defaultMessage: 'Every 2 weeks on {day}',
  },
  everyThreeWeeksOn: {
    id: 'finance.invoices.modal.edit.invoice.repeat.triweekly',
    defaultMessage: 'Every 3 weeks on {day}',
  },
  everyFourWeeksOn: {
    id: 'finance.invoices.modal.edit.invoice.repeat.fourweekly',
    defaultMessage: 'Every 4 weeks on {day}',
  },
  monthlyOnDay: {
    id: 'finance.invoices.modal.edit.invoice.repeat.monthly.day',
    defaultMessage: 'Monthly on {day} day of month',
  },
  monthlyOnLastDay: {
    id: 'finance.invoices.modal.edit.invoice.repeat.monthly.lastDay',
    defaultMessage: 'Monthly on last day of month',
  },
  ends: {
    id: 'finance.invoices.modal.edit.invoice.label.ends',
    defaultMessage: 'Ends',
  },
  endsOn: {
    id: 'finance.invoices.modal.edit.invoice.repeat.endOn',
    defaultMessage: 'On',
  },
  datePlaceholder: {
    id: 'finance.invoices.modal.edit.invoice.date.place.holder',
    defaultMessage: 'Select a date',
  },
  endsAfter: {
    id: 'finance.invoices.modal.edit.invoice.repeat.endAfter',
    defaultMessage: 'After',
  },
  occurrence: {
    id: 'finance.invoices.modal.edit.invoice.occurrences',
    defaultMessage: 'occurrence',
  },
  sendingDate: {
    id: 'finance.invoices.modal.edit.invoice.label.sending.date',
    defaultMessage: 'Sending Date',
  },
  invoiceNumberGeneration: {
    id: 'finance.invoices.modal.edit.invoice.label.invoice.number.generation',
    defaultMessage: 'Invoice Number Generation',
  },
  incrementStep: {
    id: 'finance.invoices.modal.edit.invoice.label.invoice.increment.step',
    defaultMessage: 'Increment step',
  },
  closingDate: {
    id: 'finance.invoices.modal.edit.invoice.label.closing.date',
    defaultMessage: 'Closing Date',
  },
  invoiceNumber: {
    id: 'finance.invoices.modal.edit.invoice.label.invoice.number',
    defaultMessage: 'Invoice Number',
  },
  autoAmount: {
    id: 'finance.invoices.table.auto.amount',
    defaultMessage: 'Auto Amount',
  },
  jiraHours: {
    id: 'finance.invoices.table.jira.hours',
    defaultMessage: 'Jira Hours (h)',
  },
  deleteFutureInvoices: {
    id: 'finance.invoices.table.delete.future.invoices',
    defaultMessage: 'Also Delete all future invoices',
  },
});
