import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Modal from '../../../Modal';
import messages from '../../messages';
import Icon from '../../../Icon';
import Checkbox from '../../../Checkbox';
import Button from '../../../Button';
import Search from '../../../Search';
import { SubExpenseType } from '../../../../types/finance';
import classNames from 'classnames';

type ModalEditFinancialExpenseProps = {
  onCloseRequest: () => void;
  editSetting: (data: any) => void;
  isOpen: boolean;
  types: any[];
  checked: any[];
};

function FinancialExpenses({ onCloseRequest, editSetting, types, checked, isOpen }: ModalEditFinancialExpenseProps) {
  const intl = useIntl();

  const [checkedTypes, setCheckedTypes] = useState<any[]>([]);
  const [openedTypes, setOpenedTypes] = useState<string[]>([]);
  const [filteredTypes, setFilteredTypes] = useState(types);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setFilteredTypes(getFilterTypes(types, search));
  }, [search, types]);

  useEffect(() => {
    if (types.length && checked.length) {
      const typeIds = checked.map(check => check.expenseType?.id || check.id);
      const checkTypes = types.filter(type => typeIds.includes(type.id));
      const data = checkTypes.map(type => {
        const checkedTyps = checked.filter(it => (it.expenseType?.id || it.id) === type.id);
        return {
          id: type.id,
          name: type.name,
          subTypes: checkedTyps.map(item => item.subTypes || item.id).flat(),
        };
      });

      setCheckedTypes(data);
    }
  }, [checked, types]);

  const changeType = useCallback(
    (type: any) => {
      if (checkedTypes.find((item: any) => item.id === type.id))
        setCheckedTypes((prev: any) => prev.filter((it: any) => it.id !== type.id));
      else
        setCheckedTypes((prev: any) => [
          ...prev,
          { id: type.id, name: type.name, subTypes: type.subExpenseTypes.map((sub: SubExpenseType) => sub.id) },
        ]);
    },
    [checkedTypes],
  );

  const changeSubType = useCallback(
    (type: any, subType: string) => {
      const selected = checkedTypes.find((item: any) => item.id === type.id);

      if (selected) {
        const changed = selected.subTypes.includes(subType)
          ? { ...selected, subTypes: selected.subTypes.filter((it: string) => it !== subType) }
          : { ...selected, subTypes: [...selected.subTypes, subType] };

        if (changed.subTypes.length) {
          setCheckedTypes((prev: any) => [...prev.filter((it: any) => it.id !== type.id), changed]);
        } else {
          setCheckedTypes((prev: any) => [...prev.filter((it: any) => it.id !== type.id)]);
        }
      } else setCheckedTypes(prev => [...prev, { id: type.id, name: type.name, subTypes: [subType] }]);
    },
    [checkedTypes],
  );

  const checkEverySubTypes = useCallback(
    type => {
      if (checkedTypes.length) {
        const checkedType = checkedTypes.find((item: any) => item.id === type.id);
        const selectedType = types.find(item => item.id === type.id);

        if (checkedType && checkedType.subTypes.length === selectedType.subExpenseTypes.length) {
          return true;
        }
      }

      return false;
    },
    [checkedTypes],
  );

  const getFilterTypes = useCallback(
    (list: any[], searchValue: string) =>
      searchValue
        ? list
            .map(group => {
              if (group.name.toLowerCase().includes(searchValue.toLowerCase().trim())) {
                return group;
              } else {
                const filteredSections = group.subExpenseTypes
                  .map((section: any) => {
                    if (section?.name.toLowerCase().includes(searchValue.toLowerCase())) {
                      return section;
                    }
                  })
                  .filter((item: any) => item);

                if (filteredSections.length) {
                  if (!openedTypes.includes(group.id)) setOpenedTypes(prev => [...prev, group.id]);
                  return { ...group, subExpenseTypes: filteredSections };
                }
              }
            })
            .filter((item: any) => item)
        : list,
    [openedTypes],
  );

  const submitEdit = useCallback(() => {
    const settings = [
      ...checked.filter(item => checkedTypes.map(it => it.id).includes(item.id)),
      ...checkedTypes.filter(item => !checked.map(it => it.id).includes(item.id)),
    ];

    editSetting(settings);
  }, [checkedTypes]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.expenseColumn)}
      size="small"
      classNameModal={'cash-flow-report-modal center'}
    >
      <div className="form__inputs-wrapper">
        <Search
          externalClass="cash-flow-search"
          externalWrapperClass="cash-flow-search-container"
          placeholder="Search"
          defaultValue={search}
          onChange={e => setSearch(e.target.value)}
          handleClearBtn={() => setSearch('')}
        />
        <div className="cash-flow-expenses-container">
          {filteredTypes.map((type: any) => {
            const selectedType = checkedTypes.find((item: any) => item.id === type.id);

            return (
              <>
                <div
                  className="cash-flow-expenses"
                  onClick={() =>
                    setOpenedTypes(prev =>
                      prev.includes(type.id) ? prev.filter(it => it !== type.id) : [...prev, type.id],
                    )
                  }
                >
                  <Icon
                    iconName={openedTypes.includes(type.id) ? 'chevron-down' : 'chevron-right'}
                    externalClass="cash-flow-placeholder"
                  />
                  <Checkbox
                    onChange={() => changeType(type)}
                    id={type.id}
                    externalClass={classNames({ 'clear-all-checkbox': !checkEverySubTypes(type) }, 'checkbox-no-label')}
                    iconName={checkEverySubTypes(type) ? undefined : 'clear-all-checkbox'}
                    label={type.name}
                    checked={!!selectedType}
                    checkedValue={!!selectedType}
                  />
                </div>
                {openedTypes.includes(type.id)
                  ? type.subExpenseTypes.map((subType: any, index: number) => (
                      <div className="cash-flow-subexpenses" key={index}>
                        <Checkbox
                          externalClass="form__checkbox-capitalazie-label"
                          onChange={() => changeSubType(type, subType.id)}
                          id={subType.id}
                          label={subType.name}
                          checked={selectedType ? selectedType.subTypes.includes(subType.id) : false}
                          checkedValue={selectedType ? selectedType.subTypes.includes(subType.id) : false}
                        />
                      </div>
                    ))
                  : null}
              </>
            );
          })}
        </div>
      </div>
      <div className="form__buttons display-center cash-flow-expenses-buttons">
        <Button color="gray" externalClass="button--modal button--cancel" onClick={onCloseRequest} type="button" block>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button externalClass="button--modal" type="submit" disabled={!checkedTypes.length} block onClick={submitEdit}>
          <FormattedMessage {...messages.addButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default FinancialExpenses;
