import React, { useCallback, useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatValue } from 'react-currency-input-field';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import classNames from 'classnames';
import moment from 'moment';

import messages from '../messages';
import { CurrencyType } from '../../../types/finance';
import {
  CashFlowMonthType,
  CashFlowReportItem,
  CashFlowReportType,
  CashFlowSubExpenseType,
} from '../../../enums/finance/finance.enum';
import { getTableCell } from '../../../utils/table.utils';
import { DepthLevels } from '../../../constants/tables.constants';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { TransactionsParams } from '../../../enums/params/finance.params';
import r from './../../../constants/routes.constants';
import { useFiltersValue } from '../../../utils/hooks.utils';
import { FilterParamsName } from '../../../constants/filters.constants';
import { setTransactionsParams } from '../../../actions/finance.actions';
import { CashFlowReportParams } from '../../../enums/params/cashflow.params';
import PoliciesContext from '../../../PoliciesContext';
import { VIEW_TRANSACTION } from '../../../constants/policies.constants';

export const useDataForTable = (
  tableData: any,
  baseCurrency: CurrencyType | undefined,
  title: 'expenseColumn' | 'incomeColumn',
  params: CashFlowReportParams,
) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const policies = useContext(PoliciesContext);

  const viewPolicy = useMemo(() => policies.find(policy => policy.policy.name === VIEW_TRANSACTION), [policies]);

  const clickOnCell = useCallback(
    (row, monthIndex, depth) => {
      const data = tableData[row.typeIndex];

      const type = data.typeId;
      const subType = depth > 1 ? data.subTypes[row.subIndex].id : row.id;

      const startMonth = monthIndex !== null ? data.months[monthIndex].month : params.dateFrom;
      const endMonth =
        monthIndex !== null ? moment(startMonth).endOf('month').format(DATE_FORMAT.YYYY_MM_DD) : params.dateTo;

      dispatch(
        setTransactionsParams(
          new TransactionsParams({
            dateFrom: startMonth,
            dateTo: endMonth,
            [title === 'expenseColumn' ? 'expenseTypeIds' : 'incomeTypeIds']: [type],
            ...(depth > 0
              ? {
                  [title === 'expenseColumn' ? 'subExpenseTypeIds' : row.payerType[0]]: [subType],
                  officeIds: depth > 1 ? [row.officeId] : [],
                }
              : {}),
          }),
        ),
      );

      useFiltersValue(dispatch, FilterParamsName.OFFICE_IDS, [row.officeId]);

      history.push(r.transactions);
    },
    [tableData],
  );

  const tableColumns = useMemo(() => {
    if (tableData?.length) {
      const typesColumns = [
        {
          id: 'name',
          Header: intl.formatMessage(messages[title]),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: CashFlowReportItem) =>
                  row.totalItem ? <FormattedMessage {...messages.totalRow} /> : row.typeName,
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: CashFlowSubExpenseType) => row.name,
              },
              {
                depth: DepthLevels.THIRD,
                content: (row: CashFlowReportType) => row.office.name,
              },
            ]),
        },
      ];

      const monthColumns = tableData[0].months?.map((data: CashFlowMonthType, index: number) => ({
        id: `month-${index}`,
        Header: (
          <div className="day-display">
            {moment(data.month).format(DATE_FORMAT.MMM)}
            {(index === 0 || moment(data.month).startOf('year').isSame(data.month)) && (
              <div className={classNames('year-display')}>{moment(data.month).format(DATE_FORMAT.YYYY)}</div>
            )}
          </div>
        ),
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: CashFlowReportItem, _, depth: number) =>
                row?.months[index]?.value ? (
                  !row.totalItem ? (
                    <div
                      className={classNames('cash-flow-report-table', { 'cash-flow-report-link': viewPolicy })}
                      onClick={e => {
                        e.stopPropagation();
                        clickOnCell(row, index, depth);
                      }}
                    >
                      {formatValue({
                        value: (row?.months[index]?.value).toFixed(2).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </div>
                  ) : (
                    <span>
                      {formatValue({
                        value: (row?.months[index]?.value).toFixed(2).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </span>
                  )
                ) : (
                  '-'
                ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: CashFlowSubExpenseType, _, depth: number) =>
                row?.months[index]?.value ? (
                  <div
                    className={classNames('cash-flow-report-table', { 'cash-flow-report-link': viewPolicy })}
                    onClick={e => {
                      e.stopPropagation();
                      clickOnCell(row, index, depth);
                    }}
                  >
                    {formatValue({
                      value: (row?.months[index]?.value).toFixed(2).toString(),
                    })}
                    &nbsp;
                    {baseCurrency?.name}
                  </div>
                ) : (
                  '-'
                ),
            },
            {
              depth: DepthLevels.THIRD,
              content: (row: CashFlowReportType, _, depth: number) => (
                <button
                  className={classNames('cash-flow-report-table', { 'cash-flow-report-button': viewPolicy })}
                  onClick={() => clickOnCell(row, index, depth)}
                >
                  {row?.months[index]?.value ? (
                    <>
                      {formatValue({
                        value: (row?.months[index].value).toFixed(2).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </button>
              ),
            },
          ]);
        },
      }));

      const totalColumn = [
        {
          id: `total`,
          Header: intl.formatMessage(messages.totalRow),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: CashFlowReportItem, _, depth: number) => (
                  <div
                    className={classNames('cash-flow-report-table', { 'cash-flow-report-link': viewPolicy })}
                    onClick={e => {
                      e.stopPropagation();
                      clickOnCell(row, null, depth);
                    }}
                  >
                    {formatValue({
                      value: row.total?.toFixed(2).toString(),
                    })}
                    &nbsp;
                    {baseCurrency?.name}
                  </div>
                ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: CashFlowSubExpenseType, _, depth: number) => (
                  <div
                    className={classNames('cash-flow-report-table', { 'cash-flow-report-link': viewPolicy })}
                    onClick={e => {
                      e.stopPropagation();
                      clickOnCell(row, null, depth);
                    }}
                  >
                    {formatValue({
                      value: row.total?.toFixed(2).toString(),
                    })}
                    &nbsp;
                    {baseCurrency?.name}
                  </div>
                ),
              },
              {
                depth: DepthLevels.THIRD,
                content: (row: CashFlowReportType, _, depth: number) => (
                  <div
                    className={classNames('cash-flow-report-table', { 'cash-flow-report-link': viewPolicy })}
                    onClick={() => clickOnCell(row, null, depth)}
                  >
                    {formatValue({
                      value: row.total?.toFixed(2).toString(),
                    })}
                    &nbsp;
                    {baseCurrency?.name}
                  </div>
                ),
              },
            ]),
        },
      ];

      return [...typesColumns, ...monthColumns, ...totalColumn];
    }
    return [];
  }, [tableData]);

  const tableHeaderItems = [
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: ' header_row',
      colspan: tableData[0]?.months?.length,
      sortName: '',
    },
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
  ];

  return {
    tableColumns,
    tableHeaderItems,
  };
};
