import React, { useContext, useMemo } from 'react';
import { formatValue } from 'react-currency-input-field';
import { FormattedMessage, useIntl } from 'react-intl';

import { isNumber } from 'lodash-es';
import moment from 'moment';

import Icon from '../../components/Icon';
import TableUserAvatar from '../../components/TableUserAvatar';
import { DATE_FORMAT } from '../../constants/date.constants';
import { DELETE_TRANSACTION, UPDATE_TRANSACTION } from '../../constants/policies.constants';
import { Transaction } from '../../enums/finance/finance.enum';
import PoliciesContext from '../../PoliciesContext';
import { CurrencyType } from '../../types/finance';
import messages from './messages';

export const useDataForTable = (
  baseCurrency: CurrencyType | undefined,
  pageable: any,
  setTransactionClicked: (row: Transaction) => void,
  setModalEditTransactionIsOpen: (value: boolean) => void,
  setModalDeleteTransactionIsOpen: (value: boolean) => void,
) => {
  const intl = useIntl();
  const policies = useContext(PoliciesContext);

  const updatePolicy = useMemo(() => policies.find(policy => policy.policy.name === UPDATE_TRANSACTION), [policies]);
  const deletePolicy = useMemo(() => policies.find(policy => policy.policy.name === DELETE_TRANSACTION), [policies]);

  const tableColumns = useMemo(
    () => [
      {
        name: '#',
        modifier: (row: Transaction, index?: number) => {
          if (!isNumber(index)) {
            return null;
          }
          const startIndex = pageable.pageSize * pageable.pageNumber || 0;
          const currentIndex = startIndex + index + 1;
          return <span>{currentIndex}</span>;
        },
      },
      {
        name: intl.formatMessage(messages.dateLabel),
        modifier: (row: Transaction) => <span>{moment(row.transactionDate).format(DATE_FORMAT.ll)}</span>,
      },
      {
        name: intl.formatMessage(messages.officeLabel),
        modifier: (row: Transaction) => <span>{row.office.name}</span>,
      },
      {
        name: intl.formatMessage(messages.expenseIncomeTypeColumn),
        modifier: (row: Transaction) => (
          <div className="table__data-wrapper">
            <Icon
              iconName={!!row.expenseType.name ? 'arrow-expense' : 'arrow-income'}
              externalClass="button__icon--bigger"
            />
            <span>{row.expenseType.name || row.incomeType.name}</span>
          </div>
        ),
      },
      {
        name: intl.formatMessage(messages.expenseSubTypeColumn),
        modifier: (row: Transaction) => <span>{row.subExpenseType.name || ''}</span>,
      },
      {
        name: intl.formatMessage(messages.payerRecipientColumn),
        modifier: (row: Transaction) => (
          <span>
            {row.clientPayerRecipient.name ||
              row.officePayerRecipient.name ||
              row.supplierPayerRecipient.name ||
              row.userPayerRecipient.fullName}
          </span>
        ),
      },
      {
        name: intl.formatMessage(messages.projectLabel),
        modifier: (row: Transaction) => <span>{row.financeProject.name || '-'}</span>,
      },
      {
        name: intl.formatMessage(messages.invoiceNumberColumn),
        modifier: (row: Transaction) => <span>{row.invoice.invoiceNumber || '-'}</span>,
      },
      {
        name: intl.formatMessage(messages.originalAmountColumn),
        modifier: (row: Transaction) => (
          <span>
            {formatValue({
              value: row.amount.value,
            })}
            &nbsp;
            {row.currency.name}
          </span>
        ),
        className: 'table__data--right',
      },
      {
        name: intl.formatMessage(messages.rateLabel),
        modifier: (row: Transaction) => (
          <span>
            {formatValue({
              value: row.rate.value,
            })}
          </span>
        ),
      },
      {
        name: intl.formatMessage(messages.unifiedAmountColumn),
        modifier: (row: Transaction) => (
          <span>
            {formatValue({
              value: row.unifiedAmount.value,
            })}
            &nbsp;
            {baseCurrency?.name}
          </span>
        ),
        className: 'table__data--right',
      },
      {
        name: intl.formatMessage(messages.commentLabel),
        modifier: (row: Transaction) => <span>{row.comment}</span>,
      },
      {
        name: intl.formatMessage(messages.lastUpdateColumn),
        modifier: (row: Transaction) => <span>{moment.utc(row.lastUpdate).local().format(DATE_FORMAT.lll)}</span>,
      },
      {
        name: intl.formatMessage(messages.updaterColumn),
        modifier: (row: Transaction) => <TableUserAvatar users={[row.updater]} fileSize={36} />,
      },
    ],
    [baseCurrency, pageable],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (row: Transaction) =>
          !updatePolicy?.isOfficeSpecific || updatePolicy.officeIds.some(id => id === row.officeId) ? (
            <>
              <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
              <FormattedMessage {...messages.editButton} />
            </>
          ) : null,
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Transaction) => {
          setTransactionClicked(row);
          setModalEditTransactionIsOpen(true);
        },
        verifiablePolicies: [UPDATE_TRANSACTION],
      },
      {
        label: (row: Transaction) =>
          !deletePolicy?.isOfficeSpecific || deletePolicy.officeIds?.some(id => id === row.officeId) ? (
            <>
              <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
              <FormattedMessage {...messages.deleteButton} />
            </>
          ) : null,
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Transaction) => {
          setTransactionClicked(row);
          setModalDeleteTransactionIsOpen(true);
        },
        verifiablePolicies: [DELETE_TRANSACTION],
      },
    ],
    [],
  );

  return {
    tableColumns,
    tableActions,
  };
};
