import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFormik } from 'formik';
import get from 'lodash-es/get';
import moment from 'moment';
import { isArray, isString } from 'lodash-es';

import Button from '../../Button';
import Modal from '../../Modal';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useFiltersListValue, useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import {
  Client,
  FinancePlan,
  FinanceProject,
  CurrencyFormatter,
  InvoiceClass,
  INVOICE_SCHEMA,
  Invoice,
} from '../../../enums/finance/finance.enum';
import Select from '../../Select';
import CurrencyInput from '../../CurrencyInput';
import { CurrencyType } from '../../../types/finance';
import { getBaseCurrency, isBrowserLocale24h, scrollToError } from '../../../utils';
import { DATE_FORMAT } from '../../../constants/date.constants';
import Input from '../../Input';
import CustomDateRangePicker from '../../CustomDateRangePicker';
import Checkbox from '../../Checkbox';
import { UserInfo, UserPreviewInfo } from '../../../enums/users.enum';
import Avatar from '../../Profile/Avatar';
import Icon from '../../Icon';
import { timeOptions, timeOptions24h } from '../../../constants/schedule.constants';
import { EFileName } from '../../../constants/export.constants';

type ModalEditInvoiceProps = {
  onCloseRequest: () => void;
  createInvoice: (data: { data: InvoiceClass; callback: (id?: string) => void }) => void;
  getClientsList?: () => void;
  getProjectsList?: () => void;
  getCurrenciesList: () => void;
  defaultInvoice?: FinancePlan;
  users: UserInfo[];
  clients?: Client[];
  projects?: FinanceProject[];
  currencies: CurrencyType[];
  invoice: Invoice;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
  onDeleteRequest: (data: { id: string }) => void;
};

function ModalEditInvoice({
  onCloseRequest,
  error,
  isLoading,
  isOpen,
  users,
  clients,
  projects,
  invoice,
  currencies,
  defaultInvoice,
  getClientsList,
  getProjectsList,
  createInvoice,
  onDeleteRequest,
}: ModalEditInvoiceProps) {
  const intl = useIntl();

  const startTimeInputRef = useRef<any>(null);
  const selectTimeStartRef = useRef<any>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const [fileName, setFileName] = useState('');

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit, setFieldError } = useFormik({
    initialValues: new InvoiceClass(invoice),
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: INVOICE_SCHEMA,
    onSubmit: data => {
      if (data.preparationDate && data.preparationTime) {
        const preparationUtc = moment(`${data.preparationDate} ${data.preparationTime}`)
          .utc()
          .format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss);

        data.preparationDate = preparationUtc.split(' ')[0];
        data.preparationTime = preparationUtc.split(' ')[1];
      }

      createInvoice({
        data,
        callback: () => {
          onCloseRequest();
        },
      });
    },
  });

  useEffect(() => {
    getClientsList && getClientsList();
    getProjectsList && getProjectsList();
  }, []);

  useEffect(() => {
    if (invoice?.invoiceFile?.url) {
      setFileName(EFileName.INVOICE + '.pdf');
    }
  }, [invoice]);

  const clientsOptions = useMemo(
    () => clients?.filter(item => item.isActive).map(item => ({ label: item.name, value: item })),
    [clients],
  );

  const clientValue = useMemo(() => values.client && { label: values.client.name, value: values.client }, [
    clientsOptions,
    values,
  ]);

  const projectsOptions = useMemo(
    () =>
      projects
        ?.filter(item => values.clientId && item.clientId === values.clientId && item.isActive)
        .map(item => ({ label: item.name, value: item })),
    [projects, values.clientId],
  );

  const projectValue = useMemo(
    () => values.financeProject && { label: values.financeProject.name, value: values.financeProject },
    [projectsOptions, values],
  );

  const usersOptions = useMemo(() => {
    return users.map((el: UserInfo) => {
      return {
        label: el.fullName,
        value: el,
      };
    });
  }, [users]);

  const usersValues = useFiltersListValue(usersOptions, values.userIds || []);

  const recipientsValues = useMemo(() => {
    const users = [...usersValues];
    values.emails.forEach(email => {
      users.push({
        label: email,
        value: email,
      });
    });
    return users;
  }, [values.emails, usersValues]);

  const emailsErrors: any = useMemo(() => {
    if (isArray(errors.emails)) {
      return errors.emails.filter(email => !!email);
    }
  }, [errors.emails]);

  const timeOptionsForSelect = useMemo(
    () =>
      timeOptions.map((el, index) => ({
        value: timeOptions24h[index],
        label: isBrowserLocale24h() ? el.slice(0, 5) : el,
      })),
    [timeOptions],
  );

  const timeStartValue = useMemo(() => timeOptionsForSelect.find(el => el.value === values.preparationTime) || null, [
    timeOptionsForSelect,
    values.preparationTime,
  ]);

  const formatOptionLabel = useCallback(({ value, label }: any) => {
    return !isString(value) ? (
      <div className="table__data-wrapper">
        <Avatar userInfo={value} size="tiny" externalClass="avatar-select" fileSize={36} />
        <span>{label}</span>
      </div>
    ) : (
      <span className="custom-value">{label}</span>
    );
  }, []);

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  useSetFieldsErrors(error, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  useEffect(() => {
    if (!values.currencyId && baseCurrency) {
      setFieldValue('currencyId', baseCurrency.id);
      setFieldValue('currency', baseCurrency);
    }
  }, [baseCurrency, values]);

  const handleCheckboxChange = useCallback(
    (name, e) => {
      if (!e.target.checked && values.attachNotificationFile && name === 'sendNotification') {
        setFieldValue('attachNotificationFile', false);
      }

      setFieldValue(name, e.target.checked);
    },
    [values.attachNotificationFile],
  );

  const handleRecipientsChange = useCallback(value => {
    const emails = value.filter((data: any) => isString(data.value));
    const handleUserSort = ({ value }: { value: UserPreviewInfo }) => value.id;
    const sortedUsers = value.filter(handleUserSort);

    setFieldValue('userIds', sortedUsers.map(handleUserSort));
    setFieldValue(
      'users',
      sortedUsers.map((el: any) => el.value),
    );
    setFieldValue(
      'emails',
      emails.map(({ value }: { value: UserPreviewInfo }) => value),
    );
  }, []);

  const formatCreateLabel = useCallback(inputValue => {
    return (
      <div className="select__create-wrapper">
        <Icon iconName="plus" externalClass="select__add-icon" />
        <span>
          <FormattedMessage {...messages.addButton} /> &nbsp;
          {inputValue}
        </span>
      </div>
    );
  }, []);

  const getOptionLabel = useCallback(value => {
    return value.__isNew__ ? value.value : value.label;
  }, []);

  const handleChangeClient = useCallback(item => {
    setFieldValue(`client`, item.value);
    setFieldValue(`clientId`, item.value?.id);
    setFieldValue(`financeProject`, null);
    setFieldValue(`financeProjectId`, null);
  }, []);

  const handleChangeProject = useCallback(item => {
    setFieldValue(`financeProject`, item.value);
    setFieldValue(`financeProjectId`, item.value?.id);
  }, []);

  const handleChangeDateRange = useCallback(
    (start: string, end: string) => {
      const dateStart = moment(start).format();
      const dateEnd = moment(end).format();

      if (!moment(values.invoicingDateStart).isSame(moment(start), 'date')) {
        setFieldValue('invoicingDateStart', moment(dateStart).format(DATE_FORMAT.YYYY_MM_DD));
      }
      if (!moment(values.invoicingDateEnd).isSame(moment(end), 'date')) {
        setFieldValue('invoicingDateEnd', moment(dateEnd).format(DATE_FORMAT.YYYY_MM_DD));
      }
    },
    [values.invoicingDateStart, values.invoicingDateEnd],
  );

  const handleActiveTimeScroll = (ref: any) => {
    setTimeout(() => {
      const selectedItem = ref.current?.select?.menuListRef?.querySelector('.select__option--is-selected');
      selectedItem && selectedItem.scrollIntoView({ block: 'center', inline: 'nearest' });
    }, 1);
  };

  const handleStartTimeChange = useCallback(e => {
    setFieldValue('preparationTime', `${e.target.value}:00`);
    handleActiveTimeScroll(selectTimeStartRef);
  }, []);

  const handleStartTimeSelectChange = useCallback(
    ({ value }) => {
      const input = startTimeInputRef.current;
      if (input) {
        input.value = value.slice(0, 5);
      }
      setFieldValue('preparationTime', value);
    },
    [values],
  );

  const handleChangeFile = useCallback(
    event => {
      const file = event.target.files[0];
      if (file) {
        setFieldValue('invoiceFile', file);

        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = () => {
          if (typeof fr.result === 'string') {
            setFileName(file.name);
          }
        };
      }
    },
    [fileRef, invoice],
  );

  const handleReset = useCallback(() => {
    setFieldValue('invoiceFile', '');
    setFileName('');
    if (invoice?.invoiceFile?.id) {
      onDeleteClick();
    }
  }, [invoice]);

  const onDeleteClick = useCallback(() => {
    onDeleteRequest({ id: invoice.id });
  }, [invoice]);

  const handleInvoiceAmount = useCallback(item => {
    const amount = new CurrencyFormatter({
      ...item,
      float: item.float || 0,
    });
    setFieldValue('invoiceAmount', amount);
  }, []);

  const handleChangeDate = useCallback((name, value) => {
    if (!value) {
      setFieldValue(name, '');
      return;
    }

    setFieldValue(name, moment(value).format(DATE_FORMAT.YYYY_MM_DD));
  }, []);

  const handleUploadClick = useCallback(() => {
    fileRef.current?.click();
  }, [fileRef]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editInvoiceTitle)}
      classNameModal="invoice-modal"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Select
              label={intl.formatMessage(messages.clientLabel)}
              options={clientsOptions}
              value={clientValue}
              handleChange={handleChangeClient}
              hasError={hasError(`clientId`)}
              errorMessage={errors.clientId}
              isClearable
              isDisabled={!!defaultInvoice}
            />
            <Select
              label={intl.formatMessage(messages.projectLabel)}
              options={projectsOptions}
              //@ts-ignore
              value={projectValue}
              handleChange={handleChangeProject}
              hasError={hasError(`financeProjectId`)}
              errorMessage={errors.financeProjectId}
              isClearable
              isDisabled={!values.clientId}
            />
            <div className="form__inputs-subwrapper">
              <div className="form__input-block invoice-modal--half-input">
                <Input
                  type="date"
                  label={intl.formatMessage(messages.preparationDate)}
                  name="preparationDate"
                  defaultValue={values.preparationDate}
                  onChange={event => handleChangeDate('preparationDate', event.target.value)}
                  hasError={hasError(`preparationDate`)}
                  errorMessage={errors?.preparationDate}
                />
              </div>
              <div className="form__input-block invoice-modal--half-input">
                <Select
                  selectRef={selectTimeStartRef}
                  options={timeOptionsForSelect}
                  value={timeStartValue}
                  label={intl.formatMessage(messages.preparationTime)}
                  customInput={
                    <Input
                      inputRef={startTimeInputRef}
                      type={'time'}
                      name={'preparationTime'}
                      onChange={handleStartTimeChange}
                      defaultValue={values.preparationTime?.slice(0, 5)}
                    />
                  }
                  externalClass="time-select"
                  errorMessage={errors?.preparationTime}
                  customInputRef={startTimeInputRef}
                  handleChange={handleStartTimeSelectChange}
                  hasError={hasError('preparationTime')}
                />
              </div>
            </div>
            <CustomDateRangePicker
              alwaysShowCalendars
              filterPlaceholder
              isFilter
              filterLabel={intl.formatMessage(messages.invoicingPeriod)}
              maxDate={
                values.preparationDate ? moment(values.preparationDate, DATE_FORMAT.YYYY_MM_DD).toDate() : undefined
              }
              hasError={hasError('invoicingDateStart') || hasError('invoicingDateEnd')}
              errorMessage="Required"
              startDate={values.invoicingDateStart}
              endDate={values.invoicingDateEnd}
              dateFormatStart={DATE_FORMAT.YYYY_MM_DD}
              dateFormatEnd={DATE_FORMAT.YYYY_MM_DD}
              containerExternalClass="invoice-modal--datepicker"
              parentEl=".invoice-modal--datepicker"
              onChange={handleChangeDateRange}
            />
            <div className="form__input-block">
              <Checkbox
                externalClass="form__checkbox-capitalazie-label"
                onChange={e => handleCheckboxChange('groupByMembers', e)}
                id="groupByMembers"
                label={intl.formatMessage(messages.groupByMembers)}
                checked={values.groupByMembers}
              />
            </div>
            <div className="invoice-modal-checkbox--row">
              <Checkbox
                externalClass="form__checkbox-capitalazie-label"
                onChange={e => handleCheckboxChange('sendNotification', e)}
                id="sendNotification"
                label={intl.formatMessage(messages.sendNotification)}
                checked={values.sendNotification}
              />
              <Checkbox
                externalClass="form__checkbox-capitalazie-label"
                onChange={e => handleCheckboxChange('attachNotificationFile', e)}
                id="attachNotificationFile"
                label={intl.formatMessage(messages.attachedFile)}
                checkedValue={values.attachNotificationFile}
                checked={values.attachNotificationFile}
                disabled={!values.sendNotification}
              />
            </div>
            <div className="form__input-block">
              <Select
                isSearchable
                isMulti
                isSelectWithAvatar
                label={intl.formatMessage(messages.recipientsLabel)}
                name={`userIds`}
                value={recipientsValues}
                options={usersOptions}
                errorMessage={emailsErrors && emailsErrors[0]}
                formatOptionLabel={formatOptionLabel}
                formatCreateLabel={formatCreateLabel}
                getOptionLabel={getOptionLabel}
                handleChange={handleRecipientsChange}
              />
            </div>
            <div className="form__inputs-subwrapper">
              <div className="form__input-block invoice-modal--half-input">
                <Input
                  type="date"
                  label={intl.formatMessage(messages.sendingDate)}
                  name="sendingDate"
                  defaultValue={values.sendingDate || undefined}
                  onChange={event => handleChangeDate('sendingDate', event.target.value)}
                  hasError={hasError(`sendingDate`)}
                  errorMessage={errors?.sendingDate}
                />
              </div>
              <div className="form__input-block invoice-modal--half-input">
                <Input
                  type="date"
                  label={intl.formatMessage(messages.closingDate)}
                  name="closingDate"
                  max={moment().format(DATE_FORMAT.YYYY_MM_DD)}
                  defaultValue={values.closingDate || undefined}
                  onChange={event => handleChangeDate('closingDate', event.target.value)}
                  hasError={hasError(`closingDate`)}
                  errorMessage={errors?.closingDate}
                />
              </div>
            </div>
            <div className="form__input-block">
              <Input
                name="invoiceNumber"
                label={intl.formatMessage(messages.invoiceNumber)}
                onChange={handleChange}
                defaultValue={values.invoiceNumber || 0}
                hasError={hasError('invoiceNumber')}
                errorMessage={errors?.invoiceNumber}
              />
            </div>
            <span className="invoice-file-label">
              <FormattedMessage {...messages.invoiceFilePdfButton} />
            </span>
            <div className="form__input-block flex invoice-modal--file-input">
              <input
                type="file"
                ref={fileRef}
                name="invoiceFile"
                onChange={handleChangeFile}
                accept=".pdf"
                className="file-input"
              />
              <button type="button" onClick={handleUploadClick} className="file-button">
                <FormattedMessage {...messages.choseFileButton} />
              </button>
              <span className="file-placeholder">
                {fileName || <FormattedMessage {...messages.noFileChosenButton} />}
              </span>
              <span
                onClick={handleReset}
                className={`recognition__icon-reset${!values.invoiceFile ? ' disabled' : ''}`}
              >
                <FormattedMessage {...messages.removeButton} />
              </span>
            </div>
            <div className="form__inputs-subwrapper">
              <CurrencyInput
                name="invoiceAmount"
                label={intl.formatMessage(messages.invoiceAmount)}
                value={values.invoiceAmount.value}
                onChange={handleInvoiceAmount}
                //@ts-ignore
                errorMessage={errors?.invoiceAmount?.float || errors?.invoiceAmount}
                hasError={hasError('invoiceAmount')}
                wrapperClass={'rate-input'}
                suffix={` ${baseCurrency?.name}`}
              />
            </div>
          </div>
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button
            color={'gray'}
            externalClass={'button--modal button--cancel'}
            type={'button'}
            onClick={onCloseRequest}
          >
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditInvoice;
