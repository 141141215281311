import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatValue } from 'react-currency-input-field';

import moment from 'moment';
import classNames from 'classnames';

import { CashFlowMonthType, CashFlowTotalSetting, ProfitLossType } from '../../../enums/finance/finance.enum';
import messages from '../messages';
import { CurrencyType } from '../../../types/finance';
import Button from '../../Button';
import Icon from '../../Icon';
import { calculateNetCashMonths } from './utils';
import { DATE_FORMAT } from '../../../constants/date.constants';
import ModalEditCashFlowNameSettings from '../Modals/ModalEditBlockNameSetting';
import Table from '../../Table';

type OfficeTotalTableProps = {
  customTitle?: string;
  tableData: any;
  months: string[];
  errors: string | null;
  isLoading: boolean;
  baseCurrency?: CurrencyType;
  settingsData: any;
  settingsLoading: boolean;
  settingsError: string | null;
  putSettings: (data: CashFlowTotalSetting) => void;
  onCloseSettings: () => void;
};

const OfficeTotalTable = ({
  customTitle,
  tableData,
  months,
  baseCurrency,
  errors,
  isLoading,
  settingsData,
  putSettings,
  onCloseSettings,
}: OfficeTotalTableProps) => {
  const intl = useIntl();

  const [openSettingsModal, setOpenSettingModal] = useState(false);

  const convertedData = useMemo(() => {
    if (settingsData.length && tableData.length) {
      const totalMonths = calculateNetCashMonths(tableData, months);

      return [
        {
          id: 'totalNet',
          totalItem: true,
          total: totalMonths
            .map((it: CashFlowMonthType) => Number(it.value))
            .reduce((partialSum: number, a: number) => partialSum + a, 0),
          months: totalMonths,
        },
      ];
    }

    return [];
  }, [tableData]);

  const tableColumns = useMemo(() => {
    if (convertedData.length) {
      const typeColumn = [
        {
          id: 'title',
          name: '',
          modifier: () => intl.formatMessage(messages.totalRow),
          className: 'table__row-title',
        },
      ];

      const monthColumns = convertedData[0].months?.map((data: CashFlowMonthType, index: number) => ({
        id: `month-${index}`,
        name: (
          <div className="day-display">
            {moment(data.month).format(DATE_FORMAT.MMM)}
            {(index === 0 || moment(data.month).startOf('year').isSame(data.month)) && (
              <div className={classNames('year-display')}>{moment(data.month).format(DATE_FORMAT.YYYY)}</div>
            )}
          </div>
        ),
        modifier: (row: ProfitLossType) => {
          return (
            <span>
              {row.months[index]?.value ? (
                <>
                  {formatValue({
                    value: row.months[index]?.value?.toFixed(2).toString(),
                  })}
                  &nbsp;
                  {baseCurrency?.name}
                </>
              ) : (
                '-'
              )}
            </span>
          );
        },
      }));

      const totalColumn = [
        {
          id: `total`,
          name: intl.formatMessage(messages.totalRow),
          className: 'table__row-title',
          modifier: (row: ProfitLossType) => (
            <span>
              {formatValue({
                value: row.total?.toFixed(2).toString(),
              })}
              &nbsp;
              {baseCurrency?.name}
            </span>
          ),
        },
      ];

      return [...typeColumn, ...monthColumns, ...totalColumn];
    }

    return [];
  }, [convertedData]);

  const tableHeaderItems = [
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: ' header_row',
      colspan: convertedData[0]?.months?.length,
      sortName: '',
    },
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
  ];

  const openSetting = () => {
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    onCloseSettings();
    setOpenSettingModal(false);
  };

  const editSetting = (params: CashFlowTotalSetting) => {
    putSettings(params);
  };

  return (
    <>
      <div className="cash-flow-report__table-head">
        <h2 className="cash-flow__block-title">
          {customTitle?.length ? customTitle : <FormattedMessage {...messages.balanceBlock} />}
        </h2>
        <Button color="gray" externalClass="cash-flow__report-button" onClick={openSetting}>
          <Icon iconName="pencil" externalClass="button__icon" />
        </Button>
      </div>
      <div className="page__scrollable-table-wrapper cash-flow-report">
        <div className="page__scrollable-table-wrapper__inner cash-flow-report-wrapper">
          <Table
            externalClass={'table--hierarchical'}
            tableColumns={convertedData.length ? tableColumns : []}
            tableData={convertedData}
            loading={isLoading}
            error={errors}
            tableActions={[]}
            hasRowHover={false}
            tableHeaderItems={convertedData.length ? tableHeaderItems : []}
          />
        </div>
      </div>
      {openSettingsModal && (
        <ModalEditCashFlowNameSettings
          isOpen={openSettingsModal}
          onCloseRequest={closeSetting}
          editCashFlowSetting={editSetting}
          name={customTitle}
          type="TOTAL"
        />
      )}
    </>
  );
};

export default OfficeTotalTable;
