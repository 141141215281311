import React, { useCallback, useMemo, useState } from 'react';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';
import { useIntl } from 'react-intl';
import { OptionTypeBase } from 'react-select';

import classNames from 'classnames';
import { FormikErrors } from 'formik';
import { get, isEmpty, isString } from 'lodash-es';

import Checkbox from '../../../../components/Checkbox';
import CurrencyInput from '../../../../components/CurrencyInput';
import { FilterOptionType } from '../../../../components/Filter';
import Hint from '../../../../components/Hint';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import { getDefaultCurrency } from '../../../../components/Transactions/utils';
import { ImportTransaction } from '../../../../enums/finance/finance.enum';
import { CurrencyType, SubExpenseType } from '../../../../types/finance';
import { handleHintPosition } from '../../../../utils';
import messages from '../../messages';
import {
  ExpenseIncomeTypesGroupEnum,
  ExpenseIncomeTypesOptionsType,
  ExpenseSubTypesOptionsType,
  getPayerRecipientOptions as getPayerRecipientSelectOptions,
  ImportErrorTypeEnum,
  operationTypesOptions,
  payerRecipientGroup,
  PayerRecipientGroupEnum,
  PayerRecipientOptionsType,
  setTransactionsFieldValue,
} from '../../utils';
import HintError from './HintError';
import TransactionImportRow from './TransactionImportRow';
import TransactionsCurrencyInput from './TransactionsCurrencyInpurt';
import TransactionsSelect from './TransactionsSelect';

type TransactionsTableProps = {
  transactions: ImportTransaction[];
  errors: FormikErrors<{
    transactions: ImportTransaction[];
  }>;
  officesOptions: PayerRecipientOptionsType[];
  expenseTypesOptions: ExpenseIncomeTypesOptionsType[];
  incomeTypesOptions: ExpenseIncomeTypesOptionsType[];
  clientsOptions: PayerRecipientOptionsType[];
  suppliersOptions: PayerRecipientOptionsType[];
  usersOptions: PayerRecipientOptionsType[];
  financeProjectsOptions: FilterOptionType[];
  invoiceNumbersOptions: FilterOptionType[];
  currenciesOptions: FilterOptionType[];
  firstSubmitValidate: boolean;
  baseCurrency: CurrencyType | undefined;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleAmountChange: (value: CurrencyInputOnChangeValues, row: ImportTransaction, index?: number) => void;
  handleRateChange: (value: CurrencyInputOnChangeValues, row: ImportTransaction, index?: number) => void;
  handleUnifiedAmountChange: (value: CurrencyInputOnChangeValues, index?: number) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  validateForm: () => void;
};

function TransactionsTable({
  transactions,
  errors,
  officesOptions,
  expenseTypesOptions,
  incomeTypesOptions,
  clientsOptions,
  suppliersOptions,
  usersOptions,
  financeProjectsOptions,
  invoiceNumbersOptions,
  currenciesOptions,
  firstSubmitValidate,
  baseCurrency,
  handleChange,
  handleAmountChange,
  handleRateChange,
  handleUnifiedAmountChange,
  setFieldValue,
  validateForm,
}: TransactionsTableProps) {
  const intl = useIntl();

  const [hintData, setHintData] = useState<{
    styles: React.CSSProperties;
    error: string | undefined;
    errorType: ImportErrorTypeEnum;
  } | null>(null);

  const closeHint = useCallback(() => {
    setHintData(null);
  }, []);

  const getError = useCallback(
    (fieldName: string | undefined, index: number) => {
      if (!fieldName) {
        return null;
      }

      return get(errors, `transactions[${index}.${fieldName}]`);
    },
    [errors],
  );

  const handleHint = useCallback(
    (
      event: React.MouseEvent<HTMLTableCellElement>,
      column: typeof tableColumns[0],
      row: ImportTransaction,
      rowIndex: number,
      error?: string,
    ) => {
      const parsedCorrectrlyField = column?.parsedCorrectlyField;
      const originalValue = column?.originalValueField ? get(row, column?.originalValueField) : undefined;
      const isParsedCorrectrly = parsedCorrectrlyField && get(row, parsedCorrectrlyField);

      if (!isParsedCorrectrly || error) {
        setHintData({
          styles: handleHintPosition(event),
          error: error || originalValue,
          errorType: !!error ? ImportErrorTypeEnum.VALIDATION_ERROR : ImportErrorTypeEnum.PARSER_ERROR,
        });
      }
    },
    [],
  );

  const getExpenseIncomeOptions = useCallback(
    (row: ImportTransaction) => {
      const operationType = row.operationType;
      if (!operationType) {
        return [];
      }

      return operationType === ExpenseIncomeTypesGroupEnum.EXPENSE_TYPES ? expenseTypesOptions : incomeTypesOptions;
    },
    [expenseTypesOptions, incomeTypesOptions],
  );

  const getExpenseSubTypeOptions = useCallback(
    (row: ImportTransaction) => {
      const expenseType = row.expenseTypeId;

      if (!expenseType) {
        return [];
      }

      const subExpenseTypes =
        //@ts-ignore
        expenseTypesOptions.find(element => element.value.id === expenseType)?.value.subExpenseTypes;

      return subExpenseTypes.map((el: SubExpenseType) => ({
        label: el.name,
        value: el,
        type: ExpenseIncomeTypesGroupEnum.EXPENSE_SUB_TYPES,
      }));
    },
    [expenseTypesOptions],
  );

  const getPayerRecipientOptions = useCallback(
    (row: ImportTransaction) => {
      if (row.subExpenseType && row.subExpenseType.isPersonal) {
        return usersOptions;
      }

      return payerRecipientGroup
        .filter(({ type }) => type !== PayerRecipientGroupEnum.USERS)
        .map(({ type, name }) => ({
          label: name,
          type,
          options: getPayerRecipientSelectOptions(type, { clientsOptions, suppliersOptions, officesOptions }),
        }));
    },
    [usersOptions, clientsOptions, suppliersOptions, officesOptions],
  );

  const getFinanceProjectsOptions = useCallback(
    (row: ImportTransaction) => {
      if (!row.clientPayerRecipientId) {
        return [];
      }

      return financeProjectsOptions.filter(opt => opt.value.clientId === row.clientPayerRecipientId);
    },
    [financeProjectsOptions],
  );

  const getInvoicesOptions = useCallback(
    (row: ImportTransaction) => {
      if (!row.financeProjectId) {
        return [];
      }

      return invoiceNumbersOptions.filter(opt => opt.value.financeProjectId === row.financeProjectId);
    },
    [invoiceNumbersOptions],
  );

  const getExpenseIncomeValues = useCallback((row: ImportTransaction, options: OptionTypeBase[]) => {
    return options.find(({ value }) => {
      const id = value instanceof Object ? value.id : value;
      return id === row.expenseTypeId || id === row.incomeTypeId;
    });
  }, []);

  const getExpenseSubValues = useCallback((row: ImportTransaction, options: OptionTypeBase[]) => {
    return options.find(({ value }) => {
      const id = value instanceof Object ? value.id : value;

      return id === row.subExpenseTypeId;
    });
  }, []);

  const getSingleSelectValue = (options: any[], id: string) => {
    return options.find(({ value }) => {
      const optId = value instanceof Object ? value.id : value;
      return optId === id;
    });
  };

  const getPayerRecipientValues = useCallback(
    (row: ImportTransaction) => {
      if (row.userPayerRecipientId) {
        return getSingleSelectValue(usersOptions, row.userPayerRecipientId);
      }
      if (row.clientPayerRecipientId) {
        return getSingleSelectValue(clientsOptions, row.clientPayerRecipientId);
      }

      if (row.supplierPayerRecipientId) {
        return getSingleSelectValue(suppliersOptions, row.supplierPayerRecipientId);
      }

      if (row.officePayerRecipientId) {
        return getSingleSelectValue(officesOptions, row.officePayerRecipientId);
      }
    },
    [usersOptions, clientsOptions, suppliersOptions, officesOptions],
  );

  const getFinanceProjectsValues = useCallback((row: ImportTransaction, options: OptionTypeBase[]) => {
    return getSingleSelectValue(options, row.financeProjectId);
  }, []);

  const getInvoicesValues = useCallback((row: ImportTransaction, options: OptionTypeBase[]) => {
    return getSingleSelectValue(options, row.invoiceId);
  }, []);

  const handleOperationTypeChange = useCallback(({ value }: OptionTypeBase, name?: string) => {
    if (name) {
      setFieldValue(name, value);
      setFieldValue(name.replace('operationType', 'expenseTypeId'), '');
      setFieldValue(name.replace('operationType', 'incomeTypeId'), '');
      setFieldValue(name.replace('operationType', 'operationTypeHasParseError'), false);
    }
  }, []);

  const resetPayerRecipientValues = (index: number) => {
    setTransactionsFieldValue(setFieldValue, 'clientPayerRecipientId', index, '', false);
    setTransactionsFieldValue(setFieldValue, 'supplierPayerRecipientId', index, '', false);
    setTransactionsFieldValue(setFieldValue, 'officePayerRecipientId', index, '', false);
    setTransactionsFieldValue(setFieldValue, 'userPayerRecipientId', index, '', false);
    setTransactionsFieldValue(setFieldValue, 'financeProjectId', index, '', false);
  };

  const resetInvoiceValues = (index: number) => {
    setTransactionsFieldValue(setFieldValue, 'invoiceId', index, '', false);
  };

  const getCurrenciesValues = useCallback((row: ImportTransaction, options: OptionTypeBase[]) => {
    return getSingleSelectValue(options, row.currencyId);
  }, []);

  const handleTransactionDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, name?: string) => {
    handleChange(e);
    name && setFieldValue(name.replace('transactionDate', 'transactionDateHasParseError'), false);
  }, []);

  const handleExpenseIncomeTypesChange = useCallback(
    ({ value, type }: ExpenseIncomeTypesOptionsType, row: ImportTransaction, index: number) => {
      setTransactionsFieldValue(setFieldValue, 'subExpenseTypeId', index, '', false);
      setTransactionsFieldValue(setFieldValue, 'subExpenseType', index, { id: '', name: '', isPersonal: false }, false);

      if (type === ExpenseIncomeTypesGroupEnum.EXPENSE_TYPES && !isString(value)) {
        setTransactionsFieldValue(setFieldValue, 'expenseTypeId', index, value.id, false);
        setTransactionsFieldValue(setFieldValue, 'expenseType', index, value, false);
        setTransactionsFieldValue(setFieldValue, 'incomeTypeId', index, '', false);
      } else {
        setTransactionsFieldValue(setFieldValue, 'incomeTypeId', index, value, false);
        setTransactionsFieldValue(setFieldValue, 'expenseTypeId', index, '', false);
        setTransactionsFieldValue(
          setFieldValue,
          'expenseType',
          index,
          {
            name: '',
            id: '',
            isPersonal: false,
          },
          false,
        );
      }
      firstSubmitValidate && setTimeout(validateForm);
    },
    [firstSubmitValidate],
  );

  const handleExpenseSubTypesChange = useCallback(
    ({ value }: ExpenseSubTypesOptionsType, row: ImportTransaction, index: number) => {
      if (!isString(value)) {
        if (row.subExpenseType.isPersonal !== value.isPersonal) {
          resetPayerRecipientValues(index);
        }

        setTransactionsFieldValue(setFieldValue, 'subExpenseTypeId', index, value.id, false);
        setTransactionsFieldValue(setFieldValue, 'subExpenseType', index, value, false);
      }

      firstSubmitValidate && setTimeout(validateForm);
    },
    [firstSubmitValidate],
  );

  const handlePayerRecipientChange = useCallback(
    ({ value, type }: any, row: ImportTransaction, index: number) => {
      const id = value instanceof Object ? value.id : value;
      switch (type) {
        case PayerRecipientGroupEnum.CLIENTS: {
          resetPayerRecipientValues(index);
          setTransactionsFieldValue(setFieldValue, 'clientPayerRecipientId', index, id, false);
          if (row.clientPayerRecipientId !== id) {
            setTransactionsFieldValue(setFieldValue, 'financeProjectId', index, '', false);
          }
          break;
        }
        case PayerRecipientGroupEnum.SUPPLIERS: {
          if (value instanceof Object) {
            resetPayerRecipientValues(index);
            setTransactionsFieldValue(setFieldValue, 'supplierPayerRecipientId', index, id, false);
            setTransactionsFieldValue(setFieldValue, 'financeProjectId', index, '', false);
          }
          break;
        }
        case PayerRecipientGroupEnum.OFFICES: {
          resetPayerRecipientValues(index);
          setTransactionsFieldValue(setFieldValue, 'officePayerRecipientId', index, id, false);
          setTransactionsFieldValue(setFieldValue, 'financeProjectId', index, '', false);

          break;
        }
        case PayerRecipientGroupEnum.USERS: {
          resetPayerRecipientValues(index);
          setTransactionsFieldValue(setFieldValue, 'userPayerRecipientId', index, id, false);
          setTransactionsFieldValue(setFieldValue, 'financeProjectId', index, '', false);

          break;
        }
      }
      firstSubmitValidate && setTimeout(validateForm);
    },
    [firstSubmitValidate],
  );

  const handleFinanceProjectsChange = useCallback(
    ({ value }: OptionTypeBase, row: ImportTransaction, index: number) => {
      setTransactionsFieldValue(setFieldValue, 'financeProjectId', index, value.id);

      if (value instanceof Object) {
        resetInvoiceValues(index);
        setTransactionsFieldValue(setFieldValue, 'invoiceId', index, '', false);
      }
    },
    [],
  );

  const handleInvoicesChange = useCallback(({ value }: OptionTypeBase, row: ImportTransaction, index: number) => {
    setTransactionsFieldValue(setFieldValue, 'invoiceId', index, value.id);
  }, []);

  const handleCurrencyChange = useCallback(
    ({ value }: OptionTypeBase, row: ImportTransaction, index: number) => {
      setTransactionsFieldValue(setFieldValue, 'currencyId', index, value.id);
      setTransactionsFieldValue(setFieldValue, 'currency', index, value);
      if (value.id === baseCurrency?.id) {
        setTransactionsFieldValue(setFieldValue, 'rate', index, getDefaultCurrency(1));
        setTransactionsFieldValue(setFieldValue, 'unifiedAmount', index, row.amount);
      }
    },
    [baseCurrency],
  );

  const handleOfficeChange = useCallback(({ value }: OptionTypeBase, name?: string) => {
    if (name) {
      setFieldValue(name, value);
    }
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.dateLabel),
        fieldError: 'transactionDate',
        parseErrorField: 'transactionDateHasParseError',
        parsedCorrectlyField: 'isTransactionDateParsedCorrectly',
        originalValueField: 'transactionDateOriginalValue',
        modifier: (row: ImportTransaction, index: number) => (
          <Input
            name={`transactions[${index}].transactionDate`}
            type="date"
            defaultValue={row.transactionDate}
            onChange={handleTransactionDateChange}
            externalClass="transactions__date-input"
          />
        ),
      },
      {
        name: intl.formatMessage(messages.originalAmountColumn),
        fieldError: 'amount',
        parseErrorField: 'amountHasParseError',
        parsedCorrectlyField: 'isAmountParsedCorrectly',
        originalValueField: 'amountOriginalValue',
        modifier: (row: ImportTransaction, index: number) => (
          <TransactionsCurrencyInput
            row={row}
            name={`transactions[${index}].amount`}
            index={index}
            defaultValue={row.amount.value}
            handleChange={handleAmountChange}
            allowNegativeValue={true}
          />
        ),
      },
      {
        name: intl.formatMessage(messages.commentLabel),
        fieldError: 'comment',
        modifier: (row: ImportTransaction, index: number) => (
          <Input
            wrapperClass="textarea-wrapper"
            name={`transactions[${index}].comment`}
            tag="textarea"
            defaultValue={row.comment}
            onChange={handleChange}
          />
        ),
      },
      {
        name: intl.formatMessage(messages.operationTypeColumn),
        parseErrorField: 'operationTypeHasParseError',
        parsedCorrectlyField: 'isOperationTypeParsedCorrectly',
        originalValueField: 'operationTypeOriginalValue',
        fieldError: 'operationType',
        modifier: (row: ImportTransaction, index: number) => {
          const defaultValue = operationTypesOptions.find(opt => opt.value === row.operationType);
          return (
            <Select
              externalMenuClass="transactions__form-select"
              name={`transactions[${index}].operationType`}
              options={operationTypesOptions}
              defaultValue={defaultValue}
              handleChange={handleOperationTypeChange}
              onCloseMenu={closeHint}
            />
          );
        },
      },
      {
        name: intl.formatMessage(messages.expenseIncomeColumn),
        fieldError: 'expenseTypeId',
        modifier: (row: ImportTransaction, index: number) => (
          <TransactionsSelect
            row={row}
            index={index}
            getOptions={getExpenseIncomeOptions}
            getValues={getExpenseIncomeValues}
            handleSelectChange={handleExpenseIncomeTypesChange}
            onCloseMenu={closeHint}
          />
        ),
      },
      {
        name: intl.formatMessage(messages.expenseSubTypeColumn),
        fieldError: 'subExpenseTypeId',
        modifier: (row: ImportTransaction, index: number) => (
          <TransactionsSelect
            row={row}
            index={index}
            getOptions={getExpenseSubTypeOptions}
            getValues={getExpenseSubValues}
            handleSelectChange={handleExpenseSubTypesChange}
            onCloseMenu={closeHint}
          />
        ),
      },
      {
        name: intl.formatMessage(messages.payerRecipientColumn),
        fieldError: 'officePayerRecipientId',
        modifier: (row: ImportTransaction, index: number) => (
          <TransactionsSelect
            isGrouped
            row={row}
            index={index}
            getOptions={getPayerRecipientOptions}
            getValues={getPayerRecipientValues}
            handleSelectChange={handlePayerRecipientChange}
            onCloseMenu={closeHint}
          />
        ),
      },
      {
        name: intl.formatMessage(messages.projectLabel),
        fieldError: 'financeProjectId',
        modifier: (row: ImportTransaction, index: number) => (
          <TransactionsSelect
            row={row}
            index={index}
            disabled={!row.clientPayerRecipientId}
            getOptions={getFinanceProjectsOptions}
            getValues={getFinanceProjectsValues}
            handleSelectChange={handleFinanceProjectsChange}
            onCloseMenu={closeHint}
          />
        ),
      },
      {
        name: intl.formatMessage(messages.invoiceNumberColumn),
        fieldError: 'invoiceId',
        modifier: (row: ImportTransaction, index: number) => (
          <TransactionsSelect
            row={row}
            index={index}
            disabled={!row.financeProjectId}
            getOptions={getInvoicesOptions}
            getValues={getInvoicesValues}
            handleSelectChange={handleInvoicesChange}
            onCloseMenu={closeHint}
          />
        ),
      },
      {
        name: intl.formatMessage(messages.currencyColumn),
        fieldError: 'currencyId',
        modifier: (row: ImportTransaction, index: number) => (
          <TransactionsSelect
            row={row}
            index={index}
            getValues={getCurrenciesValues}
            options={currenciesOptions}
            handleSelectChange={handleCurrencyChange}
            onCloseMenu={closeHint}
          />
        ),
      },
      {
        name: intl.formatMessage(messages.rateLabel),
        fieldError: 'rate',
        modifier: (row: ImportTransaction, index: number) => (
          <TransactionsCurrencyInput
            row={row}
            name={`transactions[${index}].rate`}
            index={index}
            value={row.rate.value}
            decimalsLimit={4}
            handleChange={handleRateChange}
          />
        ),
      },
      {
        name: intl.formatMessage(messages.unifiedAmountColumn),
        fieldError: 'unifiedAmount',
        modifier: (row: ImportTransaction, index: number) => (
          <CurrencyInput
            name={`transactions[${index}].unifiedAmount`}
            index={index}
            value={row.unifiedAmount.value}
            suffix={baseCurrency?.id ? ` ${baseCurrency.name}` : undefined}
            onChange={handleUnifiedAmountChange}
            allowNegativeValue={true}
          />
        ),
      },

      {
        name: intl.formatMessage(messages.officeLabel),
        fieldError: 'officeId',
        modifier: (row: ImportTransaction, index: number) => {
          const defaultValue = officesOptions.find(opt => opt.value === row.officeId);
          return (
            <Select
              externalMenuClass="transactions__form-select rigth"
              name={`transactions[${index}].officeId`}
              defaultValue={defaultValue}
              options={officesOptions}
              handleChange={handleOfficeChange}
              onCloseMenu={closeHint}
            />
          );
        },
      },
    ],
    [
      getExpenseIncomeOptions,
      getPayerRecipientOptions,
      getFinanceProjectsOptions,
      handlePayerRecipientChange,
      handleExpenseIncomeTypesChange,
      officesOptions,
      currenciesOptions,
      baseCurrency,
    ],
  );

  const checkedAllTransactions = transactions.every(transaction => transaction.importTransaction);

  const checkedAtLeastOneTransaction = transactions.some(transactions => transactions.importTransaction);

  const handleCheckAllClick = () => {
    if (checkedAtLeastOneTransaction) {
      setFieldValue(
        'transactions',
        transactions.map(item => ({
          ...item,
          importTransaction: false,
        })),
      );
    } else {
      setFieldValue(
        'transactions',
        transactions.map(item => ({
          ...item,
          importTransaction: true,
        })),
      );
    }
  };
  const tableContent = useMemo(
    () =>
      transactions.map((row, rowIndex) => (
        <TransactionImportRow
          key={`row-${rowIndex}`}
          row={row}
          rowIndex={rowIndex}
          tableColumns={tableColumns}
          getError={getError}
          handleChange={handleChange}
          handleHint={handleHint}
          closeHint={closeHint}
        />
      )),
    [transactions, tableColumns, getError],
  );

  return !isEmpty(transactions) ? (
    <>
      <div className="transactions__import-table-wrapper">
        <table className="transactions__table import-table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <tr className="row-header">
            <th>
              <div className="row-header-content">
                <Checkbox
                  id="choose-all"
                  checkedValue={checkedAtLeastOneTransaction}
                  onChange={handleCheckAllClick}
                  externalClass={classNames(
                    { 'clear-all-checkbox': checkedAtLeastOneTransaction && !checkedAllTransactions },
                    'checkbox-no-label',
                  )}
                  iconName={checkedAtLeastOneTransaction && !checkedAllTransactions ? 'clear-all-checkbox' : undefined}
                />
              </div>
            </th>
            {tableColumns.map(({ name }, index) => (
              <th key={`th-${index}`}>
                <div className="row-header-content">{name}</div>
              </th>
            ))}
          </tr>

          {tableContent}
        </table>
      </div>

      <Hint className="hint__transactions">
        {hintData?.error ? <HintError error={hintData.error} errorType={hintData.errorType} /> : null}
      </Hint>
    </>
  ) : null;
}

export default React.memo(TransactionsTable);
