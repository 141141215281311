import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { CashFlowReportType } from '../../../enums/finance/finance.enum';
import { useTableData } from '../../../utils/hooks.utils';
import HierarchicalTable from '../../HierarchicalTable';
import messages from '../messages';
import { CurrencyType, ExpenseType, IncomeType, SubExpenseType } from '../../../types/finance';
import { useDataForTable } from './useDataForTable';
import Button from '../../Button';
import Icon from '../../Icon';
import ModalEditCashFlowSettings from '../Modals/ModalEditCashFlowSettings';
import { getMonths } from './utils';
import { CashFlowReportParams } from '../../../enums/params/cashflow.params';

export enum ECashFlowTitle {
  FIRST_INCOME_BLOCK = 'FIRST_INCOME_BLOCK',
  SECOND_INCOME_BLOCK = 'SECOND_INCOME_BLOCK',
  FIRST_EXPENSE_BLOCK = 'FIRST_EXPENSE_BLOCK',
  SECOND_EXPENSE_BLOCK = 'SECOND_EXPENSE_BLOCK',
}

type CashFlowTableProps = {
  title: ECashFlowTitle;
  tableData: any;
  blockType: ECashFlowTitle;
  params: CashFlowReportParams;
  months: any[];
  errors: string | null;
  isLoading: boolean;
  baseCurrency: CurrencyType | undefined;
  settingsData: any;
  settingsLoading: boolean;
  settingsError: string | null;
  getIncomeList: (data: any) => void;
  getExpenseList: (data: any) => void;
  getExpenseSettings: (data: string) => void;
  putExpenseSettings: (data: any) => void;
  getIncomeSettings: (data: string) => void;
  putIncomeSettings: (data: any) => void;
  onCloseSettings: () => void;
  resetSettings: () => void;
};

const CashFlowTable = ({
  title,
  tableData,
  params,
  months,
  baseCurrency,
  errors,
  isLoading,
  settingsData,
  blockType,
  settingsError,
  settingsLoading,
  getIncomeList,
  getExpenseList,
  getExpenseSettings,
  putExpenseSettings,
  getIncomeSettings,
  putIncomeSettings,
  onCloseSettings,
  resetSettings,
}: CashFlowTableProps) => {
  const [openSettingsModal, setOpenSettingModal] = useState(false);

  const cuttedData = tableData.find((type: any) => type.blockType === blockType);

  const convertedExpenseData = useMemo(() => {
    if (
      cuttedData &&
      (blockType === ECashFlowTitle.FIRST_EXPENSE_BLOCK || blockType === ECashFlowTitle.SECOND_EXPENSE_BLOCK)
    ) {
      const types = cuttedData?.cashFlows
        .map((data: any) => data.expenseType)
        .reduce((item: any, o: any) => {
          if (!item.some((obj: any) => obj.id === o.id)) {
            item.push(o);
          }
          return item;
        }, []);

      if (types?.length) {
        const tableTypes = types.map((type: ExpenseType, typeIndex: number) => {
          const filteredData = cuttedData.cashFlows.filter(
            (item: CashFlowReportType) => item.expenseTypeId === type.id,
          );

          return {
            typeId: type.id,
            typeName: type.name,
            typeIndex,
            total: filteredData
              .map((it: any) => Number(it.cashFlowAmount))
              .reduce((partialSum: any, a: any) => partialSum + a, 0),
            months: getMonths(filteredData, months),
            subTypes: type.subExpenseTypes?.map((sub: SubExpenseType, subIndex: number) => {
              const offices = cuttedData.cashFlows.filter(
                (item: CashFlowReportType) => item.subExpenseTypeId === sub.id,
              );
              const uniqueOffices = Array.from(new Set(offices.map((it: CashFlowReportType) => it.officeId)));

              return {
                name: sub.name,
                id: sub.id,
                typeIndex: typeIndex,
                months: getMonths(offices, months),
                total: offices
                  .map((it: any) => Number(it.cashFlowAmount))
                  .reduce((partialSum: any, a: any) => partialSum + a, 0),
                offices: uniqueOffices.map(unique => {
                  const cuttedOffices = offices.filter((office: CashFlowReportType) => office.officeId === unique);

                  return {
                    office: cuttedOffices[0].office,
                    officeId: unique,
                    typeIndex: typeIndex,
                    subIndex: subIndex,
                    total: cuttedOffices
                      .map((it: any) => Number(it.cashFlowAmount))
                      .reduce((partialSum: any, a: any) => partialSum + a, 0),
                    months: getMonths(cuttedOffices, months),
                  };
                }),
              };
            }),
          };
        });

        tableTypes?.push({
          totalItem: true,
          total: cuttedData.cashFlows
            .map((it: any) => Number(it.cashFlowAmount))
            .reduce((partialSum: any, a: any) => partialSum + a, 0),
          months: getMonths(cuttedData.cashFlows, months),
        });

        return tableTypes;
      }
    }

    return [];
  }, [tableData]);

  const convertedIncomeData = useMemo(() => {
    if (
      cuttedData &&
      (blockType === ECashFlowTitle.FIRST_INCOME_BLOCK || blockType === ECashFlowTitle.SECOND_INCOME_BLOCK)
    ) {
      const types = cuttedData?.cashFlows
        ?.map((data: any) => data.incomeType)
        .reduce((item: any, o: any) => {
          if (!item.some((obj: any) => obj.id === o.id)) {
            item.push(o);
          }

          return item;
        }, []);

      if (types?.length) {
        const tableTypes = types.map((type: IncomeType, typeIndex: number) => {
          const filteredData = cuttedData?.cashFlows.filter(
            (item: CashFlowReportType) => item.incomeTypeId === type.id,
          );

          const recipients = filteredData
            ?.map(
              (data: any) =>
                data.clientPayerRecipient ||
                data.officePayerRecipient ||
                data.supplierPayerRecipient ||
                data.userPayerRecipient,
            )
            .reduce((item: any, o: any) => {
              if (!item.some((obj: any) => obj?.id === o?.id)) {
                item.push(o);
              }

              return item;
            }, []);

          return {
            typeId: type.id,
            typeName: type.name,
            typeIndex,
            total: filteredData
              .map((it: any) => Number(it.cashFlowAmount))
              .reduce((partialSum: any, a: any) => partialSum + a, 0),
            months: getMonths(filteredData, months),
            ...(recipients.filter((rec: any) => !!rec).length
              ? {
                  subTypes: recipients
                    .filter((rec: any) => !!rec)
                    .map((recipient: any, subIndex: number) => {
                      const offices = filteredData.filter(
                        (item: any) =>
                          (item.clientPayerRecipientId ||
                            item.officePayerRecipientId ||
                            item.supplierPayerRecipientId ||
                            item.userPayerRecipientId) === recipient.id,
                      );
                      const uniqueOffices = Array.from(new Set(offices.map((it: CashFlowReportType) => it.officeId)));

                      return {
                        name: recipient.name || recipient.fullName,
                        id: recipient.id,
                        typeIndex,
                        payerType: Object.entries({
                          clientPayerRecipientIds: filteredData[0].clientPayerRecipientId,
                          officePayerRecipientIds: filteredData[0].officePayerRecipientId,
                          supplierPayerRecipientIds: filteredData[0].supplierPayerRecipientId,
                          employeePayerRecipientIds: filteredData[0].userPayerRecipientId,
                        }).find(([, value]) => value !== null),
                        months: getMonths(offices, months),
                        total: offices
                          .map((it: any) => Number(it.cashFlowAmount))
                          .reduce((partialSum: any, a: any) => partialSum + a, 0),
                        offices: uniqueOffices.map(unique => {
                          const cuttedOffices = offices.filter(
                            (office: CashFlowReportType) => office.officeId === unique,
                          );

                          return {
                            office: cuttedOffices[0].office,
                            officeId: unique,
                            total: cuttedOffices
                              .map((it: any) => Number(it.cashFlowAmount))
                              .reduce((partialSum: any, a: any) => partialSum + a, 0),
                            months: getMonths(cuttedOffices, months),
                            typeIndex,
                            subIndex,
                            payerType: Object.entries({
                              clientPayerRecipientIds: cuttedOffices[0].clientPayerRecipientId,
                              officePayerRecipientIds: cuttedOffices[0].officePayerRecipientId,
                              supplierPayerRecipientIds: cuttedOffices[0].supplierPayerRecipientId,
                              employeePayerRecipientIds: cuttedOffices[0].userPayerRecipientId,
                            }).find(([, value]) => value !== null),
                          };
                        }),
                      };
                    }),
                }
              : {}),
          };
        });

        tableTypes?.push({
          totalItem: true,
          total: cuttedData.cashFlows
            .map((it: any) => Number(it.cashFlowAmount))
            .reduce((partialSum: any, a: any) => partialSum + a, 0),
          months: getMonths(cuttedData.cashFlows, months),
        });

        return tableTypes;
      }
    }
    return [];
  }, [tableData]);

  const openSetting = () => {
    if (blockType === ECashFlowTitle.FIRST_EXPENSE_BLOCK || blockType === ECashFlowTitle.SECOND_EXPENSE_BLOCK)
      getExpenseSettings(blockType);
    else getIncomeSettings(blockType);
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    onCloseSettings();
    setOpenSettingModal(false);
  };

  const editSetting = (params: any) => {
    if (blockType === ECashFlowTitle.FIRST_EXPENSE_BLOCK || blockType === ECashFlowTitle.SECOND_EXPENSE_BLOCK)
      putExpenseSettings(params);
    else putIncomeSettings(params);
  };

  const { tableColumns, tableHeaderItems } = useDataForTable(
    blockType === ECashFlowTitle.FIRST_EXPENSE_BLOCK || blockType === ECashFlowTitle.SECOND_EXPENSE_BLOCK
      ? convertedExpenseData
      : convertedIncomeData,
    baseCurrency,
    blockType === ECashFlowTitle.FIRST_EXPENSE_BLOCK || blockType === ECashFlowTitle.SECOND_EXPENSE_BLOCK
      ? 'expenseColumn'
      : 'incomeColumn',
    params,
  );

  return (
    <>
      <div className="cash-flow-report__table-head">
        <h2 className="cash-flow__block-title">
          {tableData.find((type: any) => type.blockType === blockType)?.blockName ?? (
            <FormattedMessage {...messages[title]} />
          )}
        </h2>
        <Button color="gray" externalClass="cash-flow__report-button" onClick={openSetting}>
          <Icon iconName="pencil" externalClass="button__icon" />
        </Button>
      </div>
      <div className="page__scrollable-table-wrapper cash-flow-report">
        <div className="page__scrollable-table-wrapper__inner cash-flow-report-wrapper">
          <HierarchicalTable
            tableData={useTableData(
              blockType === ECashFlowTitle.FIRST_EXPENSE_BLOCK || blockType === ECashFlowTitle.SECOND_EXPENSE_BLOCK
                ? convertedExpenseData
                : convertedIncomeData,
              ['subTypes', 'offices'],
            )}
            tableColumns={tableColumns}
            loading={isLoading}
            error={errors}
            tableHeaderItems={tableHeaderItems}
          />
        </div>
      </div>
      {openSettingsModal && (
        <ModalEditCashFlowSettings
          isOpen={openSettingsModal}
          getExpenseList={getExpenseList}
          getIncomeList={getIncomeList}
          onCloseRequest={closeSetting}
          editCashFlowSetting={editSetting}
          resetSettings={resetSettings}
          loading={settingsLoading}
          error={settingsError}
          data={settingsData}
          blockType={blockType}
        />
      )}
    </>
  );
};

export default CashFlowTable;
