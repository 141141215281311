import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatValue } from 'react-currency-input-field';

import classNames from 'classnames';
import moment from 'moment';

import messages from '../messages';
import { CurrencyType } from '../../../types/finance';
import { getTableCell } from '../../../utils/table.utils';
import { DepthLevels } from '../../../constants/tables.constants';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { ProfitLossSubType, ProfitLossType } from '../../../enums/finance/finance.enum';

export const useDataForTable = (
  tableData: any,
  baseCurrency: CurrencyType | undefined,
  title: 'expenseColumn' | 'incomeColumn',
) => {
  const intl = useIntl();

  const tableColumns = useMemo(() => {
    if (tableData?.length) {
      const typesColumns = [
        {
          id: 'name',
          Header: intl.formatMessage(messages[title]),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: ProfitLossType) =>
                  row.totalItem ? <FormattedMessage {...messages.totalRow} /> : row.name,
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: ProfitLossType | ProfitLossSubType) => row.name,
              },
              {
                depth: DepthLevels.THIRD,
                content: (row: ProfitLossSubType) => row.name,
              },
            ]),
        },
      ];

      const monthColumns = tableData[0].months?.map((data: any, index: number) => ({
        id: `month-${index}`,
        Header: (
          <div className="day-display">
            {moment(data.month).format(DATE_FORMAT.MMM)}
            {(index === 0 || moment(data.month).startOf('year').isSame(data.month)) && (
              <div className={classNames('year-display')}>{moment(data.month).format(DATE_FORMAT.YYYY)}</div>
            )}
          </div>
        ),
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ProfitLossType) => (
                <span>
                  {row?.months[index]?.value ? (
                    <>
                      {formatValue({
                        value: Math.round(row?.months[index]?.value).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: ProfitLossType | ProfitLossSubType) => (
                <span>
                  {row?.months[index]?.value ? (
                    <>
                      {formatValue({
                        value: Math.round(row?.months[index]?.value).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
            {
              depth: DepthLevels.THIRD,
              content: (row: ProfitLossSubType) => (
                <span>
                  {row?.months[index]?.value ? (
                    <>
                      {formatValue({
                        value: Math.round(row?.months[index]?.value).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
          ]);
        },
      }));

      const totalColumn = [
        {
          id: `total`,
          Header: intl.formatMessage(messages.totalRow),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: ProfitLossType) => (
                  <span>
                    {formatValue({
                      value: Math.round(row.total || 0).toString(),
                    })}
                    &nbsp;
                    {baseCurrency?.name}
                  </span>
                ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: ProfitLossType | ProfitLossSubType) => (
                  <span>
                    {formatValue({
                      value: Math.round(row.total || 0).toString(),
                    })}
                    &nbsp;
                    {baseCurrency?.name}
                  </span>
                ),
              },
              {
                depth: DepthLevels.THIRD,
                content: (row: ProfitLossSubType) => (
                  <span>
                    {formatValue({
                      value: Math.round(row.total || 0).toString(),
                    })}
                    &nbsp;
                    {baseCurrency?.name}
                  </span>
                ),
              },
            ]),
        },
      ];

      return [...typesColumns, ...monthColumns, ...totalColumn];
    }
    return [];
  }, [tableData]);

  const tableHeaderItems = [
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: ' header_row',
      colspan: tableData[0]?.months?.length,
      sortName: '',
    },
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
  ];

  return {
    tableColumns,
    tableHeaderItems,
  };
};
