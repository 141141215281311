import moment from 'moment';
import { ECashFlowTitle } from './CashFlowTable';

export const getMonths = (data: any, months: any) => {
  return months.map((item: any) => ({
    month: item,
    value: data
      .filter((tab: any) => moment(tab.cashFlowDate).isSame(moment(item), 'months'))
      .map((it: any) => Number(it.cashFlowAmount))
      .reduce((partialSum: any, a: any) => partialSum + a, 0),
  }));
};

export const calculateCloseMonths = (data: any, months: any) => {
  return months.map((item: any) => ({
    month: item,
    value: data
      .filter((tab: any) => moment(tab.balanceDate).isSame(moment(item), 'months'))
      .map((it: any) => Number(it.customCloseBalance || it.automaticCloseBalance))
      .reduce((partialSum: any, a: any) => partialSum + a, 0),
  }));
};

export const calculateOpenMonths = (data: any, months: any) => {
  return months.map((item: any) => ({
    month: item,
    value: data
      .filter((tab: any) => {
        return moment(tab.balanceDate).isSame(moment(item), 'months');
      })
      .map((it: any) => Number(it.customOpenBalance || it.automaticOpenBalance))
      .reduce((partialSum: any, a: any) => partialSum + a, 0),
  }));
};

export const calculateNetCashMonths = (data: any, months: any) => {
  return months.map((item: any) => ({
    month: item,
    value: data
      .filter((tab: any) => moment(tab.balanceDate).isSame(moment(item), 'months'))
      .map(
        (it: any) =>
          Number(it.customCloseBalance || it.automaticCloseBalance) -
          Number(it.customOpenBalance || it.automaticOpenBalance),
      )
      .reduce((partialSum: any, a: any) => partialSum + a, 0),
  }));
};

export const calculateTotalBalance = (row: any) => {
  return row?.months
    .reduce((acc: number, element: { month: string; value: number }) => {
      return acc + element.value;
    }, 0)
    ?.toFixed(2)
    .toString();
};

export const getTotalMonths = (data: any, months: any) => {
  const expensesData = data
    .filter(
      (item: any) =>
        item.blockType === ECashFlowTitle.FIRST_EXPENSE_BLOCK || item.blockType === ECashFlowTitle.SECOND_EXPENSE_BLOCK,
    )
    .map((type: any) => type.cashFlows)
    .flat();

  const incomesData = data
    .filter(
      (item: any) =>
        item.blockType === ECashFlowTitle.FIRST_INCOME_BLOCK || item.blockType === ECashFlowTitle.SECOND_INCOME_BLOCK,
    )
    .map((type: any) => type.cashFlows)
    .flat();

  const expMonths = getMonths(expensesData, months);
  const incMonths = getMonths(incomesData, months);

  const totalMonths = incMonths.map((item: any, index: number) => ({
    ...item,
    value: (item.value || 0) - (expMonths[index].value || 0),
  }));
  return totalMonths;
};
